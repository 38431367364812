import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button}
   from "reactstrap";
//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData
} from "../../store/functions";
import {
  AvForm,
  AvField
} from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PieChart from "./PieChart";





class pageList_ManagementReserved extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      isProgress:true,
      gender: [], 
      advisor : [],
      formData : {},
      Accountdetail: [
        {
          Name: ""
        }
      ],
      gridDatanominalMFG : [{
        
      }],
      gridDataMFG  : [{
        
      }],
      isPie : false

    };
    this.obj = this;
    this.breadCrumbTitle = "Reserved Account Summary";
    this.breadCrumbItem = "Reserved Account Summary";
    this.modalTitle = "Reserved Account Summary";
    this.rtPage_Print = "/membersynopsisprint";
    this.rtPage_Add =  "/add-transactionaccount"

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    
    this.renderGridBody =  this.renderGridBody.bind(this);
    this.btnSave_onClick  = this.btnSave_onClick.bind(this);
   

  }

  getaccountsdata (){
    alert(this.state.Id);
  }



  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem)

    //Filling DropDowns

  }


  btnSave_onClick (event, formData)  {
      this.setState({isPie : true});
    Fn_GetReport(this.obj, { arguList: { id: formData.TimePeriod } }, API_WEB_URLS.GetReservedManagementSummary + "/0/token", "gridData", true );
  }

  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }

  



   
  renderGridHeader() {
    return (
      <>
      
        <th>Name</th>
        <th>Membership No</th>
        <th>Debits</th>
        <th>Credits</th>
        <th>Balance</th>
      </>
    );
  }


 





  renderGridBody(formData) {
    
    return (
      <>
        <td>{formData.Name}</td>
        <td>{formData.MembershipNo}</td>
        <td>{formData.Debits}</td>
        <td>{formData.Credits}</td>
        <td>{formData.Balance}</td>
     
      </>
    );
  }


  renderModalBody(selectedFormData) {

    

    return (
      <>
        <p className="mb-4">
          Name: <span className="text-primary">{selectedFormData.Reference}</span>
        </p>
      </>
    );
  }


  


  

  

  


  render() {
    return (
<>

<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
               
                 
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                            <Col lg="12">
                                <Row>
                                    <Col sm="1" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Select Time</label>
                                        </Col>
                                    <Col sm="3" className="mb-3">
                                    <AvField name="TimePeriod" label="" value={this.state.formData.TimePeriod}   type="select" className="form-select" >
                                            <option value={0} defaultValue label={"Select"} />
                                            <option value={1} defaultValue label={"Quarterly"} />
                                            <option value={2} defaultValue label={"Half Yearly"} />
                                            <option value={3} defaultValue label={"Yearly"} />
                                          </AvField>
                                          </Col>
                                          <Col sm="4" className="mb-3">
                                          <div>
                                    <Button
                                    type="submit"
                                    color="primary"
                                    className="mr-1 waves-effect waves-light"
                                  >
                                    View
                                  </Button>
                          </div>
                          </Col>

                          
                                          
                                    
                                          </Row>  
                                   
                     </Col>
                     
                       

                         
                         </AvForm>

                         </div> 
                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                         </Container>
                         </div>
                         </React.Fragment>



                        




<React.Fragment>



                         
                        
                       
       </React.Fragment>

       <RCDisplayPage
        //page header paramaters
       
       Isbreadcrumb = {true}
       breadCrumbTitle={this.breadCrumbTitle}
       breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={false}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPage>

      </>
    );
  }
}
export default compose(container)(pageList_ManagementReserved);
