/* eslint-disable */


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
import SweetAlert from "react-bootstrap-sweetalert"
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";
import { bind } from "lodash";




class pageAddEdit_CashDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      Accountdetail: [{
        Name : ""
      }],
      RecipentDetail: [
        {
          Name : ""
        }
      ],
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype : [],
      SAccountType :0,
      MemberName : [
        {
          Name : '',
          MemberAccountNo : ''
        }
            ],
            BoldToken : [{
              Token : ''
            }],
      Account  : [],
      success_msg : false,
      VoucherId : 0
    
      
     



    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "CASH DEPOSIT ";
    this.breadCrumbTitle = "CASH DEPOSIT ";
    this.breadCrumbItem =  this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.TransactionDeposit + "/0/token";
    this.pushFormName = "/dashboard";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno =  this.syno.bind(this);
    this.tog_fullscreen = this.tog_fullscreen.bind(this);
    this.prin  =  this.prin.bind(this);
    this.printtest  =  this.printtest.bind(this);
    this.btnReset_onClick  =  this.btnReset_onClick.bind(this);
  }




  componentDidMount() {
    //Filling DropDowns
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
      
    localStorage.setItem("PageName", this.breadCrumbItem)
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
    

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }



  btnSave_onClick(event, formData) {



    let vformData = new FormData();
   
    vformData.append("F_MemberAccountMaster", this.state.MemberName[0].F_MemberAccountMaster);
    vformData.append("Amount", formData.Amount);
    vformData.append("TransactionType", 2);
    vformData.append("Narration", formData.narration);
    vformData.append("F_UserMaster", this.state.aid);


    
    
    if (!this.state.id)

   

    Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, "#", true , "VoucherId" , this.printtest);




    else
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, name: formData.Name } },
        this.API_URL,
        this.pushFormName
      );

      this.setState({
        formData: {
          'AccountValue' : 0,
          'membershipno' : '',
          'MembershipTypeId' : 0,
          'Amount' : '',
          'TransactionTypeValue' : 0,
          'narration' : ''
        },
        success_msg : true

      });




  }



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  
  btnReset_onClick = event => {
    this.setState({
      Balance : [{
        Balance : 0
       }],
      MemberName : [
  {
    Name : ''
  }
      ],
      MembershipNo : ''
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetAccountDetail =(event)=> {
    var value  =  event.target.value;
    Fn_FillListData(this.obj, "Accountdetail", API_WEB_URLS.MASTER + "/0/token/ListOfAccountsByMember/Id/"+this.state.MemberName[0].Id);
  }



  syno () {

    this.setState({
      success_msg : false
    });

      };

  // SetSenderAccountType =(event)=> {



  //   var value  =  event.target.value;
    
  //   this.setState({SAccountType: value});

  

  // }


  GetAccountDetail2 =(event)=> {
    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "RecipentDetail", API_WEB_URLS.MASTER + "/0/token/GetBalance/1/Mem/Balance?ListFor="+value+"&F_AccountType=11");
  }


  GetMemberName =(event)=> {
    if(event.target.value.length == 3 || event.target.value.length == 12){
      var value = event.target.value;
      value =  value + '-';
      
      this.setState({
        MembershipNo: value
      });
    }


    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "MemberName", API_WEB_URLS.MASTER + "/0/token/SearchMem/1/Mem?ListFor="+value);

  }


  printtest () {
    var prevState  =  this.state.modal_fullscreen;
   
    this.setState({
      modal_fullscreen:  true
      
  }, () => {
      this.prin();
  })
    this.removeBodyCss()
   
  }

  prin() {


    this.timer = setTimeout(() => {
      var reportviewerObj = $("#reportviewer-container").data("boldReportViewer"); reportviewerObj.print();
    }, 10000);

    // const intervalId = setInterval(() => {
    //   var reportviewerObj = $("#reportviewer-container").data("boldReportViewer"); reportviewerObj.print();
    // }, 1000);

    // intervalId
    
    // clearInterval(intervalId); 
  
  }

 


  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }







  render() {
    var parameters = [{
      name: 'Id',
      values: 1246
      }
    ];


    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Transaction Account</h4>
                             
                         
                                      <Row>+9
                                        22
<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Enter Membership Number</label>
          </Col>
  <Col lg="6">
    

  <AvField name="membershipno" onChange={this.GetMemberName}  value={this.state.formData.membershipno} placeholder="Enter membershipno" errorMessage="Enter membershipno" validate={{ required: { value: true } }} type="text" className="form-control" />
   
  </Col>
</Row>

<Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Member</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="Member"  value={this.state.MemberName[0].Name}  placeholder=" Member Name" errorMessage=" Sender Name"  type="text" disabled className="form-control" />
                                          {/* <AvField name="Member" onChange={this.GetAccountDetail} label="" value={this.state.formData.MembershipTypeId} errorMessage="Select Member" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.MemberName
                                              ? this.state.MemberName.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField> */}
                                        </Col>
                                      </Row>
{/* <Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Account No</label>
                                        </Col>
                                <Col lg="6">


                                <AvField name="senderaccountno" onChange={this.GetAccountDetail}  value={this.state.senderaccountno} placeholder="Account No." errorMessage="Enter Account No."  type="text"  className="form-control" />
                                  </Col>
              </Row> */}




              <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Transaction Account No.</label>
                                        </Col>
                                        <Col sm="6">


                                        <AvField name="AccountValue"   value={this.state.MemberName[0].MemberAccountNo} placeholder="Accout No."  type="text" className="form-control"  disabled/>
                                          {/* <AvField name="AccountValue" label="" value={this.state.formData.AccountValue} errorMessage="Select Account" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.Accountdetail
                                              ? this.state.Accountdetail.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField> */}
                                        </Col>
                                      </Row>


{/* 
                                    <Row>

                                    <Col sm="4">
                                                <label htmlFor="membershipType" className="col-form-label">A/C Holder Name</label>
                                              </Col>
                                      <Col lg="6">

                                      <AvField name="accountholdername"   value={this.state.Accountdetail[0].Name} placeholder="Account Holder Name"  disabled type="text" className="form-control" />
                                      
                                      </Col>
                                    </Row> */}


                                    <Row>

                                    <Col sm="4">
                                                <label htmlFor="membershipType" className="col-form-label">Amount To Depoist</label>
                                              </Col>
                                      <Col lg="6">


                                      <AvField name="Amount"  value={this.state.formData.Amount} placeholder="Amount To Deposit"    type="number" className="form-control" />
                                        </Col>


                                    </Row>



                                    <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Transaction Type</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="TransactionTypeValue" label="" value={this.state.formData.TransactionTypeValue} errorMessage="Select Transaction Type"  type="select" className="form-select"  disabled>
                                            <option value={2} defaultValue label={"Cash"} />
                                          
                                          </AvField>
                                        </Col>
                                      </Row>



                                      <Row>

<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Narration</label>
          </Col>



  <Col lg="6">
  <AvField name="narration"  value={this.state.formData.narration} placeholder="Narration"   type="text" className="form-control" />
    </Col>
</Row>


                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Agent</h4>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentCode" className="col-form-label">Agent/Staff Code</label>
                                          </Col>
                                          <Col sm="6">
                                            <input name="MemberId_Agent" label="" value={this.state.email} placeholder="Enter Agent/Staff Code" errorMessage="Enter Agent/Staff Code"  disabled type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentName" className="col-form-label">Agent/Staff Name</label>
                                          </Col>
                                          <Col sm="6">
                                            <input name="AgentName" label="" value={this.state.name} placeholder="Enter Agent/Staff Name" errorMessage="Enter Agent/Staff Name"  type="text" disabled className="form-control" />
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  

                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Save
                            </Button>{" "}

                            {this.state.success_msg ? (
                      <SweetAlert
                        title="Transaction Successful!"
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={this.syno}
                      >
                       
                      </SweetAlert>
                    ) : null}
                            {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnReset_onClick}
                        >
                              Reset
                            </Button>{" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                              Exit
                            </Button>

                            {/* <Button
                          type="button"
                          color="primary"
                          disabled={this.state.print}
                          style={{marginLeft : 10}}
                          onClick={this.tog_fullscreen}
                          className="mr-1 waves-effect waves-light"
                        >
                          Print
                        </Button> */}
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                    
                    </CardTitle>
                 
                    <div>
                     
                      <Modal
                        size="xl"
                        isOpen={this.state.modal_fullscreen}
                        toggle={this.tog_fullscreen}
                        className="modal-fullscreen"
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title mt-0"
                            id="exampleModalFullscreenLabel"
                          >
                           Print
                          </h5>
                          <button
                            onClick={() =>
                              this.setState({ modal_fullscreen: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                        <BoldReportViewerComponent id="reportviewer-container"
reportServiceUrl = {API_WEB_URLS.REPORTURL+'reporting/reportservice/api/Viewer'}
reportServerUrl= {API_WEB_URLS.REPORTURL+'reporting/api/site/site1'}
serviceAuthorizationToken = {'bearer '+this.state.BoldToken[0].Token}
reportPath = {'/Ghana/DepositReciept'}
parameters = {parameters}
>
</BoldReportViewerComponent>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={this.tog_fullscreen}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                      
                        </div>
                      </Modal>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_CashDeposit);
