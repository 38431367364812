import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_GetReport } from "../../store/functions";


import firebase from 'firebase/compat/app';
import 'firebase/database'; // Import the Realtime Database module


class pageList_CheckBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      prov : false,
     Balance : [{
      Balance : 0
     }],
    MemberName : [
{
  Name : ''
}
    ],
    MembershipNo : ''
    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Balance ";
    this.breadCrumbTitle = "Balance ";
    this.breadCrumbItem = "Check " + this.formTitle;
    this.API_URL = "CheckBalance/0/token";
    this.API_URL_SAVE = API_WEB_URLS.AdvisorMaster + "/0/token";
    this.pushFormName = "/dashboard";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
  }


  





  componentDidMount() {

    var firebaseConfig = {
      apiKey: "AIzaSyCNjgrOUdEP2cPlPdcn5ehiXg4S2PvDiRo",
      authDomain: "chat-bcfc7.firebaseapp.com",
      projectId: "chat-bcfc7",
      storageBucket: "chat-bcfc7.appspot.com",
      messagingSenderId: "491422835178",
      appId: "1:491422835178:web:ee58f920ed8ed437dc5293",
      databaseURL: "https://chat-bcfc7-default-rtdb.firebaseio.com",
    };
    firebase.initializeApp(firebaseConfig);

    // Get a reference to your database
    const database = firebase.database();

    // Reference the specific location where your objects are stored
    // You can dynamically set dataPath based on props or state
    const ref = database.ref(this.state.dataPath);

    ref.on('value', (snapshot) => {
      const fetchedObjects = snapshot.val();
      this.setState({ objects: fetchedObjects });
    });
    
    //Filling DropDowns
     // Fn_FillListData(this.obj, "cader", API_WEB_URLS.MASTER + "/0/token/Cader/Id/0");


     localStorage.setItem("PageName", this.breadCrumbItem)
    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id , ed : false });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {


    let vformData = new FormData();
        
    vformData.append("Id", this.state.MemberName[0].Id);

    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL,"Balance",true);

   // Fn_FillListData(this.obj, "Balance", API_WEB_URLS.MASTER + "/0/token/CheckBalance/Id/"+this.state.MemberName[0].Id);
  }


  

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  
  btnReset_onClick = event => {
    this.setState({
      Balance : [{
        Balance : 0
       }],
      MemberName : [
  {
    Name : ''
  }
      ],
      MembershipNo : ''
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetMemberName =(event)=> {
    if(event.target.value.length == 3 || event.target.value.length == 12){
      var value = event.target.value;
      value =  value + '-';
      
      this.setState({
        MembershipNo: value
      });
    }

    


    this.setState({
      Balance: [{
        Balance : 0
      }]
    });
    var value  =  event.target.value;
    Fn_FillListData(this.obj, "MemberName", API_WEB_URLS.MASTER + "/0/token/SearchMem/1/Mem?ListFor="+value);
  }











  render() {



    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick} >
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                              <Row>

                                <Col sm="4">
                                  <label htmlFor="membershipType" className="col-form-label">Enter Membership Number</label>
                                </Col>
                                <Col lg="6">
                                 <AvField name="membershipno" onChange={this.GetMemberName}  value={this.state.MembershipNo} placeholder="Enter Membership Number" errorMessage="Enter Membership Number" validate={{ required: { value: true } }} type="text" className="form-control" maxlength='17'/>
                                </Col>
                              </Row>
 

                            <Row>
                              <Col sm="4">
                                    <label htmlFor="membershipType" className="col-form-label">Member Name</label>
                              </Col>
                              <Col lg="6">
                                <AvField name="membername"  value={this.state.MemberName[0].Name} placeholder=" Member Name" errorMessage=" Sender Name"  type="text" disabled className="form-control" />
                                  </Col>
                                </Row>
                                <Row>
                              <Col sm="4">
                                    <label htmlFor="membershipType" className="col-form-label">Actual Balance  :  {this.state.Balance[0].ActualBalance}</label>
                              </Col>
                              <Col lg="6">
                              <label htmlFor="membershipType" className="col-form-label">Accesible Balance  :  {this.state.Balance[0].AccesibleBalance}</label>
                              </Col>
                                </Row>
                                
                            <div className="d-flex flex-wrap gap-2">
                                <Button type="submit" color="primary">
                                  View
                                </Button>{" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnReset_onClick}
                        >
                              Reset
                            </Button>{" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                              Exit
                            </Button>
                          </div>

</CardBody>
                      </Card>
                    </Col>


                    <Col lg="2">
                                  <Card>
                                    <CardBody>
                              <Row>
                                <img width={'50'} height={'200'} src={this.state.Balance[0].IMG}/>
                              </Row>
 

                           
                                
                            

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageList_CheckBalance);
