import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Jan",
    GoldMember: 4,
    OrdinaryMember : 50,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Feb",
    GoldMember: 10,
    OrdinaryMember : 50,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Mar",
    GoldMember: 12,
    OrdinaryMember : 50,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Apr",
    GoldMember: 30,
    OrdinaryMember : 55,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "May",
    GoldMember: 40,
    OrdinaryMember : 50,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Jun",
    GoldMember: 10,
    OrdinaryMember : 40,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Jul",
    GoldMember: 40,
    OrdinaryMember : 60,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Aug",
    GoldMember: 42,
    OrdinaryMember : 50,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Sep",
    GoldMember: 75,
    OrdinaryMember : 55,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Oct",
    GoldMember: 60,
    OrdinaryMember : 25,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Nov",
    GoldMember: 65,
    OrdinaryMember : 45,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
  {
    name: "Dec",
    GoldMember: 62,
    OrdinaryMember : 10,
    // NonHigh : 2,
    // NonLow: 10,
    // EntityHigh: 40,
    // EntityLow: 20,
    // GratisHigh : 5,
    // GratisLow : 10,
    // DeadHigh : 1,
    // DeadLow : 10
  },
];

export default class MixBarChart extends Component {
  render() {
    return (
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="GoldMember" stackId="GoldMember" fill="#82ca9d" />
            <Bar dataKey="OrdinaryMember" stackId="OrdinaryMember" fill="#8884d8" />
          
            {/* <Bar dataKey="NonHigh" stackId="NonHigh" fill="#82ca9d" />
            <Bar dataKey="NonLow" stackId="NonLow" fill="#82ca9d" />
            <Bar dataKey="EntityLow" stackId="EntityLow" fill="#b3411c" />
            <Bar dataKey="EntityHigh" stackId="EntityHigh" fill="#b3411c" />
            <Bar dataKey="GratisLow" stackId="GratisLow" fill="#2547a0" />
            <Bar dataKey="GratisHigh" stackId="GratisHigh" fill="#2547a0" />
            <Bar dataKey="DeadHigh" stackId="DeadHigh" fill="#711cb3" />
            <Bar dataKey="DeadLow" stackId="DeadLow" fill="#711cb3" /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
