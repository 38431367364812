import React, { Component } from "react";


//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store


import {

  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_FillListData
} from "../../store/functions";

import {
  Container,
  Row,
  Col,

  Card,
  CardBody,

  Button,
 Badge
} from "reactstrap";

import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import './datatables.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';




function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
class pageList_ManagementEntranceFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData : {},
      success_dlg: false,
      productData : [],
      OpeningClosing : [{
        OpeningBalance : 0,
        TotalCredit : 0,
        TotalDebit : 0,
        ClosingBalance : 0
      }]
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Management Activation Fee";
    this.rtPage_Add = "raiseticket";
    this.API_URL = "GetEntranceFeeDetails/0/token";
    
    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnSave_onClick =  this.btnSave_onClick.bind(this);
    
  }




  componentDidMount() {
    let vformData = new FormData();
    vformData.append("Id", 0);
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL, "productData", true);


    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Management Activation Fee")
    
   
  }

 


  btnSave_onClick (event, formData)  {

    
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL, "productData", true);


  }





  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }

  render() {
    const obj = JSON.parse(localStorage.getItem("authUser"));


    const columns = [
    {
      dataField: 'Date',
      text: 'Date',
      sort: true,
    }, 
    {
      dataField: 'MemberName',
      text: 'MemberName',
      sort: true
    },
    {
      dataField: 'GIN',
      text: 'GIN',
      sort: true,
    },
    {
      dataField: 'Amount',
      text: 'Amount',
      sort: true,
    }
  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: this.state.productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (this.state.productData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;


  
    return (




     <>

<React.Fragment>
        <div className="page-content" style={{marginTop:160}}>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              style={{fonStyle: ''}}
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
           
                         <Row>

              <Col className="col-12">
                <Card>
                  <CardBody>
                    
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                       

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
              </Row>




                         
                         </Container>
                         </div>
                       </React.Fragment>
  </>
    );
  }
}
export default compose(container)(pageList_ManagementEntranceFee);
