import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_AddEditDataN } from "../../store/functions";
import  Switch  from "react-switch";




class pageAddEdit_DebitCredit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      SenderName: [
        {
          Name : ""
        }
      ],
      ReceiverName: [
        {
          Name : ""
        }
      ],
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      switch9 : true,
    
      
     



    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "DEBIT/CREDIT";
    this.breadCrumbTitle = "DEBIT/CREDIT";
    this.breadCrumbItem =  this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = "CreditDebit/0/token";
    this.pushFormName = "/#";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.GetLedger = this.GetLedger.bind(this);
  }




  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem)
    //Filling DropDowns
     // Fn_FillListData(this.obj, "periodicity", API_WEB_URLS.MASTER + "/0/token/Periodicity/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }

  
  btnSave_onClick(event, formData) {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    let vformData = new FormData();
   
    vformData.append("F_MemberMaster", this.state.SenderName[0].Id);
    vformData.append("F_LedgerMaster", 1278);
    vformData.append("TranType", this.state.switch9 ? true : false);
    vformData.append("Amount", formData.transactionamount);
    vformData.append("Reference", formData.reference);
    vformData.append("Remarks", formData.Remarks);
    vformData.append("UserId", obj.uid);
  

    if (!this.state.id)

    Fn_AddEditDataN(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true);
    else
      Fn_AddEditDataN(
        this.obj,
        { arguList: { id: this.state.id, name: formData.Name } },
        this.API_URL,
        this.pushFormName
      );
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetSenderName =(event)=> {
    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "SenderName",  API_WEB_URLS.MASTER + "/0/token/GetBalance/1/Mem/Balance?ListFor=" + value + "&F_AccountType=11");
  }


  GetReceiverName =(event)=> {
    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "ReceiverName",  API_WEB_URLS.MASTER + "/0/token/GetBalance/1/Mem/Balance?ListFor=" + value + "&F_AccountType=11");
  }




  GetLedger =(event)=> {
    var value  =  event.target.value;
    Fn_FillListData(this.obj, "Ledger",  "SearchLedger/0/token?ListFor=" + value );
    
  }








  render() {
    const Offsymbolb = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          CR
        </div>
      )
    }
    
    
    const OnSymbolb = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          DR
        </div>
      )
    }
    


    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">DEBIT/CREDIT</h4>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">TRANSACTION ACCOUNT</h4>
                             
                              <Row>

                              <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">GIN / EIN</label>
                                        </Col>
                                <Col lg="6">

                                <AvField name="sendertransactionacno" onChange={this.GetSenderName}  value={this.state.formData.sendertransactionacno} placeholder="Enter SenderTransactionAcNo" errorMessage="Enter SenderTransactionAcNo" validate={{ required: { value: true } }} type="text" className="form-control" />
                                 
                                </Col>
                                    </Row>

                                    <Row>

                                      <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Member`s Name</label>
                                        </Col>
                                <Col lg="6">


                                <AvField name="sendername"  value={this.state.SenderName[0].Name} placeholder=" Sender Name" errorMessage=" Sender Name"  type="text" disabled className="form-control" />
                                  </Col>


                            </Row>



                            
                            <Row>


                            {/* <Col lg="4">

                            <AvField name="SearchLedger" onChange={this.GetLedger}  value={this.state.formData.SearchLedger} placeholder="Search Ledger"  type="text" className="form-control" />
                            
                            </Col>

                            <Col sm="6">
                            <AvField name="LedgerId" label="" value={this.state.formData.LedgerId}   type="select" className="form-select" >
                                            <option value={0} defaultValue label={"Select Ledger"} />
                                            {this.state.Ledger
                                              ? this.state.Ledger.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                    </Col> */}
      </Row>

      <Row>

       
      <Col sm="4" className="mb-3">
                                          <label htmlFor="middleName" className="col-form-label">Tran Type</label>
                                        </Col>
                                        <Col sm="2">
                                        <Switch
                            uncheckedIcon={<Offsymbolb />}
                            checkedIcon={<OnSymbolb />}
                            className="me-3 mb-lg-8 mb-2"
                            onColor="#ec4561"
                            offColor="#00ff00"
                            onChange={() =>
                              this.setState({ switch9: !this.state.switch9 })
                            }
                            checked={this.state.switch9}
                          />
                                        </Col>

       


</Row>











<Row>

<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Transaction Amount</label>
          </Col>
  <Col lg="6">

  <AvField name="transactionamount"  value={this.state.formData.transactionamount} placeholder="Enter Transaction Amount" errorMessage="Enter Transaction Amount" validate={{ required: { value: true } }} type="number" className="form-control" />
   
  </Col>
</Row>


<Row>

<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Reference</label>
          </Col>
  <Col lg="8">


  <AvField name="reference"  value={this.state.formData.reference} placeholder="reference" errorMessage="reference"  type="text" className="form-control" />
    </Col>


</Row>


<Row>

<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Remarks/Narration</label>
          </Col>
  <Col lg="8">


  <AvField name="Remarks"  value={this.state.formData.Remarks} placeholder="Remarks" errorMessage="Remarks"  type="text" className="form-control" />
    </Col>


</Row>




                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Save
                            </Button>{" "}
                            <Button type="reset" color="secondary">
                              Cancel
                            </Button>
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_DebitCredit);
