/* eslint-disable */


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
import SweetAlert from "react-bootstrap-sweetalert"
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_GetReport } from "../../store/functions";
import { bind } from "lodash";




class pageAddEdit_ReverseMortgage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      Accountdetail: [{
        Name : ""
      }],
      RecipentDetail: [
        {
          Name : ""
        }
      ],
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype : [],
      SAccountType :0,
     
            BoldToken : [{
              Token : ''
            }],
      Account  : [],
      success_msg : false,
      VoucherId : 0,
      PreLoanDetails: [{}],
      IsAPR : false


    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "REVERSE MORTGAGE LOAN ";
    this.breadCrumbTitle = "REVERSE MORTGAGE LOAN ";
    this.breadCrumbItem =  this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.TransactionDeposit + "/0/token";
    this.pushFormName = "/dashboard";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno =  this.syno.bind(this);
    this.tog_fullscreen = this.tog_fullscreen.bind(this);
    this.prin  =  this.prin.bind(this);
    this.printtest  =  this.printtest.bind(this);
    this.btnReset_onClick  =  this.btnReset_onClick.bind(this);
    this.onchangeInt  =  this.onchangeInt.bind(this);
  }




  componentDidMount() {
    //Filling DropDowns
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
      
    localStorage.setItem("PageName", this.breadCrumbItem)
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
    

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }



  btnSave_onClick(event, formData) {



    


    console.log(formData.AccountValue);

    let vformData = new FormData();
   
    vformData.append("F_MemberAccountMaster", this.state.MemberName[0].F_MemberAccountMaster);
    vformData.append("Amount", formData.Amount);
    vformData.append("TransactionType", 2);
    vformData.append("Narration", formData.narration);
    vformData.append("F_UserMaster", this.state.aid);


    
    
    if (!this.state.id)

   

    Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, "", "#", true , "VoucherId" , this.printtest);




    else
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, name: formData.Name } },
        this.API_URL,
        this.pushFormName
      );

      this.setState({
        formData: {
          'AccountValue' : 0,
          'membershipno' : '',
          'MembershipTypeId' : 0,
          'Amount' : '',
          'TransactionTypeValue' : 0,
          'narration' : ''
        },
        success_msg : true

      });




  }



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  
  btnReset_onClick = event => {
    this.setState({
      Balance : [{
        Balance : 0
       }],
      MemberName : [
  {
    Name : ''
  }
      ],
      MembershipNo : ''
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetAccountDetail =(event)=> {
    var value  =  event.target.value;
    Fn_FillListData(this.obj, "Accountdetail", API_WEB_URLS.MASTER + "/0/token/ListOfAccountsByMember/Id/"+this.state.MemberName[0].Id);
  }



  syno () {

    this.setState({
      success_msg : false
    });

      };



  GetAccountDetail2 =(event)=> {
    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "RecipentDetail", API_WEB_URLS.MASTER + "/0/token/GetBalance/1/Mem/Balance?ListFor="+value+"&F_AccountType=11");
  }


  GetMemberName =(event)=> {
    if(event.target.value.length == 3 || event.target.value.length == 12){
      var value = event.target.value;
      value =  value + '-';
      
      this.setState({
        MembershipNo: value
      });
    }


    let vformData = new FormData();
   
    vformData.append("MembershipNo", event.target.value);
   
    Fn_GetReport(this.obj, {  arguList: {id: 0, formData: vformData } }, "PreLoanDetails/0/token", "PreLoanDetails", true );
    

  }


  printtest () {
    var prevState  =  this.state.modal_fullscreen;
   
    this.setState({
      modal_fullscreen:  true
      
  }, () => {
      this.prin();
  })
    this.removeBodyCss()
   
  }

  prin() {

    this.timer = setTimeout(() => {
      var reportviewerObj = $("#reportviewer-container").data("boldReportViewer"); reportviewerObj.print();
    }, 1000);
  
  }

 


  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }


  onchangeInt (event) {

    if (event.target.value ==  1){
      this.setState({IsAPR : true})
    }

    else {
      this.setState({IsAPR : false})
    }
    

  }






  render() {
    var parameters = [{
      name: 'Id',
      values: [this.state.VoucherId]
      }
    ];


    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">CASH LOAN</h4>
                                      <Row>
<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Member GIN</label>
          </Col>
  <Col lg="6">
    

  <AvField name="membershipno" onChange={this.GetMemberName}  value={this.state.MembershipNo} placeholder="Member GIN" errorMessage="Enter Member GIN" validate={{ required: { value: true } }} type="text" className="form-control" />
   
  </Col>
</Row>

<Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Member</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="Member"  value={this.state.PreLoanDetails[0].Name}  placeholder=" Member Name" errorMessage=" Sender Name"  type="text" disabled className="form-control" />
                                         
                                        </Col>
                                      </Row>





                                     <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Shares</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="Share"   value={this.state.PreLoanDetails[0].Share} placeholder="Shares"  type="text" className="form-control"  disabled/>
                                        </Col>
                                      </Row>





                                    <Row>
                                    <Col sm="4">
                                                <label htmlFor="membershipType" className="col-form-label">Loan limit by Shares</label>
                                              </Col>
                                      <Col lg="6">
                                      <AvField name="LoanLimitByShare"  value={this.state.PreLoanDetails[0].LoanLimitByShare} placeholder="LoanLimitByShare"    type="text" className="form-control" disabled />
                                        </Col>
                                    </Row>



                                    <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Gold Stock</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="Caveo" label="" value={this.state.PreLoanDetails[0].Caveo} placeholder="GoldStock" errorMessage="GoldStock"  type="number" className="form-control"  disabled>
                                       
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Loan Limit By Gold Stock</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="CaveoLimit" label="" value={this.state.PreLoanDetails[0].CaveoLimit} placeholder="LoanLimitByGoldStock" errorMessage="Loan Limit By Gold Stock"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Gross Loan Limit</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="GrossLoanLimit" label="" value={this.state.PreLoanDetails[0].GrossLoanLimit} placeholder="GrossLoanLimit"   type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">OutstandingLoan</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="OutstandingLoan" label="" value={this.state.PreLoanDetails[0].OutstandingLoan} placeholder="OutstandingLoan"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">NetLoanLimit</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="NetLoanLimit" label="" value={this.state.PreLoanDetails[0].NetLoanLimit} placeholder="OutstandingLoan"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>



                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Loan Amount Required</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="LoanAmountRequired" label="" value={this.state.formData.LoanAmountRequired} placeholder="LoanAmountRequired"  type="number" className="form-control"  >
                                           
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Loan Duration</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="LoanDuration" label="" value={this.state.formData.LoanDuration} placeholder="LoanDuration"  type="number" className="form-control"  >
                                           
                                          </AvField>
                                        </Col>
                                      </Row>

                                      

                                      <Row>
                                      <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Loan Type</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="LoanType" label="" value={this.state.formData.LoanType}  errorMessage="Select Loan Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select Loan Type"} />
                                            <option value={1} defaultValue label={"Person Loan"} />
                                            <option value={2} defaultValue label={"Student Loan"} />
                                            <option value={3} defaultValue label={"Funeral Loan"} />
                                            <option value={4} defaultValue label={"Business Loan"} />
                                          </AvField>
                                        </Col>
                                      </Row>




                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">APR (Interest Rate)</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="InterestRate" label="" value={8} placeholder="InterestRate"  type="number" className="form-control"  disabled>
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                      <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Select Plan</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="PlanTypeId" label="" value={this.state.formData.PlanTypeId} onChange={this.onchangeInt}  errorMessage="Select Plan" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select Plan"} />
                                            <option value={1} defaultValue label={"APR"} />
                                            <option value={2} defaultValue label={"EMI"} />
                                          </AvField>
                                        </Col>
                                      </Row>


                                      {this.state.IsAPR == true ? 

                                      <Row>
                                      <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Select APR Type</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="APRTypeId" label="" value={this.state.formData.APRTypeId}  errorMessage="Select APR Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select APR Type"} />
                                            <option value={1} defaultValue label={"Reducing Balance "} />
                                            <option value={2} defaultValue label={"Variable Payments"} />
                                          </AvField>
                                        </Col>
                                      </Row>

                                      : null } 



                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Monthly Repayment</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="MonthlyRepayment" label="" value={this.state.formData.MonthlyRepayment} placeholder="MonthlyRepayment"  type="number" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Total Amount Payable</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="TotalAmountPayable" label="" value={this.state.formData.TotalAmountPayable} placeholder="TotalAmountPayable"  type="number" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>

                                      
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Total Interest Payable</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="TotalInterestPayable" label="" value={this.state.formData.TotalInterestPayable} placeholder="TotalAmountPayable"  type="number" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>


         

                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Save
                            </Button>{" "}

                            {this.state.success_msg ? (
                      <SweetAlert
                        title="Transaction Successful!"
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={this.syno}
                      >
                       
                      </SweetAlert>
                    ) : null}
                            {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnReset_onClick}
                        >
                              Reset
                            </Button>
                            
                            {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnReset_onClick}
                        >
                              Print
                            </Button>
                            
                            {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                              Exit
                            </Button>

                            
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                    
                    </CardTitle>
                 
                    <div>
                     
                      <Modal
                        size="xl"
                        isOpen={this.state.modal_fullscreen}
                        toggle={this.tog_fullscreen}
                        className="modal-fullscreen"
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title mt-0"
                            id="exampleModalFullscreenLabel"
                          >
                           Print
                          </h5>
                          <button
                            onClick={() =>
                              this.setState({ modal_fullscreen: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                        <BoldReportViewerComponent id="reportviewer-container"
reportServiceUrl = {'http://shinewellsofttech.co.in:50693/reporting/reportservice/api/Viewer'}
reportServerUrl= {'http://shinewellsofttech.co.in:50693/reporting/api/site/site1'}
serviceAuthorizationToken = {'bearer '+this.state.BoldToken[0].Token}
reportPath = {'/Ghana/DepositReciept'}
parameters = {parameters}
>
</BoldReportViewerComponent>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={this.tog_fullscreen}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                      
                        </div>
                      </Modal>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_ReverseMortgage);

