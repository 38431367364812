import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue } from "../../store/functions";

export const DateString = (dd) =>{

let d1 =  dd.replace('-', '');
let d2 = d1.replace('-', '');


return d2;


}


class pageAddEdit_MembershipSynopsis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {
        IsActive : true
      },
      dis : true,
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1 : '',
      BrowseImage2 : '',
      UserImage : '',
      Signature : '',
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid : 0,
      name : "",
      email : "",
      aid : 0,
      fo3 : false,
      Gender : 0,
      MaritalStatus : 0,
      success_msg : false,
      F_MISScheme : 0,
      MembershipType  : 0,
      edit : false
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Membership";
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/MemberEdit";
    this.API_URL_SAVE = API_WEB_URLS.MEMBER + "/0/token";
    this.pushFormName = "/memberships";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.printInvoice =  this.printInvoice.bind(this);
    this.approve =  this.approve.bind(this);
    this.blacklist =  this.blacklist.bind(this);
   

  }



  componentDidMount() {

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", this.breadCrumbItem)

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
 


if (obj.roleid == 1) {
  Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/2");
}
else {
  Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/4");
}


    //Filling DropDowns
  
    Fn_FillListData(this.obj, "maritalStatus", API_WEB_URLS.MASTER + "/0/token/MaritalStatus/Id/0");
    Fn_FillListData(this.obj, "bloodGroup", API_WEB_URLS.MASTER + "/0/token/BloodGroup/Id/0");
    Fn_FillListData(this.obj, "gender", API_WEB_URLS.MASTER + "/0/token/Gender/Id/0");
    Fn_FillListData(this.obj, "education", API_WEB_URLS.MASTER + "/0/token/Education/Id/0");
    Fn_FillListData(this.obj, "profession", API_WEB_URLS.MASTER + "/0/token/Profession/Id/0");
    Fn_FillListData(this.obj, "relationShip", API_WEB_URLS.MASTER + "/0/token/Relationship/Id/0");
    Fn_FillListData(this.obj, "nationality", API_WEB_URLS.MASTER + "/0/token/Country/Id/0");
    Fn_FillListData(this.obj, "typeofRegCert", API_WEB_URLS.MASTER + "/0/token/IDDocumentType/Id/0");
    Fn_FillListData(this.obj, "MISScheme", API_WEB_URLS.MASTER + "/0/token/MISScheme/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = " " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
      Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");
      this.setState({ dis: false , edit:true});

    } else {
      this.setState({ id: 0 ,   formData: {
        IsActive : 'True'
      }, });
    }


    
  }




DOBChange = (event)=> {

  var birth  =  new Date(event.target.value);
   birth = birth.getFullYear();

    var tod =  new Date();

    tod =  tod.getFullYear();

    var age = tod - birth;

    

    this.setState({ fo: age });




    if ((age <17 && this.state.MembershipType == 3)  || (age>85 && this.state.MembershipType == 3 )) {
      alert("Excempt Member can't be " + age + " It should be under 17 or older than 85.");
    }


if (age > 0 && age<=15){
  this.setState({ F_MISScheme: 5 });
}

else if (age>15 && age<= 20){
  this.setState({ F_MISScheme: 4 });
}


else if (age>20 && age<= 35){
  this.setState({ F_MISScheme: 3 });
}


else if (age>35 && age<= 50){
  this.setState({ F_MISScheme: 2 });
}


else if (age>50 ){
  this.setState({ F_MISScheme: 1 });
}



  
}


checkfemale =  (event) =>{

  var Gender =  event.target.value;

  if(Gender == 2) {
  this.setState({ Gender: Gender });

  var MaritalStatus  =  this.state.MaritalStatus;

  if (MaritalStatus == 1 || MaritalStatus == 3 || MaritalStatus  ==  4 || MaritalStatus == 5 ){
    this.setState({ fo3: true });
  }


  else {
    this.setState({ fo3: false });
  }

  }


  else {
    this.setState({ fo3: false });
  }


}


checkMarital  =  (event) => {
  var MaritalStatus =  event.target.value;
  this.setState({ MaritalStatus: MaritalStatus });

  if (this.state.Gender == 2) {

  if (MaritalStatus == 1 || MaritalStatus == 3 || MaritalStatus  ==  4 || MaritalStatus == 5 ){
    this.setState({ fo3: true });
  }

  else {
    this.setState({ fo3: false });
  }


  }

  else {
    this.setState({ fo3: false });
  }


}



NomineeDOBChange = (event)=> {

  var birth  =  new Date(event.target.value);
   birth = birth.getFullYear();

    var tod =  new Date();

    tod =  tod.getFullYear();

    var age = tod - birth;
    this.setState({ fo2: age });
  
}


membershiptype  =  (event)=> {

var MembershipType  =  event.target.value;

this.setState({ MembershipType: MembershipType });

}






  fun() {

  }

  approve =  event => {
    event.preventDefault();
    this.props.history.push(`${this.rtPage_Edit}/${this.state.id}`, {});
  }



  blacklist =  event => {
    event.preventDefault();
    alert("Member Blacklisted Successfully!");
    this.props.history.push(`${this.pushFormName}`, {});
  }


  syno () {

    this.setState({
      success_msg : false
    });
        //event.preventDefault();
        this.props.history.push(`${this.rtPage_Print}/${this.state.memberid}`, {});

      };


    


  
  printInvoice ()  {
  
    this.props.history.push(`/membersynopsisprint/${this.state.id}`, {});
  };
  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }
  

  render() {



   

    const options = [
      { value: "AK", label: "Alaska" },
      { value: "HI", label: "Hawaii" },
      { value: "CA", label: "California" },
      { value: "NV", label: "Nevada" },
      { value: "OR", label: "Oregon" },
      { value: "WA", label: "Washington" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                            >
                              <span className="number">1.</span> Personal
                              Information
                            </NavLink>
                          </NavItem>
                          <NavItem

className={classnames({
  current: this.state.activeTab === 2,
})}
>
<NavLink
  className={classnames({
    active: this.state.activeTab === 2,
  })}
  onClick={() => {
    this.toggleTab(2);
  }}
>
  <span className="number">2.</span> 
  Family & Nominee Information
</NavLink>


                        
                          </NavItem>



                          <NavItem
className={classnames({
  current: this.state.activeTab === 3,
})}
>


<NavLink
  className={classnames({
    active: this.state.activeTab === 3,
  })}
  onClick={() => {
    this.toggleTab(3);
  }}
>
  <span className="number">3.</span> 
  Identity Documentation
</NavLink>
                            
                          </NavItem>
                          <NavItem

                          
className={classnames({
  current: this.state.activeTab === 4,
})}
>
<NavLink
  className={classnames({
    active: this.state.activeTab === 4,
  })}
  onClick={() => {
    this.toggleTab(4);
  }}
>
  <span className="number">4.</span> 
Confirm Details
</NavLink>
                          
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick} ref={c => (this.form = c)}>
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Information</h4>
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Membership Type </label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MembershipTypeId" label="" value={this.state.formData.MembershipTypeId} onChange={this.membershiptype} errorMessage="Select Membership Type" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.membershipType
                                              ? this.state.membershipType.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="dateOfJoining" className="col-form-label">Date of Joining</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="4" className="mb-0">
                                          <AvField name="DateOfJoining" label="" value={this.state.formData.DateOfJoining === null ? ''   : this.state.formData.DateOfJoining} placeholder="Enter Date of Joining" errorMessage="Enter Date of Joining" validate={{ required: { value: true } }} type="date" disabled className="form-control" />
                                        </Col>  
                                      </Row>

                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="isactive" className="col-form-label">Is Active </label>
                                        </Col>
                                        <Col sm="4" className="mb-0">
                                        <AvField name="IsActive" label="" checked={this.state.formData.IsActive === 'True' ? true   : false}    type="checkbox" className="form-control" disabled />
                                        </Col>  
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">First Name</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="FirstName" label="" value={this.state.formData.FirstName === null ? ''   : this.state.formData.FirstName} placeholder="Enter First Name" errorMessage="Enter First Name" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="middleName" className="col-form-label">Middle Name</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MiddleName" label="" value={this.state.formData.MiddleName === null ? ''   : this.state.formData.MiddleName} placeholder="Enter Middle Name"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="lastName" className="col-form-label">Last Name</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="SurName" label="" value={this.state.formData.SurName === null ? ''   : this.state.formData.SurName} placeholder="Enter Last Name" errorMessage="Enter Last Name" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Maiden name</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MaidenName" label="" value={this.state.formData.MaidenName === null ? ''   : this.state.formData.MaidenName} placeholder="Enter Maiden Name" errorMessage="Enter Maiden Name" validate={{ required: { value: this.state.fo3 } }} type="text" className="form-control" disabled />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Date of Birth</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField name="DateOfBirth" onChange={this.DOBChange} label="" value={this.state.formData.DateOfBirth === null ? ''   : this.state.formData.DateOfBirth} placeholder="Enter Date of Birth" errorMessage="Enter Date of Birth" validate={{ required: { value: true } }} type="date"  className="form-control" disabled />
                                        </Col>
                                        <Col sm="1" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Age</label>
                                        </Col>
                                        <Col sm="2">
                                          <AvField name="Age" label="" value={this.state.fo === undefined ? this.state.formData.Age   : this.state.fo} placeholder="" errorMessage="Enter Age"  type="text" disabled className="form-control"  />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maritalStatus" className="col-form-label">Marital Status</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MaritalStatusId" onChange={this.checkMarital} label="" value={this.state.formData.MaritalStatusId === null ? '-1'   : this.state.formData.MaritalStatusId} errorMessage="Select Marital Status Type" validate={{ required: { value: true } }} type="select" className="form-select" disabled >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.maritalStatus
                                              ? this.state.maritalStatus.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col> 
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="bloodGroup" className="col-form-label">Blood Group</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="BloodGroupId" label="" value={this.state.formData.BloodGroupId === null ? '-1'   : this.state.formData.BloodGroupId}  errorMessage="Select Blood group" validate={{ required: { value: true } }}  type="select" className="form-select" disabled>
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.bloodGroup
                                              ? this.state.bloodGroup.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="gender" className="col-form-label">Gender</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField onChange={this.checkfemale} name="GenderId" label="" value={this.state.formData.GenderId === null ? '-1'   : this.state.formData.GenderId} errorMessage="Select Gender Type" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.gender
                                              ? this.state.gender.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Country</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="CountryId" label="" value={this.state.formData.CountryId === null ? '-1'   : this.state.formData.CountryId} errorMessage="Select Country" validate={{ required: { value: true } }} type="select" className="form-select"disabled >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="homeTown" className="col-form-label">Hometown</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="HomeTown" label="" value={this.state.formData.HomeTown === null ? ''   : this.state.formData.HomeTown} placeholder="Enter Hometown" errorMessage="Enter Hometown" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="education" className="col-form-label">Education</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="EducationId" label="" value={this.state.formData.EducationId === null ? '-1'   : this.state.formData.EducationId} errorMessage="Select Education Type" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.education
                                              ? this.state.education.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="profession" className="col-form-label">Profession</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="ProfessionId" label="" value={this.state.formData.ProfessionId === null ? '-1'   : this.state.formData.ProfessionId} errorMessage="Select Profession Type" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.profession
                                              ? this.state.profession.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Email" className="col-form-label">Email</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Email" label="" value={this.state.formData.Email === null ? ''   : this.state.formData.Email} placeholder="Enter Email"  type="email" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Phone" className="col-form-label">Phone</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MobileNo_SMS" label="" value={this.state.formData.MobileNo_SMS === null ? ''   : this.state.formData.MobileNo_SMS} placeholder="Enter Phone"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="nameOfSpouse" className="col-form-label">Name of Spouse</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="SpouseName" label="" value={this.state.formData.SpouseName === null ? ''   : this.state.formData.SpouseName} placeholder="Enter Name of Spouse"  type="text" className="form-control"disabled />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="spouseMemNo" className="col-form-label">Spouse Mem. No.</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MemberId_Spouse" label="" value={this.state.formData.MemberId_Spouse === null ? ''   : this.state.formData.MemberId_Spouse}  placeholder="Enter Spouse Mem. No."   type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                  </Col>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Address</h4>
                                      <Row>
                                        <Col lg="12">
                                            <Label for="basicpill-address-input1">
                                              Residential Address
                                            </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="basicpill-address-input1" className="col-form-label">Full Address</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          {/* <textarea
                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="2"
                                            placeholder="Enter Your Address"
                                          /> */}

                              <AvField name="Address_Residential" label="" value={this.state.formData.Address_Residential === null ? ''   : this.state.formData.Address_Residential} placeholder="Enter Full Address" errorMessage="Enter Full Address" validate={{ required: { value: true } }} type="text" className="form-control" disabled />

                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="postCode" className="col-form-label">Post Code</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Pincode_Residential" label="" value={this.state.formData.Pincode_Residential === null ? ''   : this.state.formData.Pincode_Residential} placeholder="Enter Post Code" errorMessage="Enter Post Code" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="mobilePhone1" className="col-form-label">Mobile Phone 1</label><label style={{color:"red"}}>*</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MobileNo1_Residential" label="" value={this.state.formData.MobileNo1_Residential === null ? ''   : this.state.formData.MobileNo1_Residential} placeholder="Enter Mobile Phone 1" errorMessage="Enter Mobile Phone1" validate={{ required: { value: true } }}  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="mobilePhone2" className="col-form-label">Mobile Phone 2</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MobileNo2_Residential" label="" value={this.state.formData.MobileNo2_Residential === null ? ''   : this.state.formData.MobileNo2_Residential} placeholder="Enter Mobile Phone 2"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="telephone1" className="col-form-label">Telephone 1</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="PhoneNo1_Residential" label="" value={this.state.formData.PhoneNo1_Residential === null ? ''   : this.state.formData.PhoneNo1_Residential} placeholder="Enter Telephone 1"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="telephone2" className="col-form-label">Telephone 2</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="PhoneNo2_Residential" label="" value={this.state.formData.PhoneNo2_Residential === null ? ''   : this.state.formData.PhoneNo2_Residential} placeholder="Enter Telephone 2"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg="6">
                                            <Label for="basicpill-lastname-input2">
                                              Business Address
                                            </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="basicpill-address-input1" className="col-form-label">Full Address</label>
                                        </Col>
                                        <Col sm="6">
                                            {/* <textarea 
                                              id="basicpill-address-input1"
                                              className="form-control"
                                              rows="2"
                                              placeholder="Enter Your Address"
                                            /> */}


                                          <AvField name="Address_Business" label="" value={this.state.formData.Address_Business === null ? ''   : this.state.formData.Address_Business} placeholder="Enter Full Address"  type="text" className="form-control" disabled/>

                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Pincode_Business" className="col-form-label">Post Code</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Pincode_Business" label="" value={this.state.formData.Pincode_Business === null ? ''   : this.state.formData.Pincode_Business} placeholder="Enter Post Code"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="MobileNo1_Business" className="col-form-label">Mobile Phone 1</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MobileNo1_Business" label="" value={this.state.formData.MobileNo1_Business === null ? ''   : this.state.formData.MobileNo1_Business} placeholder="Enter Mobile Phone 1"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="MobileNo2_Business" className="col-form-label">Mobile Phone 2</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MobileNo2_Business" label="" value={this.state.formData.MobileNo2_Business === null ? ''   : this.state.formData.MobileNo2_Business} placeholder="Enter Mobile Phone 2"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="PhoneNo1_Business" className="col-form-label">Telephone 1</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="PhoneNo1_Business" label="" value={this.state.formData.PhoneNo1_Business === null ? ''   : this.state.formData.PhoneNo1_Business} placeholder="Enter Telephone 1"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="PhoneNo2_Business" className="col-form-label">Telephone 2</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="PhoneNo2_Business" label="" value={this.state.formData.PhoneNo2_Business === null ? ''   : this.state.formData.PhoneNo2_Business} placeholder="Enter Telephone 2"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="PhoneNo2_Business" className="col-form-label">Emergency Contact Person</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="EmergencyContactPerson" label="" value={this.state.formData.EmergencyContactPerson === null ? ''   : this.state.formData.EmergencyContactPerson} placeholder="Enter EmergencyContactPerson"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="PhoneNo2_Business" className="col-form-label">Emergency Contact Person Address</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="EmergencyContactPersonAddress" label="" value={this.state.formData.EmergencyContactPersonAddress === null ? ''   : this.state.formData.EmergencyContactPersonAddress} placeholder="Enter EmergencyContactPersonAddress"  type="text" className="form-control" disabled />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="PhoneNo2_Business" className="col-form-label">Emergency Contact Person M. No. 2</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="EmergencyContactPersonMobileNo1" label="" value={this.state.formData.EmergencyContactPersonMobileNo1 === null ? ''   : this.state.formData.EmergencyContactPersonMobileNo1} placeholder="Enter EmergencyContactPersonMobileNo1"  type="text" className="form-control" disabled/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="PhoneNo2_Business" className="col-form-label">Emergency Contact Person M. No. 2</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="EmergencyContactPersonMobileNo2" label="" value={this.state.formData.EmergencyContactPersonMobileNo2 === null ? ''   : this.state.formData.EmergencyContactPersonMobileNo2} placeholder="Enter EmergencyContactPersonMobileNo2"  type="text" className="form-control" disabled />
                                        </Col>
                                      </Row>

                                    
                                    </CardBody>
                                  </Card>
                                  </Col>
                                </Row>
                                <Row>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Introducer Information</h4>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="Member_Introducer" className="col-form-label">Introducer Name</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="Member_Introducer" label="" value={this.state.formData.Member_Introducer === null ? ''   : this.state.formData.Member_Introducer} placeholder="Enter Introducer Name"   type="text" className="form-control" disabled/>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Introducer No.</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MemberId_Introducer" label="" value={this.state.formData.MemberId_Introducer === null ? ''   : this.state.formData.MemberId_Introducer} placeholder="Enter Introducer No."   type="text" className="form-control"disabled />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="relationship" className="col-form-label">RelationShip</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="RelationshipId_Introducer" label="" value={this.state.formData.RelationshipId_Introducer === null ? '-1'   : this.state.formData.RelationshipId_Introducer}  type="select" className="form-select"disabled >
                                              <option value={-1} defaultValue label={"Select"} />
                                              {this.state.relationShip
                                                ? this.state.relationShip.map(
                                                    (option, key) => (
                                                      <option key={option.Id} value={option.Id} label={option.Name} />
                                                    )
                                                  )
                                                : null}
                                            </AvField>
                                          </Col>
                                        </Row>
                                        <Row>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Agent/Staff Capturing Data</h4>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentCode" className="col-form-label">Agent/Staff Code</label>
                                          </Col>
                                          <Col sm="6">
                                            <input name="MemberId_Agent" label="" value={this.state.email} placeholder="Enter Agent/Staff Code" errorMessage="Enter Agent/Staff Code"  disabled type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentName" className="col-form-label">Agent/Staff Name</label>
                                          </Col>
                                          <Col sm="6">
                                            <input name="AgentName" label="" value={this.state.name} placeholder="Enter Agent/Staff Name" errorMessage="Enter Agent/Staff Name"  type="text" disabled className="form-control" />
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                                  <Row>
                                    <Col lg="4">
                                      <Card>
                                        <CardBody style={{"padding":0}}>
                                          <h4 className="card-title mb-4">Father&apos;s Information</h4>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="FirstName_Father" className="col-form-label">First Name</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="FirstName_Father" label="" value={this.state.formData.FirstName_Father === null ? ''   : this.state.formData.FirstName_Father} placeholder="Enter First Name" errorMessage="Enter First Name" validate={{ required: { value: true } }} type="text" className="form-control" disabled />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="MiddleName_Father" className="col-form-label">Middle Name</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="MiddleName_Father" label="" value={this.state.formData.MiddleName_Father === null ? ''   : this.state.formData.MiddleName_Father} placeholder="Enter Middle Name"  type="text" className="form-control"disabled />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="SurName_Father" className="col-form-label">Last Name</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="SurName_Father" label="" value={this.state.formData.SurName_Father === null ? ''   : this.state.formData.SurName_Father} placeholder="Enter Last Name" errorMessage="Enter Last Name" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="HomeTown_Father" className="col-form-label">Hometown</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="HomeTown_Father" label="" value={this.state.formData.HomeTown_Father === null ? ''   : this.state.formData.HomeTown_Father} placeholder="Enter Hometown" errorMessage="Enter Hometown" validate={{ required: { value: true } }} type="text" className="form-control"disabled />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="CountryId_Father" className="col-form-label">Nationality</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="CountryId_Father" label="" value={this.state.formData.CountryId_Father === null ? '-1'   : this.state.formData.CountryId_Father} errorMessage="Select Nationality" validate={{ required: { value: true } }} type="select" className="form-select"disabled >
                                                <option value={-1} defaultValue label={"Select"} />
                                                {this.state.nationality
                                                  ? this.state.nationality.map(
                                                      (option, key) => (
                                                        <option key={option.Id} value={option.Id} label={option.Name} />
                                                      )
                                                    )
                                                  : null}
                                              </AvField>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="IsLive_Father" className="col-form-label">Father&apos;s Status</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">

                                            <AvField name="IsLive_Father" label="" value={this.state.formData.IsLive_Father === null ? '-1'   : this.state.formData.IsLive_Father} errorMessage="Select Status" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                                <option value={-1} defaultValue label={"Select"} />
                                                <option value={0}  label={"Alive"} />
                                                <option value={1}  label={"Dead"} />
                                                  </AvField>
                                             
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="4">
                                      <Card>
                                        <CardBody style={{"padding":0}}>
                                          <h4 className="card-title mb-4">Mother&apos;s Information</h4>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="FirstName_Mother" className="col-form-label">First Name</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="FirstName_Mother" label="" value={this.state.formData.FirstName_Mother === null ? ''   : this.state.formData.FirstName_Mother} placeholder="Enter First Name" errorMessage="Enter First Name" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="MiddleName_Mother" className="col-form-label">Middle Name</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="MiddleName_Mother" label="" value={this.state.formData.MiddleName_Mother === null ? ''   : this.state.formData.MiddleName_Mother} placeholder="Enter Middle Name"  type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="SurName_Mother" className="col-form-label">Last Name</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="SurName_Mother" label="" value={this.state.formData.SurName_Mother === null ? ''   : this.state.formData.SurName_Mother} placeholder="Enter Last Name" errorMessage="Enter Last Name" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="HomeTown_Mother" className="col-form-label">Hometown</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="HomeTown_Mother" label="" value={this.state.formData.HomeTown_Mother === null ? ''   : this.state.formData.HomeTown_Mother} placeholder="Enter Hometown" errorMessage="Enter Hometown" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="CountryId_Mother" className="col-form-label">Nationality</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="CountryId_Mother" label="" value={this.state.formData.CountryId_Mother === null ? '-1'   : this.state.formData.CountryId_Mother} errorMessage="Select Nationality" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                                <option value={-1} defaultValue label={"Select"} />
                                                {this.state.nationality
                                                  ? this.state.nationality.map(
                                                      (option, key) => (
                                                        <option key={option.Id} value={option.Id} label={option.Name} />
                                                      )
                                                    )
                                                  : null}
                                              </AvField>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="IsLive_Mother" className="col-form-label">Mother&apos;s Status</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                            <AvField name="IsLive_Mother" label="" value={this.state.formData.IsLive_Mother === null ? '-1'   : this.state.formData.IsLive_Mother} errorMessage="Select Status" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                                <option value={-1} defaultValue label={"Select"} />
                                                <option value={0}  label={"Alive"} />
                                                <option value={1}  label={"Dead"} />
                                                  </AvField>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="4">
                                      <Card>
                                        <CardBody style={{"padding":0}}>
                                          <h4 className="card-title mb-4">Nominee&apos;s Information</h4>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="FirstName_Nominee" className="col-form-label">First Name</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="FirstName_Nominee" label="" value={this.state.formData.FirstName_Nominee === null ? ''   : this.state.formData.FirstName_Nominee} placeholder="Enter First Name" errorMessage="Enter First Name" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="MiddleName_Nominee" className="col-form-label">Middle Name</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="MiddleName_Nominee" label="" value={this.state.formData.MiddleName_Nominee === null ? ''   : this.state.formData.MiddleName_Nominee} placeholder="Enter Middle Name"  type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="SurName_Nominee" className="col-form-label">Last Name</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="SurName_Nominee" label="" value={this.state.formData.SurName_Nominee === null ? ''   : this.state.formData.SurName_Nominee} placeholder="Enter Last Name" errorMessage="Enter Last Name" validate={{ required: { value: true } }} type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="DateOfBirth_Nominee" className="col-form-label">Date of Birth</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="DateOfBirth_Nominee" onChange={this.NomineeDOBChange} label="" value={this.state.formData.DateOfBirth_Nominee === null ? ''   : this.state.formData.DateOfBirth_Nominee} placeholder="Enter Date of Birth" errorMessage="Enter Date of Birth" validate={{ required: { value: true } }} type="date"  className="form-control" disabled/>
                                            </Col>
                                          </Row>


                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="Age_Nominee" className="col-form-label">Age</label>
                                            </Col>
                                            <Col sm="7">
                                            <AvField name="Age" label="" value={this.state.fo2 === undefined ? this.state.formData.Age_Nominee   : this.state.fo2} placeholder="" errorMessage="Enter Age"  type="text" disabled className="form-control"  />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="GenderId_Nominee" className="col-form-label">Gender</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="GenderId_Nominee" label="" value={this.state.formData.GenderId_Nominee === null ? '-1'   : this.state.formData.GenderId_Nominee} errorMessage="Select Gender Type" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                                <option value={-1} defaultValue label={"Select"} />
                                                {this.state.gender
                                                  ? this.state.gender.map(
                                                      (option, key) => (
                                                        <option key={option.Id} value={option.Id} label={option.Name} />
                                                      )
                                                    )
                                                  : null}
                                              </AvField>
                                            </Col>
                                          </Row>



                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="RelationshipId_Nominee" className="col-form-label">RelationShip</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="RelationshipId_Nominee" label="" value={this.state.formData.RelationshipId_Nominee === null ? '-1'   : this.state.formData.RelationshipId_Nominee} errorMessage="Select RelationShip" validate={{ required: { value: true } }} type="select" className="form-select" disabled>
                                                <option value={-1} defaultValue label={"Select"} />
                                                {this.state.relationShip
                                                  ? this.state.relationShip.map(

                                                      (option, key) => (
                                                        <option key={option.Id} value={option.Id} label={option.Name} />
                                                      )
                                                    )
                                                  : null}
                                              </AvField>
                                            </Col>
                                          </Row>



                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="SurName_Nominee" className="col-form-label">Phone1</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="PhoneNo1_Nominee" label="" value={this.state.formData.PhoneNo1_Nominee === null ? ''   : this.state.formData.PhoneNo1_Nominee} placeholder="Enter Phone1"  type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>


                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="SurName_Nominee" className="col-form-label">Phone2</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="PhoneNo2_Nominee" label="" value={this.state.formData.PhoneNo2_Nominee === null ? ''   : this.state.formData.PhoneNo2_Nominee} placeholder="Enter Phone2"  type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="SurName_Nominee" className="col-form-label">Post Code</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="PostalCode_Nominee" label="" value={this.state.formData.PostalCode_Nominee === null ? ''   : this.state.formData.PostalCode_Nominee} placeholder="Enter Postal Code"  type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col sm="5" className="mb-3">
                                              <label htmlFor="SurName_Nominee" className="col-form-label">Address</label>
                                            </Col>
                                            <Col sm="7">
                                              <AvField name="Address_Nominee" label="" value={this.state.formData.Address_Nominee === null ? ''   : this.state.formData.Address_Nominee} placeholder="Enter Address"  type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>


                                        </CardBody>
                                      </Card>
                                    </Col>
                                  {/* </Row>
                                  <Row> */}
                                    
                                    </Row>
                                  <Row>
                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                          <h4 className="card-title mb-4">Payment</h4>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="MembershipFee" className="col-form-label">Membership Fee</label><label style={{color:"red"}}>*</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="MembershipFees" label="" value={this.state.formData.MembershipFees === undefined ? 1200   : this.state.formData.MembershipFees} placeholder="Enter Membership Fee"  type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>


                                          {this.state.edit ? (
                                            <>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="ShareValue" className="col-form-label">Share Value</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="ShareValue" label="" value={this.state.formData.ShareValue === undefined ? ''   : this.state.formData.ShareValue} placeholder="Enter Share Value"   type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="NoOfShares" className="col-form-label">No. of Shares</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="NoOfShares" label="" value={this.state.formData.NoOfShares === undefined ? ''   : this.state.formData.NoOfShares}  placeholder="Enter No. of Shares"  type="text" className="form-control" disabled/>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="MISScheme" className="col-form-label">MIS Scheme</label>
                                            </Col>
                                            <Col sm="6">

                                            <AvField name="F_MISScheme"  value={this.state.F_MISScheme === 0 ? this.state.formData.F_MISScheme   : this.state.F_MISScheme}  type="select" className="form-select" disabled>
                                      <option value={-1} defaultValue label={"Select"} />
                                      {this.state.MISScheme
                                        ? this.state.MISScheme.map(
                                            (option, key) => (
                                              <option key={option.Id} value={option.Id} label={option.Name} />
                                            )
                                          )
                                        : null}
                                        
                                    </AvField>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="MISAmount" className="col-form-label">MIS Amount</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="MISAmount" label="" value={this.state.formData.MISAmount == '' ? 1000   : this.state.formData.MISAmount} placeholder="Enter MIS Amount" errorMessage="Enter MIS Amount" validate={{ required: { value: true } }}  type="text" className="form-control" disabled />
                                            </Col>
                                          </Row></>) : null
                                          }
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="Remarks" className="col-form-label">Remarks</label>
                                            </Col>
                                            <Col sm="6">
                                            <AvField name="Remarks" label="" value={this.state.formData.Remarks === undefined ? ''   : this.state.formData.Remarks} placeholder="Enter Remarks"  type="text" className="form-control" disabled/>
                                          </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                            </TabPane>
                            <TabPane tabId={3}>
                              <div>
                                  <Row>
                                    <Col lg="6">
                                        <Label for="basicpill-lastname-input2">
                                          Identity Confirmation
                                        </Label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <AvField name="IDDocumentTypeId_1" label="Type of Reg. Cert." value={this.state.formData.IDDocumentTypeId_1 === null ? '-1'   : this.state.formData.IDDocumentTypeId_1}  type="select" className="form-select" disabled>
                                      <option value={-1} defaultValue label={"Select"} />
                                      {this.state.typeofRegCert
                                        ? this.state.typeofRegCert.map(
                                            (option, key) => (
                                              <option key={option.Id} value={option.Id} label={option.Name} />
                                            )
                                          )
                                        : null}
                                    </AvField>
                                    </Col>
                                    <Col lg="6">
                                    <AvField name="ID_DocNo_1" label="Registration No." value={this.state.formData.ID_DocNo_1 === undefined ? ''   : this.state.formData.ID_DocNo_1} placeholder="Enter Registration No." type="text" className="form-control" disabled />
                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ID_ImageURL_1_Thumbnail} />
                                    <AvField name="BrowseImage1" label="DocumentId 1" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'BrowseImage1', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" disabled />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <AvField name="IDDocumentTypeId_2" label="Type of Reg. Cert." value={this.state.formData.IDDocumentTypeId_2 === null ? '-1'   : this.state.formData.IDDocumentTypeId_2}    type="select" className="form-select" disabled>
                                      <option value={-1} defaultValue label={"Select"} />
                                      {this.state.typeofRegCert
                                        ? this.state.typeofRegCert.map(
                                            (option, key) => (
                                              <option key={option.Id} value={option.Id} label={option.Name} />
                                            )
                                          )
                                        : null}
                                    </AvField>
                                    </Col>
                                    <Col lg="6">
                                    <AvField name="ID_DocNo_2" label="Registration No." value={this.state.formData.ID_DocNo_2 === undefined ? ''   : this.state.formData.ID_DocNo_2} placeholder="Enter Registration No."  type="text" className="form-control" disabled/>
                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ID_ImageURL_2_Thumbnail} />
                                    <AvField name="BrowseImage2" label="DocumentId 2" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'BrowseImage2', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" disabled/>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ImageURL_Member_Thumbnail} />

                                    <AvField name="UserImage" label="Member Photo *" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'UserImage', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" disabled/>

                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ImageURL_Signature_Thumbnail} />


                                    <AvField name="Signature" label="Member Signature" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'Signature', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" disabled/>


                                    
                                    </Col>
                                  </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId={4}>
                              <div className="row justify-content-center">
                                <Col lg="6">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                      <h5>Confirm Detail</h5>
                                      <p className="text-muted">
                                        If several languages coalesce, the grammar
                                        of the resulting
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>
                          <div>
                        



                      
                       
                    

                        {this.state.success_msg ? (
                      <SweetAlert
                        title="Member Saved Successfully!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        You clicked the button!
                      </SweetAlert>
                    ) : null}
                          </div>
                        </AvForm>
                      </div>
                      <div className="actions clearfix" >
                        <ul style={{height:'50px', backgroundColor:'white'}}>

                        <li >
                       

<Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.printInvoice}
                        >
                          Print
                        </Button>
{/* <Link
                            to="#"
                            onClick={this.printInvoice}
                            className="btn btn-success me-1"
                          >
                            <i className="fa fa-print" />
                          </Link> */}
</li>
                      
                          <li style={{marginTop:'10px'}}
                            className={
                              this.state.activeTab === 1
                                ? "previous disabled"
                                : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab - 1);
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                         
                          <li
                          style={{marginTop:'10px'}}
                            className={
                              this.state.activeTab === 4
                                ? "next disabled"
                                : "next"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab + 1);
                              }}
                            >
                              Next
                            </Link>
                          </li>

                          <li
                            className={
                              this.state.activeTab === 1
                                ? "save disabled"
                                : "save"
                            }
                          >
                        
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_MembershipSynopsis);
