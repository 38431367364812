import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";
//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport
} from "../../store/functions";
import Breadcrumbs from "../../components/Common/Breadcrumb";






class pageList_MemberLedgerReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      isProgress:true,
      gender: [], 
      advisor : [],
      formData : {},
      gridDatanominalMFG : [{
        
      }],
      gridDataMFG  : [{
        
      }]

    };
    this.obj = this;
    this.breadCrumbTitle = "Member Ledger Report";
    this.breadCrumbItem = "Member Ledger Report";
    this.modalTitle = "Member Ledger Report";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    
    this.btnSave_onClick  =  this.btnSave_onClick.bind(this);
    this.renderGridBody =  this.renderGridBody.bind(this);
   

  }

  getaccountsdata (){
    alert(this.state.Id);
  }



  componentDidMount() {

    //Filling DropDowns
  
    Fn_FillListData(this.obj, "advisor", API_WEB_URLS.MASTER + "/0/token/Advisor/Id/0");
    Fn_FillListData(this.obj, "gender", API_WEB_URLS.MASTER + "/0/token/Gender/Id/0");
   
    
  }



  getmember =  (event) => {
    Fn_FillListData(this.obj, "member", API_WEB_URLS.SEARCHMEMBERSHIPNO + "/0/token/"+event.target.value+'?F_MembershipTypeMaster=0');
    } 

   
  renderGridHeader() {
    return (
      <>
      
        <th>  MemberAccountNo</th>
        <th>Name</th>
        <th>Account Type</th>
      
        <th>Account Type Scheme</th>
      </>
    );
  }


 





  renderGridBody(formData) {
    
    return (
      <>
        <td>{formData.MemberAccountNo}</td>
        <td>{formData.Name}</td>
        <td>{formData.AccountTypeName}</td>
        <td>{formData.AccountTypeSchemeName}</td>
      </>
    );
  }


  renderModalBody(selectedFormData) {

    

    return (
      <>
        <p className="mb-4">
          Name: <span className="text-primary">{selectedFormData.Id}</span>
        </p>
      </>
    );
  }


  

  btnSave_onClick (event, formData)  {
    Fn_FillListData(this.obj, "gridData", API_WEB_URLS.MASTER + "/0/token/MemberLedger/Id/"+formData.MemberId);
  }

  



  


  render() {
    return (
<>
<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="9">
                                 
                                    
                                    <Row>
                                    <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Select Time</label>
                                        </Col>
                                    <Col sm="4" className="mb-3">
                                    <AvField name="time" label="" value={this.state.formData.MemberId}   type="select" className="form-select" >
                                            <option value={0} defaultValue label={"Select"} />
                                            <option value={1} defaultValue label={"Last 30 Days"} />
                                            <option value={2} defaultValue label={"Last 90 Days"} />
                                            <option value={3} defaultValue label={"Last 180 Days"} />
                                            <option value={3} defaultValue label={"Current Year"} />
                                          </AvField>
                                          </Col>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Search Member</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="txtSearch" onChange={this.getmember} label="" value={this.state.formData.txtSearch} placeholder="Search"  type="text"  className="form-control" />
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Select Member</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="MemberId" label="" value={this.state.formData.MemberId}   type="select" className="form-select" >
                                            <option value={0} defaultValue label={"Select"} />
                                            {this.state.member
                                              ? this.state.member.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                          </Col>
                                      </Row>
                     </Col>
                         </Row>
                         <div>
                          <Button
                          type="submit"
                          color="primary"
                        
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>

                        
                          </div>

                         
                         </AvForm>

                         </div> 
                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                         </Container>
                         </div>
                         </React.Fragment>





                         <React.Fragment>
       
       
            
         



                         
                        
                       
                         </React.Fragment>



                         <RCDisplayPage
        //page header paramaters
       
       Isbreadcrumb = {true}
       breadCrumbTitle={this.breadCrumbTitle}
       breadcrumbItem={"Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPage>









    
   
     
      </>
    );
  }
}
export default compose(container)(pageList_MemberLedgerReport);
