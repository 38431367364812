import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody , Container, CardTitle,Input, Label, Nav, NavItem, NavLink, Table} from "reactstrap"
import WelcomeComp from "./WelcomeComp";

import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"
import Breadcrumbs from "components/Common/Breadcrumb";
import LineChart from "./linechart"
import { Link } from "react-router-dom"
import classnames from "classnames"
import MixBarChart from "./MixBarChart";
import PerformanceGraph from "./PerformanceGraph";



class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        {
          icon: "bx bx-copy-alt",
          title: "Transaction Account",
          value: "₵0",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-archive-in",
          title: "Reserved Account",
          value: "₵0",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "MIS ",
          value: "₵0",
          badgeValue: "0%",
          color: "warning",
          desc: "From previous period",
        },
        {
          icon: "bx bx-copy-alt",
          title: "Share Account",
          value: "₵0",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-archive-in",
          title: "Caveo Card ",
          value: "₵0",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Thrift ",
          value: "₵0",
          badgeValue: "0%",
          color: "warning",
          desc: "From previous period",
        },
        {
          icon: "bx bx-copy-alt",
          title: "Term Deposit",
          value: "₵0",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-archive-in",
          title: "Death Compensation",
          value: "₵0",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Co-Operative Insure ",
          value: "₵0",
          badgeValue: "0%",
          color: "warning",
          desc: "From previous period",
        },
      ],




      liquidity : [{
        Amount : 'Amount',
        Inflows  : 'GHC 500,000.00',
         Reserves : 'GHC 500,000.00',
         Outflows : 'GHC 378,566.84',
         Cash : 'GHC 878,566.84'
      },

      {
        Amount : 'Rate',
        Inflows  : '0%',
         Reserves : '0%',
         Outflows : '0%',
         Cash : '0%'
      },
    
    ],

    membertotal : [{
      Name : 'Members (Gold Members)',
      today  : '2',
       currentM : '',
       previousM : '',
       currentY : '',
       previousY : '',
       Total : ''
    },
    {
      Name : 'Members (Ordinary Members',
      today  : '2',
       currentM : '',
       previousM : '',
       currentY : '',
       previousY : '',
       Total : ''
    },
    {
      Name : 'Nominal Members',
      today  : '2',
      currentM : '',
      previousM : '',
      currentY : '',
      previousY : '',
      Total : ''
    },
    {
      Name : 'Gratis Members',
      today  : '2',
       currentM : '',
       previousM : '',
       currentY : '',
       previousY : '',
       Total : ''
    },
    {
      Name : 'Entity Members',
      today  : '2',
      currentM : '',
      previousM : '',
      currentY : '',
      previousY : '',
      Total : ''
    },
    {
      Name : 'Provisional Members',
      today  : '2',
      currentM : '',
      previousM : '',
      currentY : '',
      previousY : '',
      Total : ''
    },
    {
      Name : 'Dead Members',
      today  : '2',
       currentM : '',
       previousM : '',
       currentY : '',
       previousY : '',
       Total : ''
    },
    {
      Name : 'Suspended Members',
      today  : '2',
      currentM : '',
      previousM : '',
      currentY : '',
      previousY : '',
      Total : ''
    },
    {
      Name : 'Non-Members',
      today  : '2',
       currentM : '',
       previousM : '',
       currentY : '',
       previousY : '',
       Total : ''
    }
  
  ],

  cashflow : [{
    Name : 'Transaction    ',
    today  : '4,500.00    ',
     currentM : '',
     previousM : '',
     currentY : '',
     previousY : '',
     Total : ''
  },
  {
    Name : 'Reserved    ',
    today  : '2,000.00    ',
     currentM : '',
     previousM : '',
     currentY : '',
     previousY : '',
     Total : ''
  },
  {
    Name : 'MIS    ',
    today  : '6,000.00    ',
    currentM : '',
    previousM : '',
    currentY : '',
    previousY : '',
    Total : ''
  },
  {
    Name : 'Shares    ',
    today  : '5,000.00    ',
     currentM : '',
     previousM : '',
     currentY : '',
     previousY : '',
     Total : ''
  },
  {
    Name : 'Caveo    ',
    today  : '650.00    ',
    currentM : '',
    previousM : '',
    currentY : '',
    previousY : '',
    Total : ''
  },
  {
    Name : 'Thrift    ',
    today  : '400.00    ',
    currentM : '',
    previousM : '',
    currentY : '',
    previousY : '',
    Total : ''
  },
  {
    Name : 'Term Deposit    ',
    today  : '400.00    ',
     currentM : '',
     previousM : '',
     currentY : '',
     previousY : '',
     Total : ''
  },
  {
    Name : 'DCA    ',
    today  : '50.00    ',
    currentM : '',
    previousM : '',
    currentY : '',
    previousY : '',
    Total : ''
  },
  {
    Name : 'Co-Op Insure    ',
    today  : '78.00    ',
     currentM : '',
     previousM : '',
     currentY : '',
     previousY : '',
     Total : ''
  }

],

loanper : [{
  Name : 'Amount Disbursed  ',
  today  : '',
   currentM : '',
   previousM : '',
   currentY : '',
   previousY : '',
   Total : ''
},
{
  Name : 'Processing Fee Received  ',
  today  : '',
   currentM : '',
   previousM : '',
   currentY : '',
   previousY : '',
   Total : ''
},
{
  Name : 'Estimated Interest Income  ',
  today  : '',
  currentM : '',
  previousM : '',
  currentY : '',
  previousY : '',
  Total : ''
},
{
  Name : 'Loan Re-Payments  ',
  today  : '',
   currentM : '',
   previousM : '',
   currentY : '',
   previousY : '',
   Total : ''
},
{
  Name : 'Interest Income Received  ',
  today  : '',
  currentM : '',
  previousM : '',
  currentY : '',
  previousY : '',
  Total : ''
},
{
  Name : 'Loan Portfolio (Total)  ',
  today  : '',
  currentM : '',
  previousM : '',
  currentY : '',
  previousY : '',
  Total : ''
},
{
  Name : 'Loans in Default  ',
  today  : '',
   currentM : '',
   previousM : '',
   currentY : '',
   previousY : '',
   Total : ''
},
{
  Name : 'Number of Borrowers  ',
  today  : '',
  currentM : '',
  previousM : '',
  currentY : '',
  previousY : '',
  Total : ''
},
{
  Name : 'Number of Borrowers in Default  ',
  today  : '',
   currentM : '',
   previousM : '',
   currentY : '',
   previousY : '',
   Total : ''
},
{
  Name : 'Loan Default Rate  ',
    today  : '',
   currentM : '',
   previousM : '',
   currentY : '',
   previousY : '',
   Total : ''
}

],
      roleid : 0,
      activeTab: "1"
    }
    this.toggleTab = this.toggleTab.bind(this)
  }

  componentDidMount() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ Name: obj.username, email: obj.email, aid: obj.uid , Role:obj.role  , roleid:obj.roleid });

    
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  
  render() {
    localStorage.setItem("PageName", "DASHBOARD")
    return (
      <React.Fragment>
        <div className="page-content" style={{marginTop : 151}}>
          <MetaTags>
            <title>Dashboard | Sahakaar Admin & Dashboard</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboard" />

              <Tasks/>
            {this.state.roleid  ==  6 || this.state.roleid  ==  7 || this.state.roleid == 2 || this.state.roleid == 1 ? 
            <CardUser />
: null}
            <Row>
            <Col xl="12">
                <Row>
                 
                  <MiniWidget reports={this.state.reports} />
                  
                </Row>
              </Col>


            <Col md={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Liquidity</CardTitle>
                   

                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Cash Inflows</th>
                            <th>Cash Reserves</th>
                            <th>Cash Outflows</th>
                            <th>Total Cash</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.liquidity.map((item, index) => (
          <tr key={index}>
            <th scope="row">{item.Amount}</th>
            <td>{item.Inflows}</td>
            <td>{item.Reserves}</td>
            <td>{item.Outflows}</td>
            <td>{item.Cash}</td>
          </tr>
        ))}
                          
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>


             
            </Row>

            <Row>
              
              {/* <Col lg={8}>
              <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">Performance Graph</CardTitle>
                                            <MixBarChart />
                                        </CardBody>
                                    </Card>
             
              
            </Col> */}




                         <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Number of Members</CardTitle>
                   

                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Today</th>
                            <th>Current Month</th>
                            <th>Previous Month</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Total Membership</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.membertotal.map((item, index) => (
          <tr key={index}>
            <th scope="row">{item.Name}</th>
            <td>{item.today}</td>
            <td>{item.currentM}</td>
            <td>{item.previousM}</td>
            <td>{item.currentY}</td>
            <td>{item.previousY}</td>
            <td>{item.Total}</td>
          </tr>
        ))}
                          
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>



              
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Total Volume of Transaction by Membership Category						 </CardTitle>
                   

                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Today</th>
                            <th>Current Month</th>
                            <th>Previous Month</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Total Membership</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.membertotal.map((item, index) => (
          <tr key={index}>
            <th scope="row">{item.Name}</th>
            <td>{item.today}</td>
            <td>{item.currentM}</td>
            <td>{item.previousM}</td>
            <td>{item.currentY}</td>
            <td>{item.previousY}</td>
            <td>{item.Total}</td>
          </tr>
        ))}
                          
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>


            </Row>

            <Row>
            {/* <Col lg={12}>
              <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">Membership Growth Graph</CardTitle>
                                            <PerformanceGraph/>
                                           
                                        </CardBody>
                                    </Card>
             
              
            </Col> */}


<Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Cash Flow Performance</CardTitle>
                   

                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Today</th>
                            <th>Current Month</th>
                            <th>Previous Month</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Turnover To-Date</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.cashflow.map((item, index) => (
          <tr key={index}>
            <th scope="row">{item.Name}</th>
            <td>{item.today}</td>
            <td>{item.currentM}</td>
            <td>{item.previousM}</td>
            <td>{item.currentY}</td>
            <td>{item.previousY}</td>
            <td>{item.Total}</td>
          </tr>
        ))}
                          
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>


              
<Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Loan Performance</CardTitle>
                   

                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Today</th>
                            <th>Current Month</th>
                            <th>Previous Month</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Turnover To-Date</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanper.map((item, index) => (
          <tr key={index}>
            <th scope="row">{item.Name}</th>
            <td>{item.today}</td>
            <td>{item.currentM}</td>
            <td>{item.previousM}</td>
            <td>{item.currentY}</td>
            <td>{item.previousY}</td>
            <td>{item.Total}</td>
          </tr>
        ))}
                          
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
             
              
              
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
