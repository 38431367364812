export const API_WEB_URLS = {
 //BASE : "https://localhost:44362/api/V1/",
  BASE: "https://api.societiesbank.com/api/V1/",
  REPORTURL : "https://boldreports.shinewellinnovation.com/",
 //BASE: "http://apighana.shinewellsofttech.co.in/api/V1/",
  LOGIN: "Authentication/Login",
  //
  MASTER: "Masters",
  MASTER_COUNTRY: "CountryMaster",
  MASTER_SUBJECT: "SubjectMaster",

 SEARCHMEMBERSHIPNO : "SearchMembership",
 MemberMasterEntity : "MemberMasterEntity",
  //

  MEMBER:"MemberMaster",
  PersonToPersonRequest : "PersonToPersonRequest",
  AccountToAccountRequest : "AccountToAccountRequest",
  TransactionWithdraw : "TransactionWithdraw",
  TransactionDeposit : "TransactionDeposit",
  ChangePassword : "ChangePassword",
  AdvisorMaster : "AgentMaster",
  WithdrawShare : "WithdrawShare",
  AddShare : "AddShare",
  GetTransactionManagementSummary : "GetTransactionManagementSummary",
  GetMISManagementSummary : "GetMISManagementSummary",
  GetReservedManagementSummary : "GetMISManagementSummary",
  MIS : "MIS",
  BALANCESHEET : "GetBalanceSheet",
  ACCOUNTTYPESCHEME : "AccountTypeScheme",
  SCHEMEINTEREST : "SchemeInterest"

  // PRODUCT_CATEGORY: "Masters/ProductCategory",
  // PRODUCT_BRAND: "Masters/ProductBrand",
  // PRODUCT_ATTRIBUTE: "Masters/ProductAttribute"
};
