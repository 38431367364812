import React, { Component } from "react"
import { Container, Row, Col, Input, Button, Card, CardBody, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import {Progress} from 'reactstrap';
import { Fn_ChangeStateValue } from "store/functions";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class RCDisplayPage extends Component {

    
    render() {
    // const [modal, confirm_alert] = useState('foo')
      return (
        <React.Fragment>
            {/* <div className="page-content"> */}
                <Container fluid>
                {this.props.Isbreadcrumb ? ( <Breadcrumbs title={this.props.breadCrumbTitle} breadcrumbItem={this.props.breadcrumbItem} />): null}
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {/* Search Container */}
                                    {this.props.isSearchBox || this.props.isAdd ? (
                                    <Row className="mb-2">
                                        {this.props.isSearchBox ? (
                                        <Col sm="4">
                                            <div className="search-box mr-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <Input type="text" className="form-control" placeholder="Search..." onChange={this.props.searchmember} />
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
                                        ):null}
                                        {this.props.isAdd ? (
                                        <Col sm="8">
                                            <div className="text-sm-right">
                                                <Button type="button" onClick={this.props.btnAdd_onClick} color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Add New</Button>
                                            </div>
                                        </Col>
                                        ):null}

                                    {   this.props.isBulkPay ? (
                                        <Col sm="8">
                                            <div className="text-sm-right">
                                                <Button type="button"  color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Bulk Pay</Button>
                                            </div>
                                        </Col>
                                        ):null}
                                    </Row>
                                    ):null}
                                    {/* Table Data */}
                                    <div className="table-responsive">
{this.props.isProgress ? (<Progress
                        value={100}
                        color="primary"
                        style={{ width: '100%' }}
                        animated
                      ></Progress>) : null}
                                    
                                        <Table className="table table-centered table-nowrap">
                                            <thead className="thead-light">
                                                <tr>
                                                    {this.props.isCheckBox ? (
                                                    <th style={{ width: "20px" }}>
                                                        <div className="custom-control custom-checkbox">
                                                            <Input type="checkbox" className="custom-control-input" id="chkAll" />
                                                            <Label className="custom-control-label" htmlFor="chkAll">&nbsp;</Label>
                                                        </div>
                                                    </th>
                                                    ):null}
                                                    {this.props.isSNo ? (
                                                    <th>S. No.</th>
                                                    ):null}
                                                    {this.props.gridHeader()}
                                                    {this.props.isViewDetails ? (
                                                    <th>View Details</th>
                                                    ):null}
                                                    {this.props.isViewModify ? (
                                                    <th>View log</th>
                                                    ):null}
                                                    {(this.props.isEdit ||  this.props.isDelete || this.props.isApprove ) ? (
                                                    <th>Action</th>
                                                    ):null}

                                                    {(this.props.isfull ) ? (
                                                    <th>Action</th>
                                                    ):null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                           
                                                {this.props.gridData ? (
                                                    this.props.gridData.map((formData, key) =>
                                                        <tr key={"rawData_" + key}>
                                                            {this.props.isCheckBox ? (
                                                            <td>
                                                                <div className="custom-control custom-checkbox">
                                                                    <Input type="checkbox" className="custom-control-input" id={formData.id} />
                                                                    <Label className="custom-control-label" htmlFor={formData.id}>&nbsp;</Label>
                                                                </div>
                                                            </td>
                                                            ):null}
                                                            {this.props.isSNo ? (
                                                            <td>{key+1}</td>
                                                            ):null}
                                                            {this.props.gridBody(formData)}
                                                            {this.props.isViewDetails ? (
                                                            <td>
                                                                <Button type="button" color="primary" className="btn-sm btn-rounded" onClick={() => this.props.printInvoice(formData)}>
                                                                    View Details
                                                                </Button>

                                                            </td>
                                                            ):null}

                                                            {this.props.isProcess ? (
                                                            <td>
                                                                <Button type="button" color="primary" className="btn-sm btn-rounded" onClick={() => this.props.printInvoice(formData)}>
                                                                    Process
                                                                </Button>
                                                            </td>
                                                            ):null}

                                                            {this.props.isViewModify ? (
                                                            <td>
                                                                <Button type="button" color="primary" className="btn-sm btn-rounded" onClick={() => this.props.btnViewlog_onClick(formData)}>
                                                                View Log
                                                                </Button>
                                                            </td>
                                                            ):null}

                                                            
                                                            {/* {(this.props.isEdit || this.props.isDelete) ? ( */}
                                                            <td>
                                                                <div className="d-flex gap-3">
                                                                {this.props.isEdit ? (
                                                                    <Link to="#" value={formData} className="mr-3 text-primary" onClick={() => this.props.btnEdit_onClick(formData)}>
                                                                        <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                                                            Edit
                                                                        </UncontrolledTooltip >
                                                                    </Link>
                                                            
                                                                ):null}
                                                                
                                                                {this.props.isApprove ? (
                                                                    <Link to="#" value={formData} className="text-sucess" onClick={() => this.props.toggleapprove(this.props.obj,formData,true)} >
                                                                        <i className="mdi mdi-check font-size-18 mr-3" id="approvetooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="approvetooltip">
                                                                            Approve
                                                                        </UncontrolledTooltip >
                                                                    </Link>
                                                                ):null}
                                                                {this.props.isTwoFactorDelete ?(
                                                                    <Link to="#" value={formData} className="text-danger" onClick={() => this.props.confirmdelete(formData)} >
                                                                        <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                            Delete
                                                                        </UncontrolledTooltip >
                                                                    </Link>
                                                                ):null }
                                                                {this.props.isDelete ? (
                                                                    <Link to="#" value={formData} className="text-danger" onClick={() => this.props.toggleDeleteConfirm(this.props.obj,formData,true)} >
                                                                        <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                            Delete
                                                                        </UncontrolledTooltip >
                                                                    </Link>
                                                                ):null}
                                                        {this.props.isfull ? (
                                                           
                                                                <Button type="button" color="primary" className="btn-sm btn-rounded" onClick={() => this.props.btn_fullMember(formData)}>
                                                                 Change Mem. Type
                                                                </Button>
                                                           
                                                            ):null}

                                                                
                                                                </div>
                                                            {/* </td>  */}
                                                                {this.props.confirm_alert ? (
                                                                <SweetAlert
                                                                    title="Are you sure?"
                                                                    warning
                                                                    showCancel
                                                                    confirmButtonText="Yes, delete it!"
                                                                    confirmBtnBsStyle="success"
                                                                    cancelBtnBsStyle="danger"
                                                                    onConfirm={() =>this.props.btnDelete_onClick(this.props.selectedFormData)}
                                                                    onCancel={() =>this.props.toggleDeleteConfirm(this.props.obj,formData,false)}
                                                                >
                                                                    You won`t be able to revert this!
                                                                </SweetAlert>
                                                                ) : null}


                                                                {this.props.confirm_alert_delete ? (
                                                                <SweetAlert
                                                                    title="Are you sure?"
                                                                    warning
                                                                    showCancel
                                                                    confirmBtnText="Proceed Delete!"
                                                                    confirmBtnBsStyle="success"
                                                                    cancelBtnBsStyle="danger"
                                                                    onCancel={() =>this.props.confirmdelete(formData)}
                                                                    onConfirm={() =>this.props.checkpassword(formData)}
                                                                >
                                                                   
                                                                    <h4><b>Enter Password</b></h4>
                                                                    <input type="password"  onChange={(e)=> this.props.obj.setState({Password : e.target.value})}/>


                                                                </SweetAlert>
                                                                ) : null}

                                                            {this.props.confirmapprove_alert ? (
                                                                <SweetAlert
                                                                    title="Are you sure?"
                                                                    warning
                                                                    showCancel
                                                                    confirmBtnText="Yes, Approve!"
                                                                    confirmBtnBsStyle="success"
                                                                    cancelBtnBsStyle="danger"
                                                                    onConfirm={() =>this.props.btnApprove(this.props.selectedFormData)}
                                                                    onCancel={() =>this.props.toggleapprove(this.props.obj,formData,false)}
                                                                >
                                                                    You won`t be able to revert this!
                                                                </SweetAlert>
                                                                ) : null}

                                                                
                                                                {this.props.success_dlg ? (
                                                                  <SweetAlert
                                                                    success
                                                                    title={this.props.dynamic_title}
                                                                    onConfirm={() => this.props.toggleDeleteSuccess(this.props.obj,false)}
                                                                  >
                                                                    {this.props.dynamic_description}
                                                                  </SweetAlert>
                                                                ) : null}
                                                            {/* ):null} */}
                                                            </td>
                                                        </tr>
                                                    )
                                                ) : null
                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                    {/* Pagination */}
                                    {this.props.isPagination ? (
                                    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationItem disabled>
                                            <PaginationLink previous href="#" />
                                        </PaginationItem>
                                        <PaginationItem active>
                                            <PaginationLink href="#">
                                                1
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                    ):null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            {/* </div> */}

            <Modal isOpen={this.props.isOpenModal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex="-1" toggle={() => this.props.togglemodal(this.props.obj,null)}>
                <div className="modal-content">
                    <ModalHeader toggle={() => this.props.togglemodal(this.props.obj,null)}>
                        {this.props.modalTitle}
                    </ModalHeader >
                    <ModalBody>
                        {this.props.modalBody(this.props.selectedFormData)}
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={() => this.props.togglemodal(this.props.obj,null)}>Close</Button>
                    </ModalFooter>
                </div>
            </Modal>
        </React.Fragment>
      );
    }
}

RCDisplayPage.propTypes = {
    obj: PropTypes.object,
    selectedFormData: PropTypes.object,
    //
    breadCrumbTitle: PropTypes.string,
    breadcrumbItem: PropTypes.string,
    dynamic_title: PropTypes.string,
    dynamic_description: PropTypes.string,
    gridData: PropTypes.array,
    gridHeader: PropTypes.func,
    gridBody: PropTypes.func,
    //
    Isbreadcrumb : PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isSearchBox: PropTypes.bool,
    isAdd: PropTypes.bool,
    isEdit: PropTypes.bool,
    isTwoFactorDelete : PropTypes.bool,
    confirm_alert_delete : PropTypes.bool,
    isDelete: PropTypes.bool,
    isCheckBox: PropTypes.bool,
    isSNo: PropTypes.bool,
    isViewDetails: PropTypes.bool,
    isProcess : PropTypes.bool,
    isProgress : PropTypes.bool,
    isPagination: PropTypes.bool,
    confirm_alert: PropTypes.bool,
    success_dlg: PropTypes.bool,
    isViewModify : PropTypes.bool,
    confirmapprove_alert : PropTypes.bool,
    isApprove : PropTypes.bool,
    isBulkPay : PropTypes.bool,
    isfull : PropTypes.bool,
    //
    modalTitle: PropTypes.string,
    modalBody: PropTypes.func,
    togglemodal: PropTypes.func,
    toggleDeleteConfirm: PropTypes.func,
    toggleDeleteSuccess: PropTypes.func,
    toggleapprove : PropTypes.func,
    checkpassword : PropTypes.func,
    btn_ShowPass : PropTypes.func,

    //
    btnAdd_onClick: PropTypes.func,
    btnEdit_onClick: PropTypes.func,
    btnDelete_onClick: PropTypes.func,
    btnViewlog_onClick : PropTypes.func,
    printInvoice : PropTypes.func,
    searchmember : PropTypes.func,
    btnApprove : PropTypes.func,
    confirmdelete : PropTypes.func,
    btn_fullMember : PropTypes.func
}
  
export default RCDisplayPage