import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row,Button , FormGroup} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// action
import { userForgetPassword } from "../../store/actions";

// import images

import { API_WEB_URLS } from "constants/constAPI";
import { API_HELPER } from "helpers/ApiHelper";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AuthCode from "react-auth-code-input"

import speakeasy from 'speakeasy';
import logodark from "../../assets/images/logo-dark.png"
import logo from "../../assets/images/logo-light.png"

const aAndOrB = (value, ctx) => {
  if (ctx.newpassword != ctx.renewpassword) {
   
    return "Password doesn't match.";
  }
  return true;
}

class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading : false,
      isValidUserName : false,
      errorMessage: "",
      type : "password",
      inputValue : '',
      isOTPChecked : false,
      fo : "",
      fo2 : "",
      formData: {},
      
     
    };
    this.obj   =  this;
  
    this.proceed  = this.proceed.bind(this);
    this.btnSave_onClick  = this.btnSave_onClick.bind(this);
  }

 


  btnSave_onClick(event, formData) {

    

    const { id } = this.props.match.params;
      this.setState({loading : true});
    
        let vformData = new FormData();
        vformData.append("Id", id);
        vformData.append("Password", formData.renewpassword);
       
       
    let ChangePass = function() {
      return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"ChangePasswordDirect/0/token" , vformData).then(token => { return token } )
    }

    let checkotptoken =  ChangePass();
   

    checkotptoken.then(result => {
      this.setState({loading : false});
 
    if (result.data.response[0].Id > 0) {
      alert('Password Changed Succesfully!');
      this.props.history.push("/login");
    }
    else {
       alert ( "OTP Incorrect")
    }
  })
  this.setState({loading : false});

      
      
  }

  pass = (event)=> {

    let pass  =  event.target.value;
     
      this.setState({ fo: pass });
    
  }





  passmatch = (event)=> {

    let pass  =  event.target.value;


    if (this.state.fo != pass){
      this.form.validateInput('renewpassword');
    }

    else{

    }
     
      
    
  }


  
  proceed () {
    this.setState({loading : true});
    const { inputValue } = this.state;
    const obj = JSON.parse(sessionStorage.getItem("authUser1"));

   const MainId  =  this.state.MainId;

    let vformData = new FormData();
    const { id } = this.props.match.params;
    vformData.append("OTP", inputValue);
    vformData.append("Id", id);
    let CheckOtp = function() {
      return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"CheckOTP/0/token" , vformData).then(token => { return token } )
    }

    let checkotptoken =  CheckOtp();
   
    checkotptoken.then(result => {
      this.setState({loading : false});
 
    if (result.data.response[0].Id > 0) {
      this.setState({isOTPChecked : true});
    }
    else {
       alert ( "OTP Incorrect")
    }
  })


   
    

  

    
    // this.setState({ isCodeValid: isVerified });

  
  }


  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
       
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>

                      {this.state.isOTPChecked == false ?
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Please enter OTP sent to your email.</p>
                        </div>
                      </Col> : <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p></p>
                        </div>
                      </Col> }
                      
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.forgetError}
                        </Alert>
                      ) : null}


                      {this.props.forgetSuccessMsg && this.state.isOTPChecked == false ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.forgetSuccessMsg}
                        </Alert>
                      ) : null}
                   
                      
                      {this.state.isOTPChecked == false ?
                      
                     
                      <Row>
                        <Col xs={12}>
                          <FormGroup className="verification-2 mb-3">
                            <Label
                              htmlFor="digit1-input"
                              className="visually-hidden"
                            >
                              Dight 1
                            </Label>
                            <AuthCode
                              characters={6}
                              onChange={(e) => this.setState({inputValue : e})}
                              className="form-control form-control-lg text-center"
                              allowedCharacters="^[0-9]"
                              inputStyle={{
                                width: "69px",
                                height: "calc(1.5em + 1rem + 2px)",
                                padding: ".5rem 1rem",
                                borderRadius: "8px",
                                fontSize: "1.01562rem",
                                textAlign: "center",
                                marginTop : "20px",
                                marginRight: "15px",
                                border: "1px solid #ced4da",
                                textTransform: "uppercase",
                                borderRadius: ".4rem"
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <div >
                              <Button
                              onClick={this.proceed}
                              type="button"
                                //to="dashboard"
                                className="btn btn-success w-md"
                              >
                                Confirm
                              </Button>
                            </div>
                      </Row>
                    
                    
                  : 
                  <>
                     <AvForm ref={c => (this.form = c)} className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                  <Row>

                                    <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">New Password</label>
                                        </Col>
                                <Col lg="8">


                                <AvField name="newpassword" onChange={this.pass}   value={this.state.formData.newpassword} placeholder="New Password" errorMessage="Enter New Password"  type="password"  className="form-control" />
                                  </Col>
                                     </Row>
                                 

                                        <Row>

                                    <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Re-enter New Password</label>
                                        </Col>
                                <Col lg="8">


                                <AvField name="renewpassword" onChange={this.passmatch} validate={{myValidation: aAndOrB}}    value={this.state.formData.renewpassword} placeholder="re- enter New Password" errorMessage="Password doesn't match."  type="password"  className="form-control" />
                                  </Col>
                                     </Row>
                                     <Row>
                                     <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Submit
                            </Button>{" "}
                            </div>
                            </Row>
                  </AvForm>
                                     </>
                  
                  
                  }
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link to="login" className="fw-medium text-primary">
                      Login
                    </Link>{" "}
                  </p>
                  {/* <p>
                    © {new Date().getFullYear()} Skote. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger" /> by Themesbrand
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

OTP.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
};

const mapStateToProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(OTP)
);