import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store


import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"

import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import './datatables.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { API_HELPER } from "helpers/ApiHelper";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
class pageList_JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData : {},
      success_msg: false,
      dynamic_title: "",
      dynamic_description: "",
      company : [],
      page: 1,
      sizePerPage: 10,
      productData: [],
      RReason : '',
      F_RetailerComission : 0,
      F_DistributorComission : 0,
      F_SDistributorComission : 0,
      F_MDistributorComission : 0,
      Charges : 0,
      commissionmodal : false,
      success_dlg : false,
      confirm_undorefund : false,
      title : '',
      UTR : ''

    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Job List";
    this.modalTitle = "Audit Attendance";
    this.rtPage_Add = "add-customermaster";
    this.rtPage_Edit = "edit-customermaster";
    this.API_URL = "GetJobList/0/token";
    

    //Event Binding
    
    this.btnSave_onClick =  this.btnSave_onClick.bind(this);
  
    this.syno  =  this.syno.bind(this);
    this.view = this.view.bind(this);
  
  }

  //  exportToExcel = () => {

  //   let vformData = new FormData();
    
  //   vformData.append("FromDate", this.state.FromDate);
  //   vformData.append("ToDate",  this.state.ToDate);
    
  //   Fn_ExportExcel(this.obj, { arguList: { id: 0, formData: vformData } }, "DMRExcel/0/token", "DMRTransaction", true);
    
  // };





  componentDidMount() {
    Fn_FillListData(this.obj, "organisation", API_WEB_URLS.MASTER + "/0/token/Organisation/Id/0");
    Fn_FillListData(this.obj, "designation", API_WEB_URLS.MASTER + "/0/token/Designation/Id/0");
    
  }


  syno(){
    this.setState({success_msg : false, success_dlg : false })
  }
 


  btnSave_onClick (event, formData)  {



    
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    this.setState({ productData : [] })

    let vformData = new FormData();
    vformData.append("Id", 0);
    vformData.append("F_DesignationMaster", formData.F_DesignationMaster  == '' || formData.F_DesignationMaster  == undefined  ? 0 : formData.F_DesignationMaster);
    vformData.append("F_OrganisationMaster", formData.F_OrganisationMaster == '' || formData.F_OrganisationMaster == undefined ? 0 : formData.F_OrganisationMaster);
   
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL, "productData", true);


  }


  view (Id) {

    const foundItem = this.state.productData.find(item => item.Id === Id);

    this.setState ({
        
      FullName : foundItem.FullName,
      MembershipNo : foundItem.MembershipNo,
      Address_Residential : foundItem.Address_Residential,
      Gender  : foundItem.Gender,
      Age : foundItem.Age,
      Phone : foundItem.Phone,
      Email   : foundItem.Email,
      Nationality : foundItem.Nationality,
      Designation : foundItem.Designation,
      DutyMode : foundItem.DutyMode,
      Organisaton : foundItem.Organisaton,
      FullIMG : foundItem.FullIMG,
      PassportIMG : foundItem.PassportIMG,
      CV : foundItem.CV,
      commissionmodal : true
      
    });

  }



  







  
  render() {

    const obj = JSON.parse(sessionStorage.getItem("authUser"));


    const columns = [{
      dataField: 'MembershipNo',
      text: 'GIN ',
      sort: true,
    }, {
      dataField: 'FullName',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'Designation',
      text: 'Job Title ',
      sort: true,
     
    },
     {
      dataField: 'DutyMode',
      text: 'Duty Mode',
      sort: true,
    },
    {
      dataField: "action",
      text: "View",
      formatter: (cellContent, row) => {
        return (
          <button
          type="button"
          //disabled={row.IsRefund? true : false}
            className="btn btn-success btn-xs"
            onClick={() => this.view(row.Id)}
          >
            View
            {/* <i className="mdi mdi-printer font-size-18 mr-3" id="edittooltip"></i> */}
          </button>
        );
      },
    } 
    
  
  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: this.state.productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (this.state.productData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

    return (




     <>

<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="14">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="12">
                                 
                                      <>
                                    <Row>
                                    <Col sm="2" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label"> Select Organisation</label>
                                        </Col>
                                    <Col sm="3">
                                      
                                        <AvField  name="F_OrganisationMaster" label="" value={this.state.formData.F_OrganisationMaster === null ? '-1'   : this.state.formData.F_OrganisationMaster}   type="select" className="form-select" >
                                            <option value={0} defaultValue label={"All"} />
                                            
                                            {this.state.organisation
                                              ? this.state.organisation.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}      
                                                 
                                          </AvField> 
                                        </Col>


                                        <Col sm="1" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label"> Select Job</label>
                                        </Col>
                                    <Col sm="3">
                                        <AvField  name="F_DesignationMaster" label="" value={this.state.formData.F_DesignationMaster === null ? '-1'   : this.state.formData.F_DesignationMaster}   type="select" className="form-select" >
                                            <option value={0} defaultValue label={"All"} />
                                            {this.state.designation
                                              ? this.state.designation.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField> 
                                        </Col>

                                        
                                        <Col sm="3" className="mb-3">
                                        <Button
                          type="submit"
                          color="primary"
                        
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>
                      
                                          </Col>

                                       

</Row>

                                    </> 
                                   
                              

                                     
                                   

                       
                    
                         </Col>
                         </Row>
                        

                         
                         </AvForm>

                         </div> 



                       



                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>

                         <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
              </Row>





                         
                         </Container>

                         

                      <Modal
                        isOpen={this.state.commissionmodal}
                        toggle={this.commissionmodal}
                        scrollable={true}
                        backdrop={'static'}
                        id="staticBackdrop2"
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" >Job Details</h5>
                          <button type="button" className="btn-close" onClick={() =>
                            this.setState({ commissionmodal: false })
                          } aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">GIN : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.MembershipNo}</label>
                                        </Col>
                                      </Row>



                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Name : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.FullName}</label>
                                        </Col>
                                      </Row>



                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Address : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.Address_Residential}</label>
                                        </Col>
                                      </Row>



                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Gender : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.Gender}</label>
                                        </Col>
                                      </Row>



                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Age : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.Age}</label>
                                        </Col>
                                      </Row>



                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Phone : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.Phone}</label>
                                        </Col>
                                      </Row>

                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Email : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.Email}</label>
                                        </Col>
                                      </Row>

                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Nationality : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.Nationality}</label>
                                        </Col>
                                      </Row>

                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Job Title : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.Designation}</label>
                                        </Col>
                                      </Row>

                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Duty Mode : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.DutyMode}</label>
                                        </Col>
                                      </Row>

                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Organisation : </label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">{this.state.Organisaton}</label>
                                        </Col>
                                      </Row>


                                      
                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Passport Image</label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                        <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.PassportIMG} />
                                        </Col>
                                      </Row>


                                      <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Full Image</label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                        <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.FullIMG} />
                                        </Col>
                                      </Row>



                                      <Row>
                                          <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">CV</label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                        <a href={this.state.CV} target="_blank" rel="noreferrer">View</a>
                                        </Col>
                                      </Row>

                        </div>
                        
                      </Modal>


                         {this.state.success_msg == true ? (
                      <SweetAlert
                        title={this.state.title}
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                      
                      </SweetAlert>
                    ) : null}

                         {this.state.confirm_alert ? (
                                                                <SweetAlert
                                                                    title="Are you sure to Refund?"
                                                                    warning
                                                                    showCancel
                                                                    confirmBtnText="Yes, Refund it!"
                                                                    confirmBtnBsStyle="success"
                                                                    cancelBtnBsStyle="danger"
                                                                     onConfirm={() =>this.refund()}
                                                                     onCancel={() =>this.setState({confirm_alert : false, RReason : false , Id : 0})}
                                                                >
                                                                 
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label"> Refund Reason</label>
                                        </Col>
                                        <br></br>
                                        <Col sm="9" className="mb-3">
                                        <input name="RReason" label="" value={this.state.RReason} placeholder="Refund Reason" onChange={(event)=>Fn_ChangeStateValue(this.obj, "RReason", event.target.value)}  validate={{ required: { value: true } }} type="text"  className="form-control" />
                                        </Col>
                                    
                                                                    
                                                                </SweetAlert>
                                                                ) : null}



{this.state.confirm_undorefund ? (
                                                                <SweetAlert
                                                                    title="Are you sure to Undo Refund?"
                                                                    warning
                                                                    showCancel
                                                                    confirmBtnText="Yes, Undo it!"
                                                                    confirmBtnBsStyle="success"
                                                                    cancelBtnBsStyle="danger"
                                                                     onConfirm={() =>this.undorefund()}
                                                                     onCancel={() =>this.setState({confirm_undorefund : false, Id : 0})}
                                                                >
                                                                  The Credited Amount will be deduct!                                                                    
                                                                </SweetAlert>
                                                                ) : null}


{this.state.success_dlg == true ? (
                      <SweetAlert
                        title={'Updated Successful!'}
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                      
                      </SweetAlert>
                    ) : null}
                         </div>
                         </React.Fragment>



  </>
    );
  }
}
export default compose(container)(pageList_JobList);
