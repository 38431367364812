import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Transaction",
    Balance: 1000000
  },
  {
    name: "Reserved",
    Balance: 4000
  },
  {
    name: "MIS",
    Balance: 5000
  },
  {
    name: "Share",
    Balance: 25000
  },
  {
    name: "CaveoCard",
    Balance: 6600
  },
  {
    name: "Thrift",
    Balance: 7850
  },
  {
    name: "TermDeposit",
    Balance: 90000
  },
  {
    name: "DeathCompensation",
    Balance: 52580
  },
  {
    name: "Co-OperativeInsure",
    Balance: 22100
  }
];

export default class MixBarChart extends Component {
  render() {
    return (
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Transaction" stackId="Transaction" fill="#82ca9d" />
            <Bar dataKey="Reserved" stackId="Reserved" fill="#8884d8" />
          
            <Bar dataKey="MIS" stackId="MIS" fill="#b3411c" />
            <Bar dataKey="Share" stackId="Share" fill="#b3411c" />
            <Bar dataKey="CaveoCard" stackId="CaveoCard" fill="#2547a0" />
            <Bar dataKey="Thrift" stackId="Thrift" fill="#2547a0" />
            <Bar dataKey="TermDeposit" stackId="TermDeposit" fill="#711cb3" />
            <Bar dataKey="DeathCompensation" stackId="DeathCompensation" fill="#711cb3" />


            <Bar dataKey="Co-OperativeInsure" stackId="Co-OperativeInsure" fill="#711cb3" />

          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
