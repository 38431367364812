import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";


//i18n
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    


    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-hide");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (obj.roleid == 4 || obj.roleid  == 3) {
      return (
        <React.Fragment>
          <SimpleBar autoHide={false}   className="h-100"  ref={this.refDiv}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{this.props.t("Menu")}</li>
                <li>
                  <Link to="/#">
                    {/* <i className="bx bx-home-circle" /> */}
                    <span className="badge rounded-pill bg-info float-end">
                      04
                    </span>
                    <span>{this.props.t("Dashboards")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/dashboard">{this.props.t("Default")}</Link>
                    </li>
                    {/* <li>
                      <Link to="#">{this.props.t("Saas")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Crypto")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Blog")}</Link>
                    </li> */}
                  </ul>
                </li>
  
                <li className="menu-title">{this.props.t("Apps")}</li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-store" /> */}
                    <span>{this.props.t("Masters")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                  <li>
                      <Link to="#">
                        {this.props.t("Member Registration Master")}
                      </Link>
                    </li>

                    <li>
                      <Link to="#">
                        {this.props.t("Account Numbering Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Accounts Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Fees, Interests and Penalties Master")}
                      </Link>
                    </li>

                    <li>
                      <Link to="#">
                        {this.props.t("Shares Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Staff Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Wages Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Access Rights Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Loan Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="agentdetails">
                        {this.props.t("Agents Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Public Viewer Master")}
                      </Link>
                    </li>

                    <li>
                      <Link to="#">
                        {this.props.t("Log Master")}
                      </Link>
                    </li>

                    <li>
                      <Link to="#">
                        {this.props.t("Dividend Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Management Accounts Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Forms and Agreements")}
                      </Link>
                    </li>

                  {/* <li>
                      <Link to="add-members-account-master">
                        {this.props.t("Membership Account Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-blood-group">
                        {this.props.t("Blood Group")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-blood-group">{this.props.t("Bank")}</Link>
                    </li>


                    <li>
                      <Link to="masters-workcategory">{this.props.t("Work Category")}</Link>
                    </li>

                    <li>
                      <Link to="masters-salaryclass">{this.props.t("Salary Class")}</Link>
                    </li>
                    <li>
                      <Link to="masters-salarylevel">{this.props.t("Salary Level")}</Link>
                    </li> */}
                    {/* <li>
                      <Link to="masters-blood-group">
                        {this.props.t("Transaction Account")}
                      </Link>                        
                      <ul className="sub-menu" aria-expanded="false">
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("COT")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Monthly Subscription Fee")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Interest on Debit Balance")}
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li>
                      <Link to="masters-country">
                        {this.props.t("Country Codes")}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="masters-blood-group">
                        {this.props.t("Account Numbering")}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="masters-relationship">
                        {this.props.t("Relationship")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-relationship">
                        {this.props.t("Staff Deductions")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-relationship">
                        {this.props.t("Target & Commission")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-relationship">
                        {this.props.t("Share")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-relationship">
                        {this.props.t("Dividend")}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="masters-blood-group">{this.props.t("Loan")}</Link>
                      <ul className="sub-menu" aria-expanded="false">
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Cash Loan")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Hire-Purchase")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Finance Lease")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Mortage")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Reverse Mortage")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="masters-blood-group">
                        {this.props.t("Scheme")}
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("FD")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("RD")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Daily Deposits")}
                          </Link>
                        </li>
                        <li>
                          <Link to="masters-blood-group">
                            {this.props.t("Thrift")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="masters-designation">
                        {this.props.t("Designation")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-education">
                        {this.props.t("Education")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-entity-type">
                        {this.props.t("Entity Type")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-gender">{this.props.t("Gender")}</Link>
                    </li>
                    <li>
                      <Link to="masters-id-document-type">
                        {this.props.t("ID Document Type")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-marital-status">
                        {this.props.t("Marital Status")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-membership-type">
                        {this.props.t("Membership Type")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-profession">
                        {this.props.t("Profession")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-registration-document-type">
                        {this.props.t("Registration Document Type")}
                      </Link>
                    </li>
                    <li>
                      <Link to="masters-blood-group">
                        {this.props.t("User Information")}
                      </Link>
                    </li>*/}
                  </ul>
                </li> 
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Member")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                   
                   
                    <li>
                      <Link to="add-membership">
                        {this.props.t("Member Registration")}
                      </Link>
                    </li>
                    
                    <li>
                      <Link to="add-entity-membership">
                        {this.props.t("Entity Registration")}
                      </Link>
                    </li>
                    <li>
                      <Link to="unapproved">
                        {this.props.t("Approve Member")}
                      </Link>
                    </li>
                    <li>
                      <Link to="memberships">
                        {this.props.t("Member Synopsis")}
                      </Link>
                    </li>
                    <li>
                      <Link to="memberdetailsreport">
                        {this.props.t("Register of Members")}
                      </Link>
                    </li>
                    <li>
                      <Link to="memberjoiningreport">
                        {this.props.t("Member Joining Report")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Non-Members")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Search for Member")}
                      </Link>
                    </li>
                    <li>
                      <Link to="memberstatement">
                        {this.props.t("Member's Statement")}
                      </Link>
                    </li>
                    
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Transactions")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/schememaster">{this.props.t("Scheme")}</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/ledgerdetails">{this.props.t("Ledger Details")}</Link>
                    </li>
                    <li>
                      <Link to="/schemeinterestmaster">{this.props.t("Scheme Interest")}</Link>
                    </li>
                    <li>
                      <Link to="/sponsor">{this.props.t("Sponsor Information")}</Link>
                    </li> */}

                  
  
                    <li>
                      <Link to="add-cashdeposit">
                        {this.props.t("Cash Deposit")}
                      </Link>
                    </li>
  
                    <li>
                      <Link to="add-cashwithdrawl">
                        {this.props.t("Cash Withdrawl")}
                      </Link>
                    </li>

                    
                    <li>
                      <Link to="accounttoaccount">
                        {this.props.t("Fund Transfer from Transaction Account")}
                      </Link>
                    </li>

                    
                    <li>
                      <Link to="accounttoaccount">
                        {this.props.t("Fund Transfer to Transaction Account")}
                      </Link>
                    </li>

                    <li>
                      <Link to="persontoperson">
                        {this.props.t("Person to Person Fund Transfer")}
                      </Link>
                    </li>

                    <li>
                      <Link to="cashbook">
                        {this.props.t("Cash Book")}
                      </Link>
                    </li>
                    <li>
                      <Link to="ledgerdetails">
                        {this.props.t("Ledger")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="add-account-to-account">
                        {this.props.t("Inter-Account Fund Transfer")}
                      </Link>
                    </li>
                    <li>
                      <Link to="add-person-to-person">
                        {this.props.t("Inter-Person Fund Transfer")}
                      </Link>
                    </li>
                  
                    <li>
                      <Link to="#">{this.props.t("Standing Order")}</Link>
                    </li>
 

                  
                    <li>
                      <Link to="accounttoaccount">
                        {this.props.t("Inter-Account Fund Transfer Report")}
                      </Link>
                    </li>
                    <li>
                      <Link to="persontoperson">
                        {this.props.t("Inter-Person Fund Transfer Report")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Payment Report")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Standing Order Report")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Transaction Report (All Categories)")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Statement (Detailed)")}</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Account")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">


                  <li>
                      <Link to="account_transaction">{this.props.t("Transaction Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Shares")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Caveo Card Account")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Reserved Account")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Thrift Account")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Monthly Income Scheme")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Term Deposit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Death Compesation Assurance")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Co-Operative Insure")}</Link>
                    </li>
                    
                  {/* <li>
                      <Link to="checkbalance">{this.props.t("Check Balance")}</Link>
                    </li>
                    <li>
                      <Link to="/account_transaction">{this.props.t("Transaction Account")}</Link>
                    </li>
                    <li>
                      <Link to="/reserved_transaction">{this.props.t("Reserved Account")}</Link>
                    </li>

                    <li>
                      <Link to="/thrift_transaction">{this.props.t("Thrift Account")}</Link>
                    </li>
                    <li>
                      <Link to="/thrift_transaction">{this.props.t("Monthly Income Scheme")}</Link>
                    </li> */}
  
                    {/* <li>
                      <Link to="/agentbankingaccount">{this.props.t("Agent Account")}</Link>
                    </li> */}

                    {/* <li>
                      <Link to="#">{this.props.t("Account Closure")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Ledger")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Report")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Search")}</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Loans")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                  <li>
                      <Link to="#">{this.props.t("Cash Loan")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Credit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Documentary Credit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Mortgage")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Reverse Mortgage")}</Link>
                    </li>
                    {/* <li>
                      <Link to="newshare">{this.props.t("New Shares")}</Link>
                    </li>
                    <li>
                      <Link to="addshare">{this.props.t("Add Shares")}</Link>
                    </li>
                    <li>
                      <Link to="withdrawshare">{this.props.t("Withdraw Shares")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("List of Shares")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Dividend Summary")}</Link>
                    </li> */}
                    {/* <li>
                      <Link to="#">{this.props.t("Share Pay-out")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Share Register")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Share Status")}</Link>
                    </li> */}
                    
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Modifications")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Member Records")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Process Self-Srvice Request")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Process Agent Request")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Transaction Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Shares")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Caveo Card Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Reserved Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Thrift Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Monthly Income Scheme")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Term Deposit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Death Compensation Assurance")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Co-Operative Insure")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Cash Loan")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Credit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Documentary Credit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Mortgage")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Reverse Mortgage")}</Link>
                    </li>
                  </ul>
                </li>
               




                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Agent")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                  <li>
                      <Link to="#">{this.props.t("Agent Service Portal")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Add Funds to Agent Account")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Transfer Funds from Agent Account")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Agent Day Book")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Agent Statement")}</Link>
                    </li>


                    {/* <li>
                      <Link to="/agentdetails">{this.props.t("Agents")}</Link>
                    </li>

                    <li>
                      <Link to="/agentstatement">{this.props.t("Agent Statement")}</Link>
                    </li>

                    <li>
                      <Link to="/agentledgerreport">{this.props.t("Agent Ledger")}</Link>
                    </li>
                    
                    <li>
                      <Link to="add-agent">{this.props.t("New Agent On-Boarding")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Collector SAM Card Issuance")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Collector SAM Card Management")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Collector Promotion/Demotion")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Search Collector")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Collector Performancet")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Collector Ledger")}</Link>
                    </li> */}
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Staff & Salaries")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="#">{this.props.t("Staff")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Salaries")}</Link>
                    </li> */}
                      <li>
                      <Link to="addstaff">{this.props.t("Add New Staff")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Add Removed Staff")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Remove Staff")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Staff Register")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Process Salaries")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Approve Salaries")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Statement of Payments")}</Link>
                    </li>
                  </ul>
                </li>
                
                
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Operations")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Register Agent")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Approve Agent")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Agent's Register")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Manage Agent")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Agent's Ledger")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Agent's Day Book")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Agent's Log")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Public User Registration")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Create Public Viewer")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Public Viewer Log")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Self Service")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("My Statement")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("My Membership Synopsis")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Inter-Account Transfer")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("My Transaction Account to Another")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Standing Order")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Request for Reversal of Payment")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("My Apllications")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Control Panel")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Budget")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Membership Data")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Transaction Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Shares")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Caveo Card Account")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Reserved Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Thrift Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Monthly Income Scheme")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Term Deposit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Death Compensation Assurance")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Vouchers Reserved")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Vouchers Paid")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Vouchers Received")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Portfolio")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Bullion Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Assets")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Salary Payments")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Agents")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Overhead Expenses")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Operating Expenses")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Income Statement")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Cash Flow Statement")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Dividend Sensitivity Analysis")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Balance Sheet")}</Link>
                    </li>
                  </ul>
                </li>
                
              
                {/* <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" />
                    <span>{this.props.t("Modifications")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Member")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Shares")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Share Renewal")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Transaction Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t(
                          "Transaction Modification on Transaction Account"
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Loan Modification")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Monthly Income Scheme")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Reverse Mortgage Modification")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Card Transaction Limit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Modify Employee Record")}</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li>
                  <Link to="/#" className="has-arrow">
                 
                    <span>{this.props.t("Management Accounts")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Financial Year")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Group")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Ledger")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("External Bank")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Gold Stock")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Gold Depository (Cash Security)")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Payment Vouchers Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Received Vouchers Entry")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Journal Vouchers Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Contra Vouchers Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Bank Deposit Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Bank Withdrawal Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("External Bank Transactions")}
                      </Link>
                    </li>
                    <li>
                    <Link to= {{  pathname:"http://shinewellsofttech.co.in:50693/reporting/site/site1/reports/5f311306-1664-4a01-8799-ec57aa756a10/Ghana/Cashbook" }} target="_blank">{this.props.t("Cash Book")}</Link>

                      
                    </li>
                   

                    <li>
                    <Link to= {{  pathname:"http://shinewellsofttech.co.in:50693/reporting/site/site1/reports/daf483b3-3d58-478a-a952-be3352ad7888/Ghana/BankLedger" }} target="_blank">{this.props.t("Bank Ledger")}</Link>
                    </li>


                    <li>
                      <Link to="#">{this.props.t("Day Book")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Bank Book")}</Link>
                    </li>
                    <li>
                      <Link to="http://shinewellsofttech.co.in:50693/reporting/site/site1/reports/174282ad-2fee-48d8-83c1-59571d9b4ac4/Ghana/LedgerReport">{this.props.t("Ledger")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Overhead Expense Statement")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Operating Expense Statement")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Profit & Loss Statement")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Cash Flow Statement")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Balance Sheet")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Trial balance")}</Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Help")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("User Help")}</Link>
                    </li>

                    <li>
                      <Link to="globaloptions">{this.props.t("Global Options")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );

    }



    else {

      return (
        <React.Fragment>
          <SimpleBar autoHide={false}   className="h-100"  ref={this.refDiv}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{this.props.t("Menu")}</li>
                <li>
                  <Link to="/#">
                    {/* <i className="bx bx-home-circle" /> */}
                    <span className="badge rounded-pill bg-info float-end">
                      04
                    </span>
                    <span>{this.props.t("Dashboards")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/dashboard">{this.props.t("Default")}</Link>
                    </li>
                 
                  </ul>
                </li>
  
                <li className="menu-title">{this.props.t("Apps")}</li>
                
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Membership")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                   
                  
                    <li>
                      <Link to="add-entity-membership">
                        {this.props.t("New Entity Member Registration")}
                      </Link>
                    </li>

                    <li>
                      <Link to="add-membership">
                        {this.props.t("New Member Registration")}
                      </Link>
                    </li>


                    {/* <li>
                      <Link to="/memberships">{this.props.t("Members")}</Link>
                    </li>
   */}
               
                    {/* <li>
                      <Link to="#">{this.props.t("Status Report")}</Link>
                    </li> */}
                    <li>
                      <Link to="memberjoiningreport">{this.props.t("Member Joining Report")}</Link>
                    </li>

                    {/* <li>
                      <Link to="/memberdetailsreport">{this.props.t("Member Details Report")}</Link>
                    </li> */}

                    {/* <li>
                      <Link to="memberdetailsreportactiveinactive">
                        {this.props.t("Active & Inactive Members Report")}
                      </Link>
                    </li> */}
                    
                    {/* <li>
                      <Link to="#">{this.props.t("Search for Member")}</Link>
                    </li> */}

                    {/* <li>
                      <Link to="#">{this.props.t("Member Ledger")}</Link>
                    </li> */}
                   
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Transactions")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    
                  <li>
                      <Link to="add-cashdeposit">
                        {this.props.t("Cash Deposit")}
                      </Link>
                    </li>
                   

                    <li>
                      <Link to="add-cashwithdrawl">
                        {this.props.t("Cash Withdrawl")}
                      </Link>
                    </li>

                    <li>
                      <Link to="add-account-to-account">
                        {this.props.t("Inter-Account Fund Transfer")}
                      </Link>
                    </li>
                    <li>
                      <Link to="add-person-to-person">
                        {this.props.t("Inter-Person Fund Transfer")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Standing Orders")}</Link>
                    </li>

                    <li>
                      <Link to="#">{this.props.t("Payment Report")}</Link>
                    </li>
                    


                    <li>
                      <Link to="#">{this.props.t("Transaction Report(All Categories)")}</Link>
                    </li>
                   

                    {/* <li>
                      <Link to="/sponsor">{this.props.t("Sponsor Information")}</Link>
                    </li> */}
                  
  
                   
  
                
                  
  
                 
                  
                  
                
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Account")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/account_transaction">{this.props.t("Transaction Account")}</Link>
                    </li>
                    <li>
                      <Link to="/reserved_transaction">{this.props.t("Reserved Account")}</Link>
                    </li>
  

                    <li>
                      <Link to="#">{this.props.t("Account Closure")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Ledger")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Report")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Search")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Shares")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Share Purchase")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Share Pay-out")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Share Register")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Share Status")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("MIS")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Open Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("MIS Closure")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Search Accounts")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Term Deposits")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Fixed Deposit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Recurring Deposit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Cash Certificate")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Pigmy")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Thrift Deposit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Search Deposit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Reports")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Loan")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Loan Request")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Loan Approval")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Loan Disbursement / Payment")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Loan Re-Payment")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Loan Settlement")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Loan Reports")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Loan Documents")}</Link>
                      <ul className="sub-menu" aria-expanded="false">
                        <li>
                          <Link to="#">
                            {this.props.t("Loan Pro-Forma Invoice")}
                          </Link>
                        </li>
                        <li>
                          <Link to="#">{this.props.t("Loan Offer")}</Link>
                        </li>
                        <li>
                          <Link to="#">{this.props.t("Loan Amortisation")}</Link>
                        </li>
                        <li>
                          <Link to="#">
                            {this.props.t("Disbursement Receipt")}
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            {this.props.t("Security / Collateral")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Loan Search")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Reverse Mortage")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">
                        {this.props.t("Reverse Mortage Request")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Reverse Mortage Approval")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Reverse Mortage Disbursement Plan")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Reverse Mortage Documents")}
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        <li>
                          <Link to="#">
                            {this.props.t("Reverse Mortage Offer")}
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            {this.props.t("Reverse Mortage Amortisation")}
                          </Link>
                        </li>
                        <li>
                          <Link to="#">{this.props.t("Disbursement")}</Link>
                        </li>
                        <li>
                          <Link to="#">
                            {this.props.t("Security/Collateral")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Reverse Mortage Search")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Provident Account")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Healthcare")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Death Compensation Assurance")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Vehicle Insurance")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Burglary")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Card")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Card Issuance")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Card Management")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Card Verification")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Card Activity Report")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Channels")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("POS")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("ATM")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("QR Code")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Online")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Mobile Money")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("International Payment Gateway")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("SMS")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("E-Mail")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Agent")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("New Agent On-Boarding")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Collector SAM Card Issuance")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Collector SAM Card Management")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Collector Promotion/Demotion")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Search Collector")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Collector Performancet")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Collector Ledger")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Staff & Salaries")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                  
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Modifications")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Member")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Shares")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Share Renewal")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Transaction Account")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t(
                          "Transaction Modification on Transaction Account"
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Loan Modification")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Monthly Income Scheme")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Reverse Mortgage Modification")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Card Transaction Limit")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Modify Employee Record")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Management Accounts")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("Financial Year")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Group")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Account Ledger")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("External Bank")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Gold Stock")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Gold Depository (Cash Security)")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Payment Vouchers Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Received Vouchers Entry")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Journal Vouchers Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Contra Vouchers Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Bank Deposit Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Bank Withdrawal Entry")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("External Bank Transactions")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Cash Book")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Day Book")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Bank Book")}</Link>
                    </li>
                    <li>
                      <Link to={{  pathname:"http://shinewellsofttech.co.in:50693/reporting/site/site1/reports/174282ad-2fee-48d8-83c1-59571d9b4ac4/Ghana/LedgerReport"}} target="_blank">{this.props.t("Ledger")}</Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Overhead Expense Statement")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Operating Expense Statement")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {this.props.t("Profit & Loss Statement")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Cash Flow Statement")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Balance Sheet")}</Link>
                    </li>
                    <li>
                      <Link to="#">{this.props.t("Trial balance")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {/* <i className="bx bx-receipt" /> */}
                    <span>{this.props.t("Help")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="#">{this.props.t("User Help")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );


    }

  
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
