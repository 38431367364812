import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";

class pageAddEdit_SchemeInterest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Scheme Interest";
    this.breadCrumbTitle = "Scheme Interest";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.SCHEMEINTEREST + "/0/token";
    this.pushFormName = "/schemeinterestmaster";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.AccontTypeChange  = this.AccontTypeChange.bind(this);
  }
  componentDidMount() {
    //Filling DropDowns
    
    Fn_FillListData(this.obj, "accounttype", API_WEB_URLS.MASTER + "/0/token/AccountType/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }


  AccontTypeChange(event) {
    Fn_FillListData(this.obj, "AccountTypeSchemes", API_WEB_URLS.MASTER + "/0/token/AccountTypeSchemesByAccountType/Id/"+event.target.value);
  }




  btnSave_onClick(event, formData) {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    let vformData = new FormData();
   
    vformData.append("F_AccountType", formData.F_AccountType);
    vformData.append("F_AccountTypeSchemes", formData.F_AccountTypeSchemes);
    vformData.append("MainInterest", formData.MainInterest);

    if (!this.state.id)    {
      Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true , "Id", this.fun);
    }
    else {
   
  }
  }

  btnCancel_onClick = event => {
   // event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={this.breadCrumbTitle}
            breadcrumbItem={this.breadCrumbItem}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Scheme Master</h4>
                  <div className="wizard clearfix">
                    <div className="content clearfix">
                          
                          <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                       
                              <Row>
                                <Col lg="6">
                                <Card>
                                  <CardBody>
                                    <h4 className="card-title mb-4">Information</h4>
                           
                            <Row>

                            <Col sm="4">
                                        <label htmlFor="membershipType" className="col-form-label">Account Type</label>
                                      </Col>
                              <Col lg="6">

                              <AvField name="F_AccountType"  onChange={this.AccontTypeChange} value={this.state.formData.F_AccountType} errorMessage="Select Account Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                  <option value={-1} defaultValue label={"Select"} />
                                  {this.state.accounttype
                                    ? this.state.accounttype.map(
                                        (option, key) => (
                                          <option key={option.Id} value={option.Id} label={option.Name} />
                                        )
                                      )
                                    : null}
                                </AvField>
                               
                              </Col>
</Row>


<Row>

<Col sm="4">
                                        <label htmlFor="membershipType" className="col-form-label">Scheme Name</label>
                                      </Col>
                              <Col lg="6">

                              <AvField name="F_AccountTypeSchemes"   value={this.state.formData.F_AccountTypeSchemes} errorMessage="Select F_AccountTypeSchemes" validate={{ required: { value: true } }} type="select" className="form-select" >
                                  <option value={-1} defaultValue label={"Select"} />
                                  {this.state.AccountTypeSchemes
                                    ? this.state.AccountTypeSchemes.map(
                                        (option, key) => (
                                          <option key={option.Id} value={option.Id} label={option.Name} />
                                        )
                                      )
                                    : null}
                                </AvField>
                               
                             
                                </Col>


</Row>

                             


                          


                         

                              <Row>
                              <Col sm="4">
                                        <label htmlFor="membershipType" className="col-form-label">Interest </label>
                                      </Col>



                              <Col lg="6">
                                <AvField name="MainInterest"  value={this.state.formData.MainInterest} placeholder="Enter MainInterest" errorMessage="Enter MainInterest" validate={{ required: { value: true } }} type="number" className="form-control" />
                              </Col>



                              </Row>





                        <div className="d-flex flex-wrap gap-2">
                          <Button type="submit" color="primary">
                            Submit
                          </Button>{" "}
                          <Button type="reset" color="secondary" 
                          onClick={this.btnCancel_onClick}
                          >
                            Cancel
                          </Button>
                        </div>

</CardBody>
                    </Card>
                  </Col>
                  
                    </Row>
                  
                    </AvForm>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_SchemeInterest);
