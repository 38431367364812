import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import Switch from "react-switch"
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";
import SweetAlert from "react-bootstrap-sweetalert";



class pageAddEdit_MIS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      ed : true,
      passedSteps: [1],
      success_msg: false,
      //dropdowns
      MemberName: [
        {
          Name : ""
        }
      ],
     
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      TotalAmount  : 0,
      insuffiecientfunds : false,
      isPayout : false,
      minimummis : false
      
     



    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Shares ";
    this.breadCrumbTitle = "Shares ";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/AdvisorSelectById";
    this.API_URL_SAVED = API_WEB_URLS.MIS + "/0/token";
    this.API_URL_SAVEW = API_WEB_URLS.WithdrawShare + "/0/token";
    this.pushFormName = "#";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.calculatevalue  =  this.calculatevalue.bind(this);
    this.fun  =  this.fun.bind(this);
    this.syno  =  this.syno.bind(this);
  }




  


  fun() {
      this.setState({success_msg: true , 
        formData :  {
              MembershipNo : ''
        },
        MemberName : [{
          Name : '',
          ExistingShares : 0,
          ShareValue : 0,
          AvailableShare : 0
        }],
        NewNoOfShares : 0,
        TotalAmount : 0,
        formData : {
          ShareForwithdraw : 0
        }
      
      })
  }

  syno () {

    this.setState({
      success_msg : false,
      insuffiecientfunds : false,
      minimummis : false
    });
      

      };


  componentDidMount() {
    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Add MIS")
    //Filling DropDowns
      Fn_FillListData(this.obj, "cader", API_WEB_URLS.MASTER + "/0/token/Cader/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id , ed : false });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }



  btnSave_onClick(event, formData) {

    const obj = JSON.parse(localStorage.getItem("authUser"));

      if (this.state.isPayout  == true) {
        let vformData2 = new FormData();
   
        vformData2.append("NewShares", formData.ShareForwithdraw);
        vformData2.append("UserId", obj.uid);


        Fn_AddEditData(this.obj, { arguList: { id: this.state.MemberName[0].Id, formData: vformData2 } }, this.API_URL_SAVEW, this.pushFormName, true, "F_VoucherH" , this.fun );


      }

      else {
       

        if(this.state.MemberName[0].TranBalance < parseFloat(formData.Amount) ){
          this.setState({insuffiecientfunds : true})
        }

            else if (parseFloat(formData.Amount) < parseFloat(this.state.MemberName[0].MinimumMISAmount)){
              this.setState({minimummis: true})
            }

    else {
    
   
    
      let vformData = new FormData();
   
      vformData.append("Amount", formData.Amount);
      vformData.append("UserId", obj.uid);
    
   

    if (!this.state.id)
    Fn_AddEditData(this.obj, { arguList: { id: this.state.MemberName[0].Id, formData: vformData } }, this.API_URL_SAVED, this.pushFormName, true, "F_VoucherH" , this.fun );

    }

  }
   
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetMemberName =(event)=> {

    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "MemberName", API_WEB_URLS.MASTER + "/0/token/GetBalance/1/Mem/Balance?ListFor="+value + "&F_AccountType=5");

   
  }


  calculatevalue (event) {
    var NewShare  =  event.target.value;

    var ShareValue  =  parseFloat(this.state.MemberName[0].ShareValue);

    var TotalShare  =  parseFloat(this.state.MemberName[0].ExistingShares) + parseFloat(NewShare);

    var TotalAmount  =  parseFloat(TotalShare)*parseFloat(ShareValue);

    this.setState({TotalAmount  : TotalAmount , NewNoOfShares : event.target.value})
  }










  render() {

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Yes
        </div>
      )
    }


    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          No
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                  
                    <div className="wizard clearfix">
                      <div className="content clearfix">


                        
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>

                                      <Row>                                
                                    <Col sm="4" className="mb-3">
                                          <label htmlFor="Nationality" className="col-form-label">Pay Out</label>
                                        </Col>
                                        <Col sm="6">
                                        
                                        <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-3 mb-lg-8 mb-2"
                            onColor="#ec4561"
                            onChange={() =>
                              this.setState({ isPayout: !this.state.isPayout })
                            }
                            checked={this.state.isPayout}
                          />
                                        </Col>
                                        </Row>
                              <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Membership No.</label>
                                        </Col>
                                <Col lg="6">

                                <AvField name="membershipno" onChange={this.GetMemberName}  value={this.state.formData.MembershipNo} placeholder="Enter membershipno" errorMessage="Enter membershipno" validate={{ required: { value: true } }} type="text" className="form-control" />
                                 
                                </Col>
                              </Row>


    <Row>

    <Col sm="4">
                                              <label htmlFor="membershipType" className="col-form-label">Member Name</label>
                                            </Col>
                                    <Col lg="6">


                                    <AvField name="membername"  value={this.state.MemberName[0].Name} placeholder=" Member Name"   type="text" disabled className="form-control" />
                                      </Col>
    </Row>


 

<Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Amount Deposited</label>
                                        </Col>
                                <Col lg="6">


                                <AvField name="AmountDeposited"  value={this.state.MemberName[0].DepositedAmount} placeholder="AmountDeposited" disabled   type="number"  className="form-control" />
                                  </Col>


</Row>




<Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Total Interest</label>
                                        </Col>
                                <Col lg="6">


                                <AvField name="Interest"  value={this.state.MemberName[0].TotalInterest} placeholder="Interest"   type="number" disabled className="form-control" />
                                  </Col>


</Row>



<Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Balance</label>
                                        </Col>
                                <Col lg="6">
                                <AvField name="Balance"  value={this.state.MemberName[0].AccountBalance} placeholder="Balance"   type="number" disabled className="form-control" />
                                  </Col>


</Row>

{this.state.isPayout  ?<Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Amount</label>
                                        </Col>
                                <Col lg="6">
                                <AvField name="Amount"  value={this.state.formData.Amount} placeholder="Amount"   type="number"  className="form-control" />
                                  </Col>


</Row> : <Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Amount</label>
                                        </Col>
                                <Col lg="6">
                                <AvField name="Amount"  value={this.state.formData.Amount} placeholder="Amount"   type="number"  className="form-control" />
                                  </Col>


</Row> }






                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Save
                            </Button>{" "}
                            <Button type="reset" color="secondary">
                              Cancel
                            </Button>
                          </div> {this.state.success_msg ? (
                      <SweetAlert
                        title="AMOUNT CREDITED SUCCESSFULLY TO MIS!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        You clicked the button!
                      </SweetAlert>
                    ) : null}

{this.state.insuffiecientfunds ? (
                      <SweetAlert
                        title="INSUFFICIENT FUNDS!"
                        danger
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        Available Balance  -  {this.state.MemberName[0].TranBalance}
                      </SweetAlert>
                    ) : null}


{this.state.minimummis ? (
                      <SweetAlert
                        title="Can't add funds!"
                        danger
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        Minimum MIS Amount  -  {this.state.MemberName[0].MinimumMISAmount}
                      </SweetAlert>
                    ) : null}

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_MIS);
