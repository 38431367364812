import React, { Component } from "react"

import { Row, Col, Card, CardBody , CardTitle , Container } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "./main.jpg"
import LineChart from "./linechart"

class WelcomeComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
    Name : '',
    Role : ''
    };
  }


  componentDidMount() {


    const obj = JSON.parse(localStorage.getItem("authUser"));


    this.setState({ Name: obj.username, email: obj.email, aid: obj.uid , Role:obj.role  , roleid:obj.roleid });

    
    
  }


  render() {
    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p>Skote Dashboard</p>
                </div>
              </Col>
              {/* <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" style={{maxWidth : "45%"}} className="img-fluid" />
              </Col> */}
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="4">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={avatar1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 className="font-size-15 text-truncate">{this.state.Name}</h5>
                <p className="text-muted mb-0 text-truncate">{this.state.Role}</p>
              </Col>

              <Col sm="8">
                <div className="pt-4">
                  <Row>
                    <Col xs="6">
                      <h5 className="font-size-15">0</h5>
                      <p className="text-muted mb-0">Members</p>
                    </Col>
                    <Col xs="6">
                      <h5 className="font-size-15">₵0</h5>
                      <p className="text-muted mb-0">Bal</p>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Link
                      to="changepassword"
                      className="btn btn-primary btn-sm"
                    >
                      View Profile {" "}<i className="mdi mdi-arrow-right ms-1"/>
                    </Link>
                  </div>
                </div>
              </Col>




            </Row>

           



          </CardBody>





         





        </Card>


    
       
            <Row>
            <Col lg={10}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Data Chart</CardTitle>
                    <Row className="justify-content-center">
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">0</h5>
                          <p className="text-muted">Activated</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">0</h5>
                          <p className="text-muted">Pending</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">0</h5>
                          <p className="text-muted">Deactivated</p>
                        </div>
                      </Col>
                    </Row>
                    <LineChart />
                  </CardBody>
                </Card>
              </Col>
            </Row>

      </React.Fragment>
    )
  }
}

export default WelcomeComp