import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Col, Card, CardBody } from "reactstrap"

class MiniWidget extends Component {

  constructor(props) {
    super(props)
    this.state = {
    Name : '',
    Role : ''
    };
  } 
  
  componentDidMount() {


    const obj = JSON.parse(localStorage.getItem("authUser"));


    this.setState({ Name: obj.username, email: obj.email, aid: obj.uid , Role:obj.role  , roleid:obj.roleid });

    
    
  }



  render() {
    return (
      <React.Fragment>
        {this.props.reports.map((report, key) => (
          <Col sm="3" key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className={report.icon}/>
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">{report.title}</h5>
                </div>
                <div className="text-muted mt-4">
                  <h6>
                    Current Balance :  {report.value}{" "}
                   
                  </h6>

                  {this.state.roleid ==  2 || this.state.roleid  ==  4 ? 
                  <>
                  <h6>
                    Total Credits :  {report.value}{" "}
                    
                  </h6>
                  <h6>
                    Total Debits  :  {report.value}{" "}
                   
                  </h6>
                  <h6>
                    Total Transaction :  {report.value}{" "}
                    <i className="mdi mdi-chevron-up ms-1 text-success"/>
                  </h6>
                  </>
                   : null}
                  <div className="d-flex">
                    <span
                      className={
                        "badge badge-soft-" + report.color + " font-size-12"
                      }
                    >
                      {" "}
                      {report.badgeValue}{" "}
                    </span>{" "}
                    <span className="ms-2 text-truncate">{report.desc}</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    )
  }
}

MiniWidget.propTypes = {
  reports: PropTypes.array
}

export default MiniWidget
