import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";
//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport
} from "../../store/functions";
import Breadcrumbs from "../../components/Common/Breadcrumb";




function printInvoice2 ()  {
  alert("Hi");
    }

class pageList_MemberDetailsReportActiveInActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      isProgress:true,
      formData : {},
      gridDatanominalMFG : [{
        
      }],
      gridDataMFG  : [{
        
      }],
      isDelete : false

    };
    this.obj = this;
    this.breadCrumbTitle = "Active InActive Report";
    this.breadCrumbItem = "Active InActive Report";
    this.modalTitle = "Active InActive Report";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    
    this.btnSave_onClick  =  this.btnSave_onClick.bind(this);
this.renderGridBody =  this.renderGridBody.bind(this);
   

  }


  
 
  componentDidMount() {


    const obj = JSON.parse(localStorage.getItem("authUser"));


    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role  , roleid:obj.roleid });
    
  }

  getaccountsdata (){
    alert(this.state.Id);
  }



   
  renderGridHeader() {
    return (
      <>
      
        <th>  Membership No.</th>
        <th>Full Name</th>
        <th>Maiden Name</th>
        <th>Gender</th>
        <th>Phone No.</th>
        <th>Joining Date</th>
        {/* <th>Country Code</th>
        <th>ISD Code</th> */}
      </>
    );
  }


 





  renderGridBody(formData) {
    
    return (
      <>
        <td >
       


          <a onClick={() =>  {this.props.history.push(`/membersynopsisprint/${formData.Id}`, {})}}>
                          
            {formData.MembershipNo}</a></td>
        <td>{formData.FullName}</td>
        <td>{formData.MaidenName}</td>
        <td>{formData.Gender}</td>
        <td>{formData.MobileNo_SMS}</td>
        <td>{formData.DateOfJoining}</td>
     
      </>
    );
  }


  renderModalBody(selectedFormData) {

    

    return (
      <>
        <p className="mb-4">
          Name: <span className="text-primary">{selectedFormData.Id}</span>
        </p>
      </>
    );
  }


  

  btnSave_onClick (event, formData)  {



    const obj = JSON.parse(localStorage.getItem("authUser"));


    if (obj.roleid == 4 && formData.IsActive == 0 ) {
      this.setState({  isDelete : true });
    }

    else {
      this.setState({  isDelete : false });
    }

    let vformData = new FormData();
   
    vformData.append("FromDate", formData.FromDate);
    vformData.append("ToDate", formData.ToDate);
    vformData.append("FromAge", formData.FromAge);
    vformData.append("ToAge", formData.ToAge);
    vformData.append("F_MembershipType", 1);
    vformData.append("IsActive", formData.IsActive);
   
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, "MemberDetailsReportActiveInActive/0/token", "gridData", true);
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, "GetGenderTotalActiveInActive/0/token", "gridDataMFG", true);



    let vformData2 = new FormData();
   
    vformData2.append("FromDate", formData.FromDate);
    vformData2.append("ToDate", formData.ToDate);
    vformData2.append("FromAge", formData.FromAge);
    vformData2.append("ToAge", formData.ToAge);
    vformData2.append("F_MembershipType", 2);
    vformData2.append("IsActive", formData.IsActive);


  

    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData2 } }, "MemberDetailsReportActiveInActive/0/token", "gridDatanominal", true);
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData2 } }, "GetGenderTotalActiveInActive/0/token", "gridDatanominalMFG", true);

  }

  



  


  render() {
    return (
<>
<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="9">
                                  {/* <Card>
                                    <CardBody> */}


                                    <Row>
                                    <Col sm="4">
                                          <label htmlFor="isactive" className="col-form-label">Is Active </label>
                                        </Col>
                                        <Col sm="4" className="mb-0">
                                        <AvField name="IsActive" label="" checked={this.state.formData.IsActive === 'True' ? true   : false}    type="checkbox" className="form-control" disabled={this.state.dis} />
                                        </Col> 
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">From Date</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="FromDate" label="" value={this.state.formData.FromDate} placeholder="From Date"  type="date"  className="form-control" />
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">ToDate</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="ToDate" label="" value={this.state.formData.ToDate} placeholder="To Date"  type="date"  className="form-control" />
                                          </Col>
                                      </Row>

                                     
                                    <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">From Age</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="FromAge" label="" value={this.state.formData.FromAge} placeholder="From Age"  type="number"  className="form-control" />
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">To Age</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="ToAge" label="" value={this.state.formData.ToAge} placeholder="To Age"  type="number"  className="form-control" />
                                          </Col>
                                      </Row>

                       
                                      
                         {/* </CardBody>
                         </Card> */}
                         </Col>
                         </Row>
                         <div>
                          <Button
                          type="submit"
                          color="primary"
                        
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>

                        
                          </div>

                         
                         </AvForm>

                         </div> 
                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                         </Container>
                         </div>
                         </React.Fragment>





                         <React.Fragment>
       
       
            
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                  
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" >
                         
                                <Row>
                                  <Col lg="12">


                                    <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Total Male</label>
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                        <AvField name="TotalMale" label="" value={this.state.gridDataMFG[0].Male} placeholder="Total Male"  type="number" disabled  className="form-control" />
                                        </Col>
                                      <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Total Female</label>
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                        <AvField name="TotalFeMale" label="" value={this.state.gridDataMFG[0].FeMale} placeholder="Total FeMale"  type="number" disabled  className="form-control" />
                                          </Col>
                                   
                                      <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Grand Total</label>
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                        <AvField name="GrandTotal" label="" value={this.state.gridDataMFG[0].Total} placeholder="Grand Total"  type="number" disabled  className="form-control" />
                                          </Col>
                                      </Row>
                      
                         </Col>
                         </Row>
                 

                         
                         </AvForm>

                      
                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                        
                       
                         </React.Fragment>



                         <RCDisplayPage
        //page header paramaters
       
       Isbreadcrumb = {true}
       breadCrumbTitle={this.breadCrumbTitle}
       breadcrumbItem={"Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={this.state.isDelete}
      ></RCDisplayPage>



<React.Fragment>
       
       
            
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                  
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" >
                         
                                <Row>
                                  <Col lg="12">


                                    <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Total Male</label>
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                        <AvField name="TotalMale" label="" value={this.state.gridDatanominalMFG[0].Male} placeholder="Total Male"  type="number" disabled  className="form-control" />
                                        </Col>
                                     
                                    

                                     
                                      <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Total Female</label>
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                        <AvField name="TotalFeMale" label="" value={this.state.gridDatanominalMFG[0].FeMale} placeholder="Total FeMale"  type="number" disabled  className="form-control" />
                                          </Col>
                                     

                                   
                                      <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Grand Total</label>
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                        <AvField name="GrandTotal" label="" value={this.state.gridDatanominalMFG[0].Total} placeholder="Grand Total"  type="number" disabled  className="form-control" />
                                          </Col>
                                      </Row>

                                     
                                   

                       
                                      
                      
                         </Col>
                         </Row>
                 

                         
                         </AvForm>

                      
                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                        
                       
                         </React.Fragment>



<RCDisplayPage
        //page header paramaters
       
       Isbreadcrumb = {true}
       breadCrumbTitle={this.breadCrumbTitle}
       breadcrumbItem={"Nominal Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatanominal}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={this.state.isDelete}
      ></RCDisplayPage>
     
      </>
    );
  }
}
export default compose(container)(pageList_MemberDetailsReportActiveInActive);
