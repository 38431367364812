import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";
import { bind } from "lodash";




class pageAddEdit_SchemeMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      membershipType: [],
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype :  [
       
      ],

      emitype :  [
        {
          Id : 1,
          Name : 'EMI Type'
        },
        {
          Id : 2,
          Name  : 'Non-EMI Type'
        }
       
      ],
      periodicity :  [
        {
          Id : 1,
          Name : 'Day'
        },
        {
          Id : 2,
          Name  : 'Month'
        },
        {
          Id : 3,
          Name  : 'Week'
        }

       
      ]



    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Scheme Master";
    this.breadCrumbTitle = "Scheme Masters";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL_SAVE = API_WEB_URLS.ACCOUNTTYPESCHEME + "/0/token";
    this.pushFormName = "/schememaster";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
  }




  componentDidMount() {
    //Filling DropDowns
   
    Fn_FillListData(this.obj, "periodicity", API_WEB_URLS.MASTER + "/0/token/Periodicity/Id/0");
    Fn_FillListData(this.obj, "accounttype", API_WEB_URLS.MASTER + "/0/token/AccountType/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }

  fun() {

  }
  btnSave_onClick(event, formData) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    let vformData = new FormData();
   
    vformData.append("F_AccountType", formData.accounttypevalue);
    vformData.append("UserId", obj.uid);
    vformData.append("IsInterestVariable", formData.IntrestVariable);
    vformData.append("Name", formData.schemeName);


    if (!this.state.id)    {
      Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true , "Id", this.fun);
    }
    else {
   
  }
}

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }


  onChange =()=> {

    if (this.state.prov == true){
      this.setState({ prov: false });
    }

    else {
      this.setState({ prov: true });
    }

  }
  

  AccountChange = () => {
    var accounttype =   this.state.accounttypevalue;

     if(accounttype  == 4){
      return (<Row>
        <Col sm="4">
                  <label htmlFor="membershipType" className="col-form-label">Pre Maturity After</label>
                </Col>


                <Col lg="6">
          <AvField name="preMaturity"  value={this.state.formData.MaidenName} placeholder="Enter Pre Maturity After in Days" errorMessage="Enter Pre Maturity After" validate={{ required: { value: false } }} type="text" className="form-control" />
        </Col>

        </Row>)
    }

    else {
      return (<Row>
        <Col sm="4">
                  <label htmlFor="membershipType" className="col-form-label">Pre Maturity After</label>
                </Col>


                <Col lg="6">
          <AvField name="preMaturity"  value={this.state.formData.MaidenName} placeholder="Enter Pre Maturity After in Months" errorMessage="Enter Pre Maturity After" validate={{ required: { value: false } }} type="text" className="form-control" />
        </Col>

        </Row>)
    }


    
}


AccountValueChange =  (event) =>{

  this.setState({accounttypevalue: event.target.value}); 
}



  change () {

  
if (this.state.prov == true)
return (

    <Row>
    
    <Col sm="4">
                <label htmlFor="provision" className="col-form-label">Select Provision Ledger</label>
              </Col>
      <Col lg="6">  
        <AvField name="provledger"  value={this.state.formData.membershipType} errorMessage="Select Provision Ledger" validate={{ required: { value: true } }} type="select" className="form-select" >
          <option value={-1} defaultValue label={"Select Provision ledger"} />
          {this.state.membershipType
            ? this.state.membershipType.map(
                (option, key) => (
                  <option key={option.Id} value={option.Id} label={option.Name} />
                )
              )
            : null}
        </AvField>
      </Col> 
      </Row>
)


else 
return null;
  
   

  }

  render() {


const Provision  =  this.change();
const LoanType  =  this.AccountChange(); 


    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Scheme Master</h4>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Information</h4>
                             
                              <Row>

                              <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Account Type</label>
                                        </Col>
                                <Col lg="6">

                                <AvField name="accounttypevalue"  onChange={this.AccountValueChange} value={this.state.formData.accounttypevalue} errorMessage="Select Account Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select"} />
                                    {this.state.accounttype
                                      ? this.state.accounttype.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                 
                                </Col>
</Row>


<Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Scheme Name</label>
                                        </Col>
                                <Col lg="6">


                                <AvField name="schemeName"  value={this.state.formData.schemeName} placeholder="Enter Scheme Name" errorMessage="Enter Scheme Name" validate={{ required: { value: true } }} type="text" className="form-control" />
                                  </Col>


</Row>

                               

                                <Row>
                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                          <Row>
                                            <Col sm="4">
                                              <label htmlFor="fixedterm" className="col-form-label">Fixedterm</label>
                                            </Col>
                                            <Col lg="2">
                                              <AvField name="Fixedterm"  value={this.state.formData.FirstName}   type="checkbox" className="form-control" />                                
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>



                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                              <Row>
                                                <Col sm="4">
                                                     <label htmlFor="interestbased" className="col-form-label">Intrest Based </label>
                                                </Col>
                                                <Col lg="2">
                                                       <AvField name="IntrestBased " value={this.state.formData.FirstName}   type="checkbox" className="form-control" />                                
                                                </Col>
                                              </Row>
                                          </CardBody>
                                      </Card>
                                    </Col>


                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                              <Row>
                                                <Col sm="4">
                                                     <label htmlFor="prematurity" className="col-form-label">Prematurity Allowed </label>
                                                </Col>
                                                <Col lg="2">
                                                       <AvField name="PrematurityAllowed " value={this.state.formData.FirstName}   type="checkbox" className="form-control" />                                
                                                </Col>
                                              </Row>
                                          </CardBody>
                                      </Card>
                                    </Col>



                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                              <Row>
                                                <Col sm="4">
                                                     <label htmlFor="paymentallowed" className="col-form-label">Payment Allowed</label>
                                                </Col>
                                                <Col lg="2">
                                                       <AvField name="PaymentAllowed " value={this.state.formData.FirstName}   type="checkbox" className="form-control" />                                
                                                </Col>
                                              </Row>
                                          </CardBody>
                                      </Card>
                                    </Col>


                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                              <Row>
                                                <Col sm="4">
                                                     <label htmlFor="intvar" className="col-form-label">Interest Variable(Intrest recalculate on maturity)</label>
                                                </Col>
                                                <Col lg="2">
                                                       <AvField name="IntrestVariable" checked={this.state.formData.IntrestVariable}   type="checkbox" className="form-control" />                                
                                                </Col>
                                              </Row>
                                          </CardBody>
                                      </Card>
                                    </Col>


                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                              <Row>
                                                <Col sm="4">
                                                     <label htmlFor="membershipType" className="col-form-label">Provision</label>
                                                </Col>
                                                <Col lg="2">
                                                       <AvField name="Provision" onChange={this.onChange} value={this.state.formData.FirstName}   type="checkbox" className="form-control" />                                
                                                </Col>
                                              </Row>
                                          </CardBody>
                                      </Card>
                                    </Col>






                                </Row>





                                <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Select Periodocity</label>
                                        </Col>
                                <Col lg="6">
                                  <AvField name="periodicity"  value={this.state.formData.periodicity} errorMessage="Select Periodicity" validate={{ required: { value: false } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select  Periodocity"} />
                                    {this.state.periodicity
                                      ? this.state.periodicity.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                </Col> 

                                </Row>


                                 {/* <Col lg="12">
                               

                                    <AvField type="checkbox" name="avFieldCheckbox" label="Fixed Term " required />
                                    <AvField type="checkbox" name="avFieldCheckbox" label="Intrest Based " required />
                                    <AvField type="checkbox" name="avFieldCheckbox" label="Prematurity Allowed" required />
                                    <AvField type="checkbox" name="avFieldCheckbox" label="Payment Allowed " required />
                                    <AvField type="checkbox" name="avFieldCheckbox" label="Intrest Variable(Intrest recalculate on maturity) " required />
                                    <AvField type="checkbox" name="avFieldCheckbox" label="Provision" required />
                                </Col> */}

                              <Row>


                               



                              <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Select EMI Type </label>
                                        </Col>
                                <Col lg="6">  
                                  <AvField name="emitype"  value={this.state.formData.emitype} errorMessage="Select EMI Type" validate={{ required: { value: false } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select EMI Type"} />
                                    {this.state.emitype
                                      ? this.state.emitype.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                </Col> 
                                


                                </Row>




<Row>
                                
                              <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Select Interest Ledger</label>
                                        </Col>
                                <Col lg="6">  
                                  <AvField name="intledger"  value={this.state.formData.membershipType} errorMessage="Select Interest Ledger" validate={{ required: { value: false } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select Interest ledger"} />
                                    {this.state.membershipType
                                      ? this.state.membershipType.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                </Col> 
                                


                                </Row>






                                {Provision}


                                  {LoanType}




                                
                           

                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Min. Amount </label>
                                        </Col>



                                <Col lg="6">
                                  <AvField name="minAmount"  value={this.state.formData.LastName} placeholder="Enter Min Amount" errorMessage="Enter Min Amount" validate={{ required: { value: false } }} type="text" className="form-control" />
                                </Col>



                                </Row>


                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Max. Amount </label>
                                        </Col>



                                        <Col lg="6">
                                  <AvField name="maxAmount"  value={this.state.formData.MaidenName} placeholder="Enter Max Amount" errorMessage="Enter Max Amount" validate={{ required: { value: false } }} type="text" className="form-control" />
                                </Col>



                                </Row>



                                
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Multiple Amount </label>
                                        </Col>



                                        <Col lg="6">
                                  <AvField name="multipleAmount"  value={this.state.formData.MaidenName} placeholder="Enter Multiple Amount" errorMessage="Enter Multiple Amount" validate={{ required: { value: false } }} type="text" className="form-control" />
                                </Col>


                                </Row>



                                  
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">No of Installments </label>
                                        </Col>



                                        <Col lg="6">
                                  <AvField name="noInstallments"  value={this.state.formData.MaidenName} placeholder="Enter No of Installments" errorMessage="Enter No of Installments" validate={{ required: { value: false } }} type="text" className="form-control" />
                                </Col>

                                </Row>


                                




                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Min Member Value</label>
                                        </Col>


                                        <Col lg="6">
                                  <AvField name="minMemberValue"  value={this.state.formData.MaidenName} placeholder="Enter Min Member Value" errorMessage="Enter Min Member Value" validate={{ required: { value: false } }} type="text" className="form-control" />
                                </Col>

                                </Row>



                                
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Max Member Value</label>
                                        </Col>


                                        <Col lg="6">
                                  <AvField name="maxMemberValue"  value={this.state.formData.MaidenName} placeholder="Enter Max Member Value" errorMessage="Enter Max Member Value" validate={{ required: { value: false } }} type="text" className="form-control" />
                                </Col>

                                </Row>





                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Submit
                            </Button>{" "}
                            <Button type="reset" color="secondary">
                              Cancel
                            </Button>
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_SchemeMaster);
