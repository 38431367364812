import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPageMem from "../../components/Common/RCDisplayPageMem";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData
  
} from "../../store/functions";
import {Link} from 'react-router-dom';
import { UncontrolledTooltip } from "reactstrap";
import { API_HELPER } from "helpers/ApiHelper";

class pageList_MembershipModify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true,
      isEdit : false,
      gridDatamemberlog :[],
      isDelete : false ,
      roleid : 0,
      confirm_alert_delete : false
     
    };
    this.obj = this;
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Members";
    this.modalTitle = "Memberships";
    this.rtPage_Add = "add-membership";
    this.rtPage_Edit2  =  "edit-entity-membership"
    this.rtPage_Edit = "/masters-membershipmodify";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Member";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.btnViewlog_onClick  =  this.btnViewlog_onClick.bind(this);
    this.printInvoice =  this.printInvoice.bind(this);
    this.searchmember  =  this.searchmember.bind(this);
    this.confirmdelete  =  this.confirmdelete.bind(this);
    this.checkpassword  = this.checkpassword.bind(this);
    this.proceed  = this.proceed.bind(this);
  }


 
  componentDidMount() {

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Members")
    const obj = JSON.parse(localStorage.getItem("authUser"));


    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role  , roleid:obj.roleid });

    if (obj.roleid == 4) {
      this.setState({ isEdit: false, isDelete : false });
    }

    else {
      this.setState({ isEdit: false, isDelete : false });
    }


    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id  });

      Fn_FillListData(this.obj, "gridDatamemberlog", API_WEB_URLS.MASTER + "/0/token/MemberLog" + "/Id/"+id);
    }

    else {

    Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/1");
    Fn_FillListData(this.obj, "gridDatanominal", this.API_URL + "/Id/2");
    // Fn_FillListData(this.obj, "gridDatanon", this.API_URL + "/Id/4");
    Fn_FillListData(this.obj, "gridDataentity", this.API_URL + "/Id/5");
    Fn_FillListData(this.obj, "gridDatagratis", this.API_URL + "/Id/6");
    Fn_FillListData(this.obj, "gridDataprovisional", this.API_URL + "/Id/8");
    }
    
  }

  checkpassword (formData) {
    var password  =  this.state.Password;
    
    if(password  ==  'Bulaiza@123'){

      
      this.setState({confirm_alert_delete : false});
     
      Fn_DeleteData(this.obj, this.state.selectedFormData.Id, this.API_URL, this.API_URL + "/Id/0");
    }

    else {
      alert('Invalid Password');
    }
  }


  print = () => {                                                                                                                                                      
    window.print();
  };
  searchmember (event) {
    Fn_FillListData(this.obj, "gridDatamember", "SearchMembership/0/token/"+event.target.value+"?F_MembershipTypeMaster=1");
    Fn_FillListData(this.obj, "gridDatanominal", "SearchMembership/0/token/"+event.target.value+"?F_MembershipTypeMaster=2");
  }
  confirmdelete (formData) {
    this.setState({confirm_alert_delete : !this.state.confirm_alert_delete, password : '', selectedFormData : formData});
  }

  printInvoice (formData)  {
    this.props.history.push(`/membersynopsisprint/${formData.Id}`, {});
  };

  btnViewlog_onClick (formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }

  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }



  btnEdit_onClick(formData) {

    


    if (formData.MembershipTypeId == 5){
      this.props.history.push(`${this.rtPage_Edit2}/${formData.Id}`, {});
    }
    else {
      this.props.history.push(`/edit-membership/${formData.Id}`, {});
    }
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        {/* <th>Membership Type</th> */}
        <th>MEMBERSHIP NO.</th>
        
        <th>MEMBERS NAME</th>
        <th>SEX</th>
        {/* <th>ADDRESS</th> */}
        <th>PHONE</th>
        <th>AMC</th>
        {/* <th>Move to Archieve</th> */}

      </>
    );
  }

  renderGridHeaderlog() {
    return (
      <>
        <th>Modifications Date</th>
        <th>Membership Type</th>
        <th>MEMBERSHIP NO.</th>
        
        <th>MEMBERS NAME</th>
        <th>DOB</th>
        <th>Marital Status</th>
        <th>Gender</th>
        <th>Country</th>
        <th>Hometown</th>
        <th>Profession</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Name of Spouse</th>
        <th>Residential Address</th>
        <th>Business Address</th>
        <th>Father Name</th>
        <th>Mother Name</th>
        <th>Nominee Name</th>
      </>
    );
  }


  proceed  = async(Id)=> {


    this.props.history.push(`/amc/${Id}`, {});
    
    //  let vformData = new FormData();
    // vformData.append("Id", Id);


    // let AuthUser2 = function() {
    //   return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"MoveToArchieve/0/token/" , vformData).then(token => { return token } )
    // }

  
    // let userToken2 = await AuthUser2();

    // if (userToken2.data.response[0].Id > 0){
    //   alert('Member Moved to archieve');
    // }

    // else {
    //   alert(userToken2.data.response[0].Message);
    // }


    
    // Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/1");
    // Fn_FillListData(this.obj, "gridDatanominal", this.API_URL + "/Id/2");
    // // Fn_FillListData(this.obj, "gridDatanon", this.API_URL + "/Id/4");
    // Fn_FillListData(this.obj, "gridDataentity", this.API_URL + "/Id/5");
    // Fn_FillListData(this.obj, "gridDatagratis", this.API_URL + "/Id/6");
    // Fn_FillListData(this.obj, "gridDataprovisional", this.API_URL + "/Id/8");


  }



  renderGridBody(formData) {

   
var Gender  =  formData.GenderId;

if (Gender  == 2) {
  Gender  =  "Female";
}
else if (Gender  == 1) {
  Gender  =  "Male"
}
else if (Gender  == 4){
  Gender  =  "Entity"
}

    return (
      <>
        {/* <td>{formData.MembershipType}</td> */}
        <td>{formData.MembershipNo}</td>
        <td>{formData.Name}</td>
        <td>{Gender}</td>
        <td>{formData.PhoneNo1_Residential}</td>
        <td>
        <Link to="#"  className="mr-3 text-primary"  onClick={()=>this.proceed(formData.Id)}>
                                                                        <i className="mdi mdi-eye font-size-18 mr-3" id="AMC"></i>
                                                                        <UncontrolledTooltip placement="top" target="AMC">
                                                                           AMC
                                                                        </UncontrolledTooltip >
                                                                    </Link>
        </td>
      
        {/* <td></td>
        <td></td> */}
      </>
    );
  }






  
  renderGridLog(formData) {

   
    var Gender  =  formData.GenderId;
    
    if (Gender  == 2) {
      Gender  =  "Female";
    }
    else if (Gender  == 1) {
      Gender  =  "Male"
    }
    else if (Gender  == 4){
      Gender  =  "Entity"
    }
    
        return (
          <>
            
            <td>{formData.DateOfCreation}</td>
            <td>{formData.MembershipType}</td>
            <td>{formData.MembershipNo}</td>
            <td>{formData.MemberName}</td>
            <td>{formData.DateOfBirth}</td>
            <td>{formData.MaritalStatus}</td>
            <td>{formData.Gender}</td>
            <td>{formData.Country}</td>
            <td>{formData.HomeTown}</td>
            <td>{formData.Profession}</td>
            <td>{formData.Email}</td>
            <td>{formData.MobileNo_SMS}</td>
            <td>{formData.SpouseName}</td>
            <td>{formData.Address_Residential}</td>
            <td>{formData.Address_Business}</td>
            <td>{formData.FatherName}</td>
            <td>{formData.MotherName}</td>
            <td>{formData.NomineeName}</td>
           
           
          </>
        );
      }


  



  renderModalBody(selectedFormData) {
 
   
  }
  render() {
    return (
      <>
{this.state.id   ? 

<div className="page-content" style={{marginTop : 150}}>



<RCDisplayPageMem
  //page header paramaters
  Isbreadcrumb = {true}
  breadCrumbTitle={"Member Log"}
  breadcrumbItem={"Member Log"}
  obj={this.obj}
  //column paramaters
  isSearchBox={false}
  isSNo={true}
  isCheckBox={false}
  isViewDetails={false}
  isProgress = {this.state.isProgress}
  //grid paramaters
  gridData={this.state.gridDatamemberlog}
  gridHeader={this.renderGridHeaderlog}
  gridBody={this.renderGridLog}
  btnAdd_onClick={this.btnAdd_onClick}
  btnEdit_onClick={this.btnEdit_onClick}
  printInvoice = {this.printInvoice}
  //delete link parameters
  confirm_alert={this.state.confirm_alert}
  success_dlg={this.state.success_dlg}
  dynamic_title={this.state.dynamic_title}
  dynamic_description={this.state.dynamic_description}
  toggleDeleteConfirm={toggleDeleteConfirm}
  toggleDeleteSuccess={toggleDeleteSuccess}
  btnDelete_onClick={this.btnDelete_onClick}
  searchmember = {this.searchmember}
  //modal paramaters
  isOpenModal={this.state.modal}
  modalTitle={this.modalTitle}
  selectedFormData={this.state.selectedFormData}
  modalBody={this.renderModalBody}
  togglemodal={togglemodal}
  confirmdelete = {this.confirmdelete}
  btnViewlog_onClick={this.btnViewlog_onClick}
  checkpassword={this.checkpassword}
  proceed=  {this.proceed}
  //user rights
  isAdd={false}
  isEdit={false}
  isDelete={false}
  isViewModify={false}
  isProcess={false}
  isTwoFactorDelete = {false}
  confirm_alert_delete =  {this.state.confirm_alert_delete}
></RCDisplayPageMem>
</div>

: 
<>

      <div className="page-content" style={{marginTop : 200}}>

      <RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"Provisional Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDataprovisional}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        checkpassword={this.checkpassword}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        btnViewlog_onClick={this.btnViewlog_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        confirmdelete = {this.confirmdelete}
        proceed=  {this.proceed}
        //user rights
        isAdd={false}
        isEdit={true}
        isDelete={false}
        isViewModify={true}
        isProcess={false}
        isTwoFactorDelete = {true}
        confirm_alert_delete =  {this.state.confirm_alert_delete}
      ></RCDisplayPageMem>


      <RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={true}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatamember}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        searchmember = {this.searchmember}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        btnViewlog_onClick={this.btnViewlog_onClick}
        togglemodal={togglemodal}
        confirmdelete = {this.confirmdelete}
        checkpassword={this.checkpassword}
        proceed=  {this.proceed}
        //user rights
        isAdd={false}
        isEdit={true}
        isDelete={false}
        isViewModify={true}
        isProcess={false}
        isTwoFactorDelete = {true}
        confirm_alert_delete =  {this.state.confirm_alert_delete}
      ></RCDisplayPageMem>
</div>

<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"Nominal Members"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatanominal}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        checkpassword={this.checkpassword}
        proceed=  {this.proceed}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        btnViewlog_onClick={this.btnViewlog_onClick}
        togglemodal={togglemodal}
        confirmdelete = {this.confirmdelete}
        //user rights
        isAdd={false}
        isEdit={true}
        isDelete={false}
        isViewModify={true}
        isProcess={false}
        isTwoFactorDelete = {true}
        confirm_alert_delete =  {this.state.confirm_alert_delete}
      ></RCDisplayPageMem>



<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"Gratis Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatagratis}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        checkpassword={this.checkpassword}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        btnViewlog_onClick={this.btnViewlog_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        confirmdelete = {this.confirmdelete}
        proceed=  {this.proceed}
        //user rights
        isAdd={false}
        isEdit={true}
        isDelete={false}
        isViewModify={true}
        isProcess={false}
        isTwoFactorDelete = {true}
        confirm_alert_delete =  {this.state.confirm_alert_delete}
      ></RCDisplayPageMem>



<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"Entity Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDataentity}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        checkpassword={this.checkpassword}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        btnViewlog_onClick={this.btnViewlog_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        confirmdelete = {this.confirmdelete}
        proceed=  {this.proceed}
        //user rights
        isAdd={false}
        isEdit={true}
        isDelete={false}
        isViewModify={true}
        isProcess={false}
        isTwoFactorDelete = {true}
        confirm_alert_delete =  {this.state.confirm_alert_delete}
      ></RCDisplayPageMem>





</>

}


</>







      
    );
  }
}
export default compose(container)(pageList_MembershipModify);
