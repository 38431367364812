import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData , Fn_FillListData } from "../../store/functions";

class pageAddEdit_MembersAccountMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      activeTabVartical: 1,
      passedSteps: [1],
      passedStepsVertical: [1],
      name : "",
      email : "",
      aid : 0
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Membership Account Master";
    this.breadCrumbTitle = "Memberships Account Master";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership_Account_Master";
    this.pushFormName = "/memberships";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
  }
  componentDidMount() {


    
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });

//dropdown

Fn_FillListData(this.obj, "accounttype", API_WEB_URLS.MASTER + "/0/token/AccountType/Id/0");
Fn_FillListData(this.obj, "accounttypescheme", API_WEB_URLS.MASTER + "/0/token/AccountTypeSchemes/Id/0");





    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {
    if (!this.state.id)
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, name: formData.Name } },
        this.API_URL,
        this.pushFormName
      );
    else
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, name: formData.Name } },
        this.API_URL,
        this.pushFormName
      );
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  render() {
    const options = [
      { value: "AK", label: "Alaska" },
      { value: "HI", label: "Hawaii" },
      { value: "CA", label: "California" },
      { value: "NV", label: "Nevada" },
      { value: "OR", label: "Oregon" },
      { value: "WA", label: "Washington" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Account Details</h4>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                            >
                              <span className="number">1.</span> Account Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 2,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(2)
                              }
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.toggleTab(2);
                              }}
                            >
                              <span className="number">2.</span>{" "}
                              <span>Member/Advisor Details</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 3,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(3)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 3,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(3);
                              }}
                            >
                              <span className="number">3.</span> Form / Amount
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 4,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(4)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 4,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(4);
                              }}
                            >
                              <span className="number">4.</span> Confirm Detail
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                      <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Information</h4>
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Account Type</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MembershipTypeId" label="" value={this.state.formData.AccountTypeId} disabled errorMessage="Select Membership Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.accounttype
                                              ? this.state.accounttype.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Account Type Scheme</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MembershipTypeId" label="" value={this.state.formData.AccountTypeSchemeId} disabled errorMessage="Select Membership Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.accounttypescheme
                                              ? this.state.accounttypescheme.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="dateOfJoining" className="col-form-label">Opening Date</label>
                                        </Col>
                                        <Col sm="4" className="mb-0">
                                          <AvField name="DateOfJoining" label="" value={this.state.formData.DateOfJoining === null ? ''   : this.state.formData.DateOfJoining} placeholder="Enter Date of Joining" disabled errorMessage="Enter Date of Joining" validate={{ required: { value: true } }} type="date"  className="form-control" />
                                        </Col>  
                                      </Row>


                                      {/* <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Select Member</label>
                                        </Col>
                                        <Col sm="3">
                                        <AvField name="Search" label="" value={this.state.formData.SurName === null ? ''   : this.state.formData.SurName} placeholder="Search Mem." errorMessage="Enter Last Name" validate={{ required: { value: true } }} type="text" className="form-control" />
                                        </Col>
                                        <Col sm="1" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Select</label>
                                        </Col>
                                        <Col sm="4">
                                        <AvField name="MembershipTypeId" label="" value={this.state.formData.MembershipTypeId} errorMessage="Select Member" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.membershipType
                                              ? this.state.membershipType.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row> */}
<Row>
                                       
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="middleName" className="col-form-label">A/C Holder Name</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MiddleName" label="" value={this.state.formData.MemberName === null ? ''   : this.state.formData.MemberName} placeholder="Enter A/c holder Name" disabled  type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      {/* <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="lastName" className="col-form-label">Periodicity</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MembershipTypeId" label="" value={this.state.formData.MembershipTypeId} errorMessage="Select Periodiciy" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.membershipType
                                              ? this.state.membershipType.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row> */}

                                    
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Interest</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MaidenName" label="" value={this.state.formData.MaidenName === null ? ''   : this.state.formData.MaidenName} placeholder="Enter Interest" errorMessage="Enter Interest" validate={{ required: { value: true } }} type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Member A/c No.</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MaidenName" label="" value={this.state.formData.MemberAccountNo === null ? ''   : this.state.formData.MemberAccountNo} placeholder="Member A/c No."  disabled type="text" className="form-control" />
                                        </Col>
                                      </Row>

                                      {/* <Row>
                                        <Col sm="4">
                                          <label htmlFor="dateOfJoining" className="col-form-label">Maturity Date</label>
                                        </Col>
                                        <Col sm="4" className="mb-0">
                                          <AvField name="DateOfJoining" label="" value={this.state.formData.DateOfJoining === null ? ''   : this.state.formData.DateOfJoining} placeholder="Enter Date of Joining" errorMessage="Enter Date of Joining" validate={{ required: { value: true } }} type="date"  className="form-control" />
                                        </Col>  
                                      </Row> */}
                                      

                                      {/* <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maritalStatus" className="col-form-label">Interest Type</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MaritalStatusId" label="" value={this.state.formData.MaritalStatusId === null ? '-1'   : this.state.formData.MaritalStatusId} errorMessage="Select Interest Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.maritalStatus
                                              ? this.state.maritalStatus.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col> 
                                      </Row> */}
                                      {/* <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="bloodGroup" className="col-form-label">SB Account</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="BloodGroupId" label="" value={this.state.formData.BloodGroupId === null ? '-1'   : this.state.formData.BloodGroupId}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.bloodGroup
                                              ? this.state.bloodGroup.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row> */}
                                      


                                     
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="homeTown" className="col-form-label">FD Receipt</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="HomeTown" label="" value={this.state.formData.HomeTown === null ? ''   : this.state.formData.HomeTown} placeholder="Enter FD Receipt" errorMessage="Enter FD Receipt" validate={{ required: { value: true } }} type="text" className="form-control" />
                                        </Col>
                                      </Row>
                                      
                                      
                                    </CardBody>
                                  </Card>
                                  </Col>
                                </Row>
                                <Row>
                                </Row>
 
                            </TabPane>
                            <TabPane tabId={2}>
                                  <Row>
                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                          <h4 className="card-title mb-4">Member/Advisor Details</h4>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="FirstName_Father" className="col-form-label">Member ID</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="FirstName_Father" label="" value={this.state.formData.MembershipNo === null ? ''   : this.state.formData.MembershipNo}  disabled  type="text" className="form-control" />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="MiddleName_Father" className="col-form-label">DOB</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="dob" label="" value={this.state.formData.DateOfBirth === null ? ''   : this.state.formData.DateOfBirth}  disabled type="text" className="form-control" />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="SurName_Father" className="col-form-label">Contact No.</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="MobileNo_SMS" label="" value={this.state.formData.MobileNo_SMS === null ? ''   : this.state.formData.MobileNo_SMS} disabled type="text" className="form-control" />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="HomeTown_Father" className="col-form-label">Father Name</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="HomeTown_Father" label="" value={this.state.formData.FirstName_Father === null ? ''   : this.state.formData.FirstName_Father} disabled validate={{ required: { value: true } }} type="text" className="form-control" />
                                            </Col>
                                          </Row>







                                          <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentCode" className="col-form-label">Agent/Staff Code</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MemberId_Agent" label="" value={this.state.email} placeholder="Enter Agent/Staff Code" errorMessage="Enter Agent/Staff Code" validate={{ required: { value: true } }} disabled type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentName" className="col-form-label">Agent/Staff Name</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="AgentName" label="" value={this.state.name} placeholder="Enter Agent/Staff Name" errorMessage="Enter Agent/Staff Name" validate={{ required: { value: true } }} type="text" disabled className="form-control" />
                                          </Col>
                                        </Row>
                                        
                                      {/* <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Select Advisor</label>
                                        </Col>
                                        <Col sm="3">
                                        <AvField name="Search" label="" value={this.state.formData.SurName === null ? ''   : this.state.formData.SurName} placeholder="Search Adv."  type="text" className="form-control" />
                                        </Col>
                                        <Col sm="1" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Select</label>
                                        </Col>
                                        <Col sm="4">
                                        <AvField name="MembershipTypeId" label="" value={this.state.formData.MembershipTypeId} errorMessage="Select Advisor" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.membershipType
                                              ? this.state.membershipType.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row> */}
                            
                                        </CardBody>
                                      </Card>
                                    </Col>
                                   
                                  </Row>
                                  
                            </TabPane>
                            <TabPane tabId={3}>
                              <Row>
                                  <Row>
                                    <Col lg="6">
                                        <Label for="basicpill-lastname-input2">
                                          Form / Amount
                                        </Label>
                                    </Col>
                                  </Row>
                                  <Row>
                                  <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Upload Form</label>
                                        </Col>
                                    <Col lg="4">
                                    <AvField name="BrowseImage1"  placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'BrowseImage1', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />
                                    </Col>

                                    <Col lg="2">
                                    <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>
                                    </Col>
                                  </Row>

                                  <Row>
                                  <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Amount</label>
                                        </Col>
                                    <Col lg="2">
                                    <AvField name="HomeTown_Father" label="" placeholder="Enter Amount" value={this.state.formData.HomeTown_Father === null ? ''   : this.state.formData.HomeTown_Father}  validate={{ required: { value: true } }} type="text" className="form-control" />
                                    </Col>

                                    <Col lg="4">
                                    <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Click here to calculate Amount
                        </Button>
                                    </Col>
                                  </Row>
                                 

                                  <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">EMI</label>
                                        </Col>
                                        <Col sm="2">
                                          <AvField name="MaidenName" label="" value={this.state.formData.MaidenName === null ? ''   : this.state.formData.MaidenName} placeholder="Enter EMI" disabled  type="text" className="form-control" />
                                        </Col>
                                      </Row>



                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Firts EMI Date</label>
                                        </Col>
                                        <Col sm="2">
                                        <AvField name="DateOfJoining" label="" value={this.state.formData.DateOfJoining === null ? ''   : this.state.formData.DateOfJoining} placeholder="Enter Date of Joining" errorMessage="Enter Date of Joining" validate={{ required: { value: true } }} type="date"  className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Processing Charges</label>
                                        </Col>
                                        <Col sm="2">
                                        <AvField name="MaidenName" label="" value={this.state.formData.MaidenName === null ? ''   : this.state.formData.MaidenName} placeholder="Enter Processing Charges"  type="text" className="form-control" />

                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Stationery</label>
                                        </Col>
                                        <Col sm="2">
                                        <AvField name="MaidenName" label="" value={this.state.formData.MaidenName === null ? ''   : this.state.formData.MaidenName} placeholder="Enter Stationery"  type="text" className="form-control" />

                                        </Col>
                                      </Row>  


                                      
                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Total Amount</label>
                                        </Col>
                                        <Col sm="2">
                                        <AvField name="MaidenName" label="" value={this.state.formData.MaidenName === null ? ''   : this.state.formData.MaidenName} disabled  type="text" className="form-control" />

                                        </Col>
                                      </Row>  


                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Remarks</label>
                                        </Col>
                                        <Col sm="2">
                                        <AvField name="MaidenName" label="" value={this.state.formData.MaidenName === null ? ''   : this.state.formData.MaidenName} placeholder="Enter Remarks"  type="text" className="form-control" />

                                        </Col>
                                      </Row>  









                                  
                              </Row>


                              <Row>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Guarantor Information</h4>
                                       
                                      
                                        <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Select Guarantor</label>
                                        </Col>
                                        <Col sm="3">
                                        <AvField name="Search" label="" value={this.state.formData.SurName === null ? ''   : this.state.formData.SurName} placeholder="Search Gur."  type="text" className="form-control" />
                                        </Col>
                                        <Col sm="1" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Select</label>
                                        </Col>
                                        <Col sm="4">
                                        <AvField name="MembershipTypeId" label="" value={this.state.formData.MembershipTypeId} errorMessage="Select Guarantor" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.membershipType
                                              ? this.state.membershipType.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                            


                                        <Row>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Nominee Information</h4>
                                        

                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="relationship" className="col-form-label">Nominee Exists in Branch</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="RelationshipId_Introducer" label="" value={this.state.formData.RelationshipId_Introducer === null ? '-1'   : this.state.formData.RelationshipId_Introducer}  type="select" className="form-select" >
                                              <option value={-1} defaultValue label={"Select"} />
                                              {this.state.relationShip
                                                ? this.state.relationShip.map(
                                                    (option, key) => (
                                                      <option key={option.Id} value={option.Id} label={option.Name} />
                                                    )
                                                  )
                                                : null}
                                            </AvField>
                                          </Col>
                                        </Row>




                                        <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Select Nominee</label>
                                        </Col>
                                        <Col sm="3">
                                        <AvField name="Search" label="" value={this.state.formData.SurName === null ? ''   : this.state.formData.SurName} placeholder="Search Nomin."  type="text" className="form-control" />
                                        </Col>
                                        <Col sm="1" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Select</label>
                                        </Col>
                                        <Col sm="4">
                                        <AvField name="MembershipTypeId" label="" value={this.state.formData.MembershipTypeId} errorMessage="Select Nominee" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.membershipType
                                              ? this.state.membershipType.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="relationship" className="col-form-label">RelationShip</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="RelationshipId_Introducer" label="" value={this.state.formData.RelationshipId_Introducer === null ? '-1'   : this.state.formData.RelationshipId_Introducer}  type="select" className="form-select" >
                                              <option value={-1} defaultValue label={"Select"} />
                                              {this.state.relationShip
                                                ? this.state.relationShip.map(
                                                    (option, key) => (
                                                      <option key={option.Id} value={option.Id} label={option.Name} />
                                                    )
                                                  )
                                                : null}
                                            </AvField>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={4}>
                              <div className="row justify-content-center">
                                <Col lg="6">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                      <h5>Confirm Detail</h5>
                                      <p className="text-muted">
                                        If several languages coalesce, the grammar
                                        of the resulting
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>
                          <div>
                          <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button>
                          </div>
                        </AvForm>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              this.state.activeTab === 1
                                ? "previous disabled"
                                : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab - 1);
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li
                            className={
                              this.state.activeTab === 4
                                ? "next disabled"
                                : "next"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab + 1);
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_MembersAccountMaster);
