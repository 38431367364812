import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"


// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter, Link } from "react-router-dom";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData , Fn_FillListData, Fn_AddEditDataN } from "../../store/functions";

class pageAddEdit_GlobalOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
    };
    this.obj = this;
    this.formTitle = "Global Options";
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = this.formTitle;
    this.API_URL_SAVE =  "UpdateGlobalOptions/0/token";
    this.pushFormName = "/GlobalOptions";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/GlobalOptions";
    this.syno  = this.syno.bind(this);
  }
  componentDidMount() {
    

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", this.breadCrumbItem)
    Fn_DisplayData(this.obj, 0, this.API_URL + "/Id");
    
  }

  syno(){
    this.setState({success_msg : false});
  }
  btnSave_onClick(event, formData) {
   
    const obj = JSON.parse(localStorage.getItem("authUser"));

    
      Fn_AddEditDataN(
        this.obj,
        { arguList: 
          { id: 0,
            totalShares: formData.TotalShares ,
            availableShares : formData.AvailableShares ,
            minimumAmount : formData.MinimumAmount ,
            cashHandlingCommission : formData.CashHandlingCommission ,
            goldValue : formData.GoldValue ,
            weeklyWithdrawalLimit : formData.WeeklywithdrawalLimit 
              } },
        this.API_URL_SAVE,
        this.pushFormName
      );
  



  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                     
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Total Shares</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="TotalShares" label="" value={this.state.formData.TotalShares === null ? ''   : this.state.formData.TotalShares} placeholder="TotalShares "   type="number" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="middleName" className="col-form-label">Available Shares</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="AvailableShares" label="" value={this.state.formData.AvailableShares === null ? ''   : this.state.formData.AvailableShares} placeholder="AvailableShares"   type="number" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="lastName" className="col-form-label">Minimum Cash Balance</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MinimumAmount" label="" value={this.state.formData.MinimumAmount === null ? ''   : this.state.formData.MinimumAmount} placeholder="MinimumAmount"  type="number" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Cash Handling Commission</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="CashHandlingCommission" label="" value={this.state.formData.CashHandlingCommission === null ? ''   : this.state.formData.CashHandlingCommission} placeholder="Cash Handling Commission"  type="number" className="form-control" />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Gold Value</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="GoldValue" label="" value={this.state.formData.GoldValue === null ? ''   : this.state.formData.GoldValue}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Share Value</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="ShareValue" label="" value={this.state.formData.ShareValue === null ? ''   : this.state.formData.ShareValue}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MISInterest</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MISInterest" label="" value={this.state.formData.MISInterest === null ? ''   : this.state.formData.MISInterest}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MinimumMISAmount</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MinimumMISAmount" label="" value={this.state.formData.MinimumMISAmount === null ? ''   : this.state.formData.MinimumMISAmount}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">PreMatureMISCharges</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="PreMatureMISCharges" label="" value={this.state.formData.PreMatureMISCharges === null ? ''   : this.state.formData.PreMatureMISCharges}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MISWithdrawCharge</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MISWithdrawCharge" label="" value={this.state.formData.MISWithdrawCharge === null ? ''   : this.state.formData.MISWithdrawCharge}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">LoanLimitTimes</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="LoanLimitTimes" label="" value={this.state.formData.LoanLimitTimes === null ? ''   : this.state.formData.LoanLimitTimes}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MembershipFeesRemark</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MembershipFeesRemark" label="" value={this.state.formData.MembershipFeesRemark === null ? ''   : this.state.formData.MembershipFeesRemark}  validate={{ required: { value: true } }} type="text" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MinimumBalanceInShares</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MinimumBalanceInShares" label="" value={this.state.formData.MinimumBalanceInShares === null ? ''   : this.state.formData.MinimumBalanceInShares}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">SubscriptionFee</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="SubscriptionFee" label="" value={this.state.formData.SubscriptionFee === null ? ''   : this.state.formData.SubscriptionFee}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">DepositCharges</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="DepositCharges" label="" value={this.state.formData.DepositCharges === null ? ''   : this.state.formData.DepositCharges}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">ReservedPer</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="ReservedPer" label="" value={this.state.formData.ReservedPer === null ? ''   : this.state.formData.ReservedPer}  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">UniversalPass</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="UniversalPass" label="" value={this.state.formData.UniversalPass === null ? ''   : this.state.formData.UniversalPass}  validate={{ required: { value: true } }} type="text" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Weekly Withdrawal Limit</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="WeeklywithdrawalLimit" label="" value={this.state.formData.WeeklywithdrawalLimit === null ? ''   : this.state.formData.WeeklywithdrawalLimit}  validate={{ required: { value: true } }} type="text" className="form-control" />
                                        </Col>
                                      </Row>

                                      
                                  
                                    
                                    </CardBody>
                                  </Card>
                                  </Col>
                                 
                                </Row>
                                <Row>
                                </Row>
                           
                                <div className="d-flex flex-wrap gap-2">
                          <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Update
                        </Button>

                        <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                          Cancel
                        </Button>


                        {this.state.success_msg ? (
                      <SweetAlert
                        title="Updated Successfully!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        You clicked the button!
                      </SweetAlert>
                    ) : null}
                          </div>
                        </AvForm>
                      </div>
                      
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_GlobalOptions);
