import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"
import axios from "axios"
import { API_WEB_URLS } from "constants/constAPI"

const fireBaseBackend = getFirebaseBackend()

function* forgetUser({payload : {user , history}}) {
  try {
    
    let vformData = new FormData();
    //Information
    vformData.append("Email", user.email);
    const response = yield call(axios.post, API_WEB_URLS.BASE +'ForgotPassword/0/token',  vformData );
    console.log(response);
    if (response.data.data.data.id > 0) {
      yield put(
        userForgetPasswordSuccess(
          "Reset OTP has been sent to your e-mail box."
        )
      );
      
      history.push(`/OTP/${response.data.data.data.id}`, {});
    }
     else {
      // Handle error case
      yield put(userForgetPasswordError("Account not found with this email."));
    }
  } catch (error) {
    // Handle network errors or other issues
    console.log(error);
    yield put(userForgetPasswordError("Network error or server issue."));
  }

}


function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

export default forgetPasswordSaga
