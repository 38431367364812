import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
} from "../../store/functions";
import {Link} from 'react-router-dom';
import { UncontrolledTooltip } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

class pageList_PersonToPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alerts: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true
    };
    this.obj = this;
    this.breadCrumbTitle = "Person-to-Person Requests";
    this.breadCrumbItem = "Person-to-Person Requests";
    this.modalTitle = "Person-to-Person Requests";
    this.rtPage_Add = "add-person-to-person";
    this.rtPage_Edit = "edit_schemeinterestmaster";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/PersonToPerson";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.process  =  this.process.bind(this);
    this.Accept  =  this.Accept.bind(this);
    this.Reject  =  this.Reject.bind(this);
  }
  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem)
    Fn_FillListData(this.obj, "gridData", this.API_URL + "/Id/0");
  }
  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }

  Accept = async() =>{
    this.setState({confirm_alerts: false});

    let vformData = new FormData();
        
    vformData.append("Id", this.state.MainId);


    let AuthUser2 = function() {
      return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"AcceptPTP/0/token" , vformData).then(token => { return token } )
    }


    let userToken2 = await AuthUser2();
    if (userToken2.status == 200 || userToken2.status == '200'){
      Fn_FillListData(this.obj, "gridData", this.API_URL + "/Id/0");
      if(userToken2.data.data[0].Id  == -1){
        alert('Insufficient Balance!')
      }
      else if(userToken2.data.data[0].Id>0) {
          alert('Approved!');
      }
      else {
        alert('Failed to get User Information! \n Please try again later.');
      }

    }
    else {
      alert('Some Error Ocurred!');
    }
  }

  Reject  =  async () =>{
    this.setState({confirm_alerts: false});

    let vformData = new FormData();
        
    vformData.append("Id", this.state.MainId);


    let AuthUser2 = function() {
      return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"RejectPTP/0/token" , vformData).then(token => { return token } )
    }


    let userToken2 = await AuthUser2();
    if (userToken2.status == 200 || userToken2.status == '200'){
      Fn_FillListData(this.obj, "gridData", this.API_URL + "/Id/0");
    alert('Rejected');
    }
    else {
      alert('Some Error Occured!\n Please Try Again Later.\n If the problem persists');
      
    }





  }


  process (Id){
    this.setState({confirm_alerts:true , MainId : Id})
  }
  renderGridHeader() {
    return (
      <>
      
        <th>Date</th>
        <th>GIN</th>
        <th>Name</th>
        <th>Transfer to GIN</th>
        <th>Transfer to NAME</th>
        <th>Amount</th>
        <th>Reference</th>
        <th>Status</th>
        <th>Approve/Decline</th>
        {/* <th>Country Code</th>
        <th>ISD Code</th> */}
      </>
    );
  }
  renderGridBody(formData) {
    return (
      <>
        <td>{formData.DateOfCreation}</td>
        <td>{formData.GIN}</td>
        <td>{formData.Name}</td>
        <td>{formData.RGIN}</td>
        <td>{formData.RName}</td>
        <td>{formData.Amount}</td>
        <td>{formData.Reference}</td>
        <td style={{color : formData.IsProcessed ? 'green' : 'red'}}> {formData.Status}</td>
        <td> {formData.IsProcessed ? null : <Link to="#"  className="mr-3 text-primary"  onClick={()=>this.process(formData.Id)}>
                                                                        <i className="mdi mdi-check-decagram font-size-18 mr-3" id="edittooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                                                            Process
                                                                        </UncontrolledTooltip >
                                                                    </Link> }</td>

        {/* <td></td>
        <td></td> */}
      </>
    );
  }
  renderModalBody(selectedFormData) {
    return (
      <>
        <p className="mb-4">
        SenderTransactionAcNo: <span className="text-primary">{selectedFormData.SenderTransactionAcNo}</span>
        </p>
        <p className="mb-4">
        SenderName: <span className="text-primary">{selectedFormData.SenderName}</span>
        </p>
        <p className="mb-4">
        RecipientTransactionAcNo: <span className="text-primary">{selectedFormData.RecipentTransactionAcNo}</span>
        </p>
        <p className="mb-4">
        RecipientName: <span className="text-primary">{selectedFormData.RecipentName}</span>
        </p>
        <p className="mb-4">
        Amount: <span className="text-primary">{selectedFormData.Amount}</span>
        </p>
        <p className="mb-4">
        Reference: <span className="text-primary">{selectedFormData.Reference}</span>
        </p>
        <p className="mb-4">
        Status: <span className="text-primary">{selectedFormData.Status}</span>
        </p>
      </>
    );
  }
  render() {
    return (

      <div className="page-content">
      <RCDisplayPage
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={true}
        isSNo={true}
        isCheckBox={false}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        process={this.process}
        Accept  =  {this.Accept}
        Reject   = {this.Reject}
        //user rights
        isAdd={true}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPage>



{this.state.confirm_alerts ? (
                                                                <SweetAlert
                                                                    title="Are you sure?"
                                                                    warning
                                                                    showCancel
                                                                    confirmBtnText="Accept"
                                                                    confirmBtnBsStyle="success"
                                                                    cancelBtnBsStyle="danger"
                                                                    cancelBtnText="Reject"
                                                                    onConfirm={() =>this.Accept()}
                                                                    onCancel={() =>this.Reject()}
                                                                >
                                                                    You won`t be able to revert this!
                                                                </SweetAlert>
                                                                ) : null}
      </div>
    );
  }
}
export default compose(container)(pageList_PersonToPerson);
