import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPageMem from "../../components/Common/RCDisplayPageMem";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData
  
} from "../../store/functions";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

class pageList_NonMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true,
      isEdit : false,
      isDelete : false ,
      roleid : 0
     
    };
    this.obj = this;
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Non-Members";
    this.modalTitle = "Memberships";
    this.rtPage_Add = "add-membership";
    this.rtPage_Edit = "/edit-membership";
    
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/GetNonMembers";

    //Event Binding
   
    this.printInvoice =  this.printInvoice.bind(this);
  }


  printInvoice (formData) {
    this.props.history.push(`/edit-memberapprove/${formData.Id}/2`, {});
  };


 
  componentDidMount() {

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", this.breadCrumbItem)

    const obj = JSON.parse(localStorage.getItem("authUser"));

    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role  , roleid:obj.roleid });

    Fn_FillListData(this.obj, "gridData", this.API_URL + "/Id/0");
   
    
  }



 


  btnEdit_onClick(formData) {
   this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        {/* <th>Membership Type</th> */}
        <th>MEMBERSHIP NO.</th>
        <th>MEMBERS NAME</th>
        <th>SEX</th>
        {/* <th>ADDRESS</th> */}
        <th>PHONE</th>
        <th>STATUS</th>
        {/* <th>EMERGENCY CONTACT</th> */}
         <th>Action</th>
       
      </>
    );
  }



  renderGridBody(formData) {

    var Gender  =  formData.GenderId;

    if (Gender  == 2) {
      Gender  =  "Female";
    }
    else {
      Gender  =  "Male"
    }
    


    return (
      <>
        {/* <td>{formData.MembershipType}</td> */}
        <td>{formData.MembershipNo}</td>
       
        <td>{formData.Name + ' ' + formData.MidName  + ' ' + formData.SurName} </td>
        <td>{Gender}</td>
        {/* <td>{formData.Address_Residential}</td> */}
        <td>{formData.PhoneNo1_Residential}</td>
        <td>{formData.IsPaid ? 'ACTIVATED' : 'PENDING'}</td>
        
        {/* <td>{formData.PhoneNo1_Nominee}</td> */}
      
      </>
    );
  }



  renderModalBody(selectedFormData) {
 
     
  

    return (
      <>
     
      </>
    );
  }
  render() {
    return (
      <>
  <div className="page-content">
<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"UnApproved Members"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isProcess = {true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        //delete link parameters`
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPageMem>
</div>
</>







      
    );
  }
}
export default compose(container)(pageList_NonMember);
