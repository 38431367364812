import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_AddEditDataN } from "../../store/functions";
import { bind } from "lodash";
import { findAllByDisplayValue } from "@testing-library/react";




class pageAddEdit_WithdrawLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      Accountdetail: [
        {
          Name: ""
        }
      ],
      RecipentDetail: [
        {
         
        }
      ],
      prov: false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype: [],
      SAccountType: 0,
      RecipentAccountType: -1
    };



    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "";
    this.breadCrumbTitle = "Account To Account Transfer";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.AccountToAccountRequest + "/0/token";
    this.pushFormName = "/accounttoaccount";


    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.setRAccountType = this.setRAccountType.bind(this);
  }




  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem)
    //Filling DropDowns
    Fn_FillListData(this.obj, "accounttype", API_WEB_URLS.MASTER + "/0/token/AccountType/Id/0");




    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {




    let vformData = new FormData();

    vformData.append("SenderAccountType", 11);
    vformData.append("RecipentAccountType", this.state.RecipentAccountType);
    vformData.append("SenderTransactionAcNo", formData.senderaccountno);
    vformData.append("SenderName", this.state.Accountdetail[0].Name);
    vformData.append("RecipientTransactionAcNo", formData.recipentaccountno);
    vformData.append("RecipientName", this.state.RecipentDetail[0].Name);
    vformData.append("TransactionAmount", formData.transactionamount);



    if (!this.state.id)
      Fn_AddEditDataN(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true);
    else
    Fn_AddEditDataN(
        this.obj,
        { arguList: { id: this.state.id, name: formData.Name } },
        this.API_URL,
        this.pushFormName
      );
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetAccountDetail = (event) => {
    var value = event.target.value;

    Fn_FillListData(this.obj, "Accountdetail", API_WEB_URLS.MASTER + "/0/token/GetBalance/1/Mem/Balance?ListFor=" + value + "&F_AccountType=11");
  }


  // SetSenderAccountType =(event)=> {



  //   var value  =  event.target.value;

  //   this.setState({SAccountType: value});



  // }


  GetAccountDetail2 = (event) => {
    var value = event.target.value;

    Fn_FillListData(this.obj, "RecipentDetail", API_WEB_URLS.MASTER + "/0/token/GetBalance/1/Mem/Balance?ListFor=" + value + "&F_AccountType=" + this.state.RecipentAccountType);
  }


  setRAccountType(event) {
    this.setState({
      RecipentAccountType: event.target.value
    });
  }







  render() {



    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Set Withdraw Limit</h4>
                    <div className="wizard clearfix">
                      <div className="content clearfix">

                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>

                          <Row>
                            <Col lg="6">
                              <Card>
                                <CardBody>
                                  <h4 className="card-title mb-4">Account Information</h4>


                                  <Row>
                                    <Col sm="4">
                                      <label htmlFor="membershipType" className="col-form-label">Account Type</label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField name="SenderAccountType" label="" value={this.state.formData.SenderAccountType}   type="select" className="form-select" disabled>
                                        <option value={11} defaultValue label={"Transaction Account"} />
                                      </AvField>
                                    </Col>
                                  </Row>
                                  <Row>

                                    <Col sm="4">
                                      <label htmlFor="membershipType" className="col-form-label">Account No</label>
                                    </Col>
                                    <Col lg="6">


                                      <AvField name="senderaccountno" onChange={this.GetAccountDetail} value={this.state.senderaccountno} placeholder="Account No." errorMessage="Enter Account No." type="text" className="form-control" />
                                    </Col>
                                  </Row>



                                  <Row>

                                    <Col sm="4">
                                      <label htmlFor="membershipType" className="col-form-label">A/C Holder Name</label>
                                    </Col>
                                    <Col lg="6">

                                      <AvField name="accountholdername" value={this.state.Accountdetail[0].Name} placeholder="Account Holder Name" disabled type="text" className="form-control" />

                                    </Col>
                                  </Row>


                                  <Row>

                                    <Col sm="4">
                                      <label htmlFor="membershipType" className="col-form-label">A/C Balance</label>
                                    </Col>
                                    <Col lg="6">


                                      <AvField name="accountbalance" value={this.state.Accountdetail[0].AccountBalance} placeholder="A/C Balance" disabled type="text" className="form-control" />
                                    </Col>


                                  </Row>
                                  <Row>

<Col sm="4">
  <label htmlFor="membershipType" className="col-form-label">Limit</label>
</Col>
<Col lg="6">


  <AvField name="Limit" value={this.state.formData.Limit} placeholder="Transaction Limit" errorMessage=" Enter Limit" type="number" className="form-control" />
</Col>


</Row>



                                </CardBody>
                              </Card>
                            </Col>

                          </Row>
              

                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_WithdrawLimit);
