import React, {lazy  , Suspense} from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Masters
import pageList_BloodGroup from "../pages/Masters/pageList_BloodGroup";
import pageList_Country from "../pages/Masters/pageList_Country";
import pageList_Designation from "../pages/Masters/pageList_Designation";
import pageList_Education from "../pages/Masters/pageList_Education";
import pageList_EntityType from "../pages/Masters/pageList_EntityType";
import pageList_Gender from "../pages/Masters/pageList_Gender";
import pageList_IDDocumentType from "../pages/Masters/pageList_IDDocumentType";
import pageList_MaritalStatus from "../pages/Masters/pageList_MaritalStatus";
import pageList_MembershipType from "../pages/Masters/pageList_MembershipType";
import pageList_Profession from "../pages/Masters/pageList_Profession";
import pageList_RegistrationDocumentType from "../pages/Masters/pageList_RegistrationDocumentType";
import pageList_Relationship from "../pages/Masters/pageList_Relationship";
//Membership
import pageList_Membership from "../pages/Memberships/pageList_Membership";
import pageList_EntityMembership from "../pages/Memberships/pageList_EntityMembership";
//Transaction
import pageList_SchemeMaster from "../pages/Transaction/pageList_SchemeMaster"
import pageList_SchemeInterest from "../pages/Transaction/pageList_SchemeInterest"
import pageList_SponsorInformation from "../pages/Transaction/pageList_SponsorInformation"

import pageAddEdit_BloodGroup from "pages/Masters/pageAddEdit_BloodGroup";
import pageAddEdit_Country from "../pages/Masters/pageAddEdit_Country";
import pageAddEdit_Designation from "../pages/Masters/pageAddEdit_Designation";
import pageAddEdit_Education from "../pages/Masters/pageAddEdit_Education";
import pageAddEdit_EntityType from "../pages/Masters/pageAddEdit_EntityType";
import pageAddEdit_Gender from "../pages/Masters/pageAddEdit_Gender";
import pageAddEdit_IDDocumentType from "../pages/Masters/pageAddEdit_IDDocumentType";
import pageAddEdit_MaritalStatus from "../pages/Masters/pageAddEdit_MaritalStatus";
import pageAddEdit_MembershipType from "../pages/Masters/pageAddEdit_MembershipType";
import pageAddEdit_Profession from "../pages/Masters/pageAddEdit_Profession";
import pageAddEdit_RegistrationDocumentType from "../pages/Masters/pageAddEdit_RegistrationDocumentType";
import pageAddEdit_Relationship from "../pages/Masters/pageAddEdit_Relationship";
//Membership
import pageAddEdit_Membership from "../pages/Memberships/pageAddEdit_Membership";
import pageAddEdit_EntityMembership from "../pages/Memberships/pageAddEdit_EntityMembership";
import pageAddEdit_MembersAccountMaster from "../pages/Memberships/pageAddEdit_MembersAccountMaster";
//Transaction
import pageAddEdit_SchemeMaster from "../pages/Transaction/pageAddEdit_SchemeMaster"
import pageAddEdit_SchemeInterest from "../pages/Transaction/pageAddEdit_SchemeInterest"
import pageAddEdit_SponsorInformation from "../pages/Transaction/pageAddEdit_SponsorInformation"
import pageList_TransactionAccount from "../pages/Account/pageList_TransactionAccount"
import pageList_ReservedAccount from "../pages/Account/pageList_ReservedAccount"
import pageAddEdit_PersonToPerson from '../pages/Transaction/pageAddEdit_PersonToPerson'
import pageList_LedgerDetails from '../pages/Transaction/pageList_LedgerDetails';
import pageList_PersonToPerson from "pages/Transaction/pageList_PersonToPerson";
import pageAddEdit_Agent from "pages/Memberships/pageAddEdit_Agent";
import pageAddEdit_AccountToAccount from "pages/Transaction/pageAddEdit_AccountToAccount";
import pageList_AccountToAccount from "pages/Transaction/pageList_AccountToAccount";
import pageList_AdvisorMaster from "pages/Memberships/pageList_AdvisorMaster";
import pageList_AgentBankingAccount from "pages/Account/pageList_AgentBankingAccount";
import pageAddEdit_CashDeposit from "pages/Transaction/pageAddEdit_CashDeposit";
import pageAddEdit_CashWithdrawl from "pages/Transaction/pageAddEdit_CashWithdrawl";
import ChangePassword from "../pages/Authentication/ChangePassword";
import MemberSynopsisPrint from "pages/Memberships/MemberSynopsisPrint";
import pageList_MemberDetailsReport from "pages/Memberships/pageList_MemberDetailsReport";
import pageList_MemberDetailsReportActiveInActive from "pages/Memberships/pageList_MemberDetailsReportActiveInActive";
import pageList_MemberJoiningReport from "pages/Memberships/pageList_MemberJoiningReport";
import pageList_MemberLedgerReport from "pages/Memberships/pageList_MemberLedgerReport";
import pageList_CashBook from "pages/ManagementAccounts/pageList_CashBook";
import pageList_UnaprovedMember from "pages/Memberships/pageList_UnaprovedMember";
import MemberApprove from "pages/Memberships/MemberApprove";
import pageAddEdit_GlobalOptions from "pages/Masters/pageAddEdit_GlobalOptions";
import pageAddEdit_AddStaff from "pages/Staff/pageAddEdit_AddStaff";
import pageList_WorkCategory from "pages/Masters/pageList_WorkCategory";
import pageList_SalaryClass from "pages/Masters/pageList_SalaryClass";
import pageList_SalaryLevel from "pages/Masters/pageList_SalaryLevel";
import pageAddEdit_WorkCategory from "pages/Masters/pageAddEdit_WorkCategory";
import pageAddEdit_SalaryClass from "pages/Masters/pageAddEdit_SalaryClass";
import pageAddEdit_SalaryLevel from "pages/Masters/pageAddEdit_SalaryLevel";
import pageAddEdit_PublicViewer from "pages/Memberships/pageAddEdit_PublicViewer";
import pageList_MemberStatement from "pages/Memberships/pageList_MemberStatement";
import pageList_CheckBalance from "pages/Memberships/pageList_CheckBalance";
import pageAddEdit_NewShare from "pages/Memberships/pageAddEdit_NewShare";
import pageAddEdit_AddShare from "pages/Memberships/pageAddEdit_AddShare";
import pageAddEdit_WithdrawShare from "pages/Memberships/pageAddEdit_WithdrawShare";
import pageList_ThriftAccount from "pages/Account/pageList_ThriftAccount";
import pageList_MonthlyIncome from "pages/Account/pagereport_MonthlyIncome";
import pageList_BankLedger from "pages/ManagementAccounts/pageList_BankLedger";
import pageList_AgentStatement from "pages/Memberships/pageList_AgentStatement";
import pageList_AgentLedgerReport from "pages/Memberships/pageList_AgentLedgerReport";
import pageAddEdit_TransactionAccount from "pages/Account/pageAddEdit_TransactionAccount";
import pageList_VoucherReceived from "pages/ManagementAccounts/pageList_VoucherReceived";
import pageList_VoucherPaid from "pages/ManagementAccounts/pageList_VoucherPaid";
import pageList_BalanceSheet from "pages/ManagementAccounts/pageList_BalanceSheet";
import pageReport_BalanceSheet from "pages/ManagementAccounts/pageReport_BalanceSheet";
import pageReport_CashFlowStatement from "pages/ManagementAccounts/pageReport_CashFlowStatement";
import pageReport_IncomeStatement from "pages/ManagementAccounts/pageReport_IncomeStatement";
import OTP from "pages/Authentication/OTP";
import pageReport_MemberStatement from "pages/Memberships/pageReport_MemberStatement";
import pageReport_MemberShareStatement from "pages/Memberships/pageReport_MemberShareStatement";
import pageAddEdit_AddMIS from "pages/Memberships/pageAddEdit_AddMIS";
import pagereport_MonthlyIncome from "pages/Account/pagereport_MonthlyIncome";
import pagereport_CaveoCardStatement from "pages/Account/pagereport_CaveoCardStatement";
import pageList_ManagementTransaction from "pages/ManagementAccounts/pageList_ManagementTransaction";
import pageList_ManagementMIS from "pages/ManagementAccounts/pageList_ManagementMIS";
import pageList_ManagementReserved from "pages/ManagementAccounts/pageList_ManagementReserved";
import pageList_MembershipModify from "pages/Memberships/pageList_MembershipModify";
import pagereport_LoanStatement from "pages/Loan/pagereport_LoanStatement";
import pageAddEdit_CashLoan from "pages/Loan/pageAddEdit_CashLoan";
import pageAddEdit_MembershipApprove from "pages/Memberships/pageAddEdit_MembershipApprove";
import pageAddEdit_MembershipSynopsis from "pages/Memberships/pageAddEdit_MembershipSynopsis";
import Chat from "pages/Loan/Chat";
import pageAddEdit_CreditLoan from "pages/Loan/pageAddEdit_CreditLoan";
import pageAddEdit_DocumentCredit from "pages/Loan/pageAddEdit_DocumentCredit";
import pageAddEdit_Mortgage from "pages/Loan/pageAddEdit_Mortgage";
import pageAddEdit_ReverseMortgage from "pages/Loan/pageAddEdit_ReverseMortgage";
import pageList_CashLoan from "pages/Memberships/pageList_CashLoan";
import pageList_CashLoanReject from "pages/Memberships/pageList_CashLoanReject";
import pageList_CashLoanApproved from "pages/Memberships/pageList_CashLoanApproved";
import pageList_ProvisionalMember from "pages/Memberships/pageList_ProvisionalMember";
import pageAddEdit_Organisation from "pages/Masters/pageAddEdit_Organisation";
import pageList_Organisaction from "pages/Masters/pageList_Organisaction";
import pageList_DutyMode from "pages/Masters/pageList_DutyMode";
import pageAddEdit_DutyMode from "pages/Masters/pageAddEdit_DutyMode";
import pageList_JobList from "pages/Memberships/pageList_JobList";
import pageList_MemberSynopsis from "pages/Memberships/pageList_MemberSynopsis";
import pageList_PersonaRegister from "pages/Memberships/pageList_PersonaRegister";
import pageList_DeadRegister from "pages/Memberships/pageList_DeadRegister";
import MemberSynopsisPrintEntity from "pages/Memberships/MemberSynopsisPrintEntity";
import AMC from 'pages/Memberships/pageAddEdit_AMC';
import pageList_MembersForStatement from "pages/Memberships/pageList_MembersForStatement";
import pagelist_MemberStatistics from 'pages/Transaction/pagelist_MemberStatistics'
import pageAddEdit_DebitCredit from "pages/Transaction/pageAddEdit_DebitCredit";
import pageList_JournalVouchers from "pages/Memberships/pageList_JournalVouchers";
import pageAddEdit_SubjectMaster from "pages/Masters/pageAddEdit_SubjectMaster";
import pageList_SubjectMaster from "pages/Masters/pageList_Subject";
import pageList_MailList from "pages/Help/pageList_MailList";
import pageList_NonMembers from "pages/Memberships/pageList_NonMembers";
import pageList_WithdrawRequest from "pages/Transaction/pageList_WithdrawRequest";
import pageList_MemberUpdateRequest from "pages/Transaction/pageList_MemberUpdateRequest";
import pageAddEdit_WithdrawLimit from "pages/Transaction/pageAddEdit_WithdrawLimit";
import pageList_HelpTopics from "pages/ManagementAccounts/pageList_HelpTopics";
import pagelist_TicketChat from "pages/Help/pagelist_TicketChat";
import pageList_TicketList from "pages/Help/pageList_TicketList";
import pageList_ManagementShareStatement from "pages/Help/pageList_ManagementShareStatement";
import pageList_UnpaidMember from "pages/Memberships/pageList_UnpaidMember";
import pageList_ManagementEntranceFee from "pages/Help/pageList_ManagementEntranceFee";


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/changepassword", component: ChangePassword },

  { path: "/ticketchat/:id", component: pagelist_TicketChat },
  { path: "/tickets", component: pageList_TicketList },
  { path: "/managementsharestatement", component: pageList_ManagementShareStatement },

  { path: "/managemententrancefee", component: pageList_ManagementEntranceFee },
  

  { path: "/unapproved", component: pageList_UnaprovedMember },
 
  { path: "/joblist", component: pageList_JobList },

  { path: "/provisionalmember", component: pageList_ProvisionalMember },

  { path: "/checkbalance", component: pageList_CheckBalance },


  { path: "/unpaidmember", component: pageList_UnpaidMember },

  { path: "/nonmembers", component: pageList_NonMembers },

  { path: "/cashloanreject", component: pageList_CashLoanReject },

  { path: "/cashloanapproved", component: pageList_CashLoanApproved },

  { path: "/helptopics", component: pageList_HelpTopics },

  { path: "/memberstatistics", component: pagelist_MemberStatistics },

  { path: "/maillist", component: pageList_MailList },


  { path: "/membersforstatement", component: pageList_MembersForStatement },

  { path: "/withdrwallimit", component:  pageAddEdit_WithdrawLimit},
  { path: "/memberupdaterequest", component: pageList_MemberUpdateRequest },



  { path: "/creditloan", component: pageAddEdit_CreditLoan },

  { path: "/journalvouchers", component: pageList_JournalVouchers },

  { path: "/debitcredit", component: pageAddEdit_DebitCredit },



  { path: "/personaregister", component: pageList_PersonaRegister },

  { path: "/deadregister", component: pageList_DeadRegister },


  { path: "/documentloan", component: pageAddEdit_DocumentCredit },
 
  { path: "/mortgage", component: pageAddEdit_Mortgage },

  { path: "/reversemortgage", component: pageAddEdit_ReverseMortgage },

  { path: "/add-transactionaccount", component: pageAddEdit_TransactionAccount },





  { path: "/agentstatement", component: pageList_AgentStatement },


  { path: "/membersynopsispage", component: pageList_MemberSynopsis },

  { path: "/agentledgerreport", component: pageList_AgentLedgerReport },


  { path: "/bankledger", component: pageList_BankLedger },

  { path: "/cashloan", component: pageList_CashLoan },

  { path: "/caveocardstatement", component: pagereport_CaveoCardStatement },

  { path: "/newshare", component: pageAddEdit_NewShare },

  { path: "/addshare", component: pageAddEdit_AddShare },

  { path: "/withdrawshare", component: pageAddEdit_WithdrawShare },
  
  { path: "/add-publicviewer", component: pageAddEdit_PublicViewer },
  { path: "/memberstatement", component: pageList_MemberStatement },

  { path: "/memberapprove/:id", component:  MemberApprove},

  { path: "/amc/:id", component:  AMC},

  { path: "/membersynopsisnew/:id", component:  pageAddEdit_MembershipSynopsis},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },


  //Masters
  { path: "/masters-blood-group", component: pageList_BloodGroup },
  { path: "/add-blood-group", component: pageAddEdit_BloodGroup },
  { path: "/edit-blood-group/:id", component: pageAddEdit_BloodGroup },

  { path: "/edit-memberapprove/:id/:ids", component: pageAddEdit_MembershipApprove },
  //
  { path: "/masters-country", component: pageList_Country },
  { path: "/add-country", component: pageAddEdit_Country },
  { path: "/edit-country/:id", component: pageAddEdit_Country },

//


{ path: "/masters-subjectmaster", component: pageList_SubjectMaster },
  { path: "/add-subjectmaster", component: pageAddEdit_SubjectMaster },
  { path: "/edit-subjectmaster/:id", component: pageAddEdit_SubjectMaster },

  //
  { path: "/globaloptions", component: pageAddEdit_GlobalOptions },
  //
  { path: "/masters-designation", component: pageList_Designation },

  { path: "/add-designation", component: pageAddEdit_Designation },
  { path: "/edit-designation/:id", component: pageAddEdit_Designation },
   //

   { path: "/masters-organisation", component: pageList_Organisaction },

   { path: "/add-organisation", component: pageAddEdit_Organisation },
   { path: "/edit-organisation/:id", component: pageAddEdit_Organisation },

    //

    { path: "/masters-dutymode", component: pageList_DutyMode },

    { path: "/add-dutymode", component: pageAddEdit_DutyMode },
    { path: "/edit-dutymode/:id", component: pageAddEdit_DutyMode },
  //
  { path: "/masters-education", component: pageList_Education },
  { path: "/add-education", component: pageAddEdit_Education },
  { path: "/edit-education/:id", component: pageAddEdit_Education },
  //
  { path: "/masters-entity-type", component: pageList_EntityType },
  { path: "/add-entity-type", component: pageAddEdit_EntityType },
  { path: "/edit-entity-type/:id", component: pageAddEdit_EntityType },
  //
  { path: "/masters-gender", component: pageList_Gender },
  { path: "/add-gender", component: pageAddEdit_Gender },
  { path: "/edit-gender/:id", component: pageAddEdit_Gender },
  //
  { path: "/masters-id-document-type", component: pageList_IDDocumentType },
  { path: "/add-id-document-type", component: pageAddEdit_IDDocumentType },
  { path: "/edit-id-document-type/:id", component: pageAddEdit_IDDocumentType },
  //
  { path: "/masters-marital-status", component: pageList_MaritalStatus },
  { path: "/add-marital-status", component: pageAddEdit_MaritalStatus },
  { path: "/edit-marital-status/:id", component: pageAddEdit_MaritalStatus },
  //
  { path: "/masters-membership-type", component: pageList_MembershipType },
  { path: "/add-membership-type", component: pageAddEdit_MembershipType },
  { path: "/edit-membership-type/:id", component: pageAddEdit_MembershipType },
  //
  { path: "/masters-profession", component: pageList_Profession },
  { path: "/add-profession", component: pageAddEdit_Profession },
  { path: "/edit-profession/:id", component: pageAddEdit_Profession },
  //
  { path: "/masters-workcategory", component: pageList_WorkCategory },
  { path: "/add-workcategory", component: pageAddEdit_WorkCategory },
  { path: "/edit-workcategory/:id", component: pageAddEdit_WorkCategory },
  //
  { path: "/masters-salaryclass", component: pageList_SalaryClass },
  { path: "/add-salaryclass", component: pageAddEdit_SalaryClass },
  { path: "/edit-salaryclass/:id", component: pageAddEdit_SalaryClass },
  //
  { path: "/masters-salarylevel", component: pageList_SalaryLevel },
  { path: "/add-salarylevel", component: pageAddEdit_SalaryLevel },
  { path: "/edit-salarylevel/:id", component: pageAddEdit_SalaryLevel },
  //
  {
    path: "/masters-registration-document-type",
    component: pageList_RegistrationDocumentType,
  },
  {
    path: "/add-registration-document-type",
    component: pageAddEdit_RegistrationDocumentType,
  },
  {
    path: "/edit-registration-document-type/:id",
    component: pageAddEdit_RegistrationDocumentType,
  },


  {
    path: "/loanstatement",
    component: pagereport_LoanStatement,
  },


  //
  { path: "/masters-relationship", component: pageList_Relationship },
  { path: "/add-relationship", component: pageAddEdit_Relationship },
  { path: "/edit-relationship/:id", component: pageAddEdit_Relationship },

  //Membership
  { path: "/memberships", component: pageList_Membership },
  { path: "/memberledgers", component: pageList_MemberLedgerReport },
  { path: "/add-membership", component: pageAddEdit_Membership },
  { path: "/edit-membership/:id", component: pageAddEdit_Membership },
  { path: "/membersynopsisprint/:id", component: MemberSynopsisPrint },

  { path: "/membersynopsisprintentity/:id", component: MemberSynopsisPrintEntity },
  { path: "/edit-memberaccount/:id", component: pageAddEdit_MembersAccountMaster },
  { path: "/add-entity-membership", component: pageAddEdit_EntityMembership },
  { path: "/add-Agent", component: pageAddEdit_Agent },
  { path: "/edit-Agent/:id", component: pageAddEdit_Agent },
  {
    path: "/edit-entity-membership/:id",
    component: pageAddEdit_EntityMembership,
  },
  {
    path: "/add-members-account-master",
    component: pageAddEdit_MembersAccountMaster,
  },
  {
    path: "/edit-member-account-master/:id",
    component: pageAddEdit_MembersAccountMaster,
  },


  {
    path: "/agentdetails",
    component: pageList_AdvisorMaster,
  },

  {
    path: "/menulistcollectionreport",
    component: Chat,
  },


  { path: "/memberdetailsreport", component: pageList_MemberDetailsReport },
  { path: "/memberjoiningreport", component: pageList_MemberJoiningReport },

  { path: "/cashdepositsa", component: pageList_CashBook},


  { path: "/masters-membershipmodify", component: pageList_MembershipModify},
  { path: "/masters-membershipmodify/:id", component: pageList_MembershipModify},



  { path: "/managementtransaction", component: pageList_ManagementTransaction},

  { path: "/managementreserved", component: pageList_ManagementReserved},



  { path: "/memberdetailsreportactiveinactive", component: pageList_MemberDetailsReportActiveInActive },


  //Transaction
  //Scheme
  { path: "/schememaster", component: pageList_SchemeMaster },
  { path: "/add_schememaster", component: pageAddEdit_SchemeMaster },
  { path: "/edit-schememaster/:id", component: pageAddEdit_SchemeMaster },
  { path: "/add-entity-schememaster", component: pageAddEdit_SchemeMaster },
  { path: "/add-person-to-person", component: pageAddEdit_PersonToPerson },
  { path: "/add-account-to-account", component: pageAddEdit_AccountToAccount },
  { path: "/add-cashdeposit", component: pageAddEdit_CashDeposit },
  { path: "/add-cashwithdrawl", component: pageAddEdit_CashWithdrawl },
  { path: "/ledgerdetails", component: pageList_LedgerDetails },
  { path: "/persontoperson", component: pageList_PersonToPerson },
  { path: "/accounttoaccount", component: pageList_AccountToAccount },
  
  //Scheme Interest Master
  { path: "/schemeinterestmaster", component: pageList_SchemeInterest },
  { path: "/add_schemeinterestmaster", component: pageAddEdit_SchemeInterest },
  { path: "/edit-schemeinterestmaster/:id", component: pageAddEdit_SchemeInterest },
  { path: "/add-entity-schememaster", component: pageAddEdit_SchemeInterest },

  //Sponsor Information
  { path: "/sponsor", component: pageList_SponsorInformation },
  { path: "/add_sponsormaster", component: pageAddEdit_SponsorInformation },
  { path: "/edit-sponsormaster/:id", component: pageAddEdit_SponsorInformation },
  { path: "/add-entity-sponsormaster", component: pageAddEdit_SponsorInformation },


  //Account

  { path: "/account_transaction", component: pageList_TransactionAccount },
  { path: "/reserved_transaction", component: pageList_ReservedAccount },
  { path: "/thrift_transaction", component: pageList_ThriftAccount },
  { path: "/monthlyincome_transaction", component: pagereport_MonthlyIncome },
  { path: "/add-mis", component: pageAddEdit_AddMIS },
  { path: "/agentbankingaccount", component: pageList_AgentBankingAccount },



  //Management Accounts
  { path: "/cashbook", component: pageList_CashBook },
  { path: "/voucherreceived", component: pageList_VoucherReceived },
  { path: "/voucherpaid", component: pageList_VoucherPaid },
  { path: "/balancesheet", component: pageReport_BalanceSheet },
  { path: "/cashflowstatement", component: pageReport_CashFlowStatement },
  { path: "/incomestatement", component: pageReport_IncomeStatement },
  { path: "/memberstatementreport", component: pageReport_MemberStatement },
  { path: "/memberstatementreport/:id", component: pageReport_MemberStatement },
  { path: "/membersharestatement", component: pageReport_MemberShareStatement },
  { path: "/managementmis", component: pageList_ManagementMIS },
  { path: "/withdrawrequest", component: pageList_WithdrawRequest },



  //Staff
  { path: "/addstaff", component: pageAddEdit_AddStaff },


  //Loan
  { path: "/add-cashloan", component: pageAddEdit_CashLoan },

];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/otp/:id", component: OTP },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
