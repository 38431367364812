import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { API_WEB_URLS } from "../../constants/constAPI";
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_AddEditData, Fn_AddEditDataN, Fn_FillListData } from "../../store/functions";


class pageList_MailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {
        subject: null,
      },
      activeTab: 1,
      passedSteps: [1],

      mailList: [],
      selectedMailItem: null,
      openList: false,
      conversation: [],
    };

    this.obj = this;
    this.formTitle = "Query List";
    this.breadCrumbTitle = "Request For Support";
    this.breadCrumbItem = this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE =   "InsertSupportMailL/0/token";
    this.pushFormName = "/maillist";

    //bind this
    this.formatDate = this.formatDate.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    //Filling DropDowns
    Fn_FillListData(
      this.obj,
      "mailList",
      API_WEB_URLS.MASTER + "/0/token/maillist/Id/0"
    );
  }
  formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate.replace(",", "");
  }

  sendEmail = (item, id) => {
    console.log(this.state.conversation);
    let vformData = new FormData();
    vformData.append("F_SupportMail", this.state.selectedMailItem.Id);
    vformData.append("F_UserType", "Entity");
    vformData.append("F_UserMaster", 1);
    vformData.append("Message", this.state.message);

    Fn_AddEditDataN(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      this.API_URL_SAVE,
      '#',
      true
    );

     Fn_FillListData(
       this.obj,
       "conversation",
       API_WEB_URLS.MASTER +
         "/0/token/supportmailL/Id/" +
         this.state.selectedMailItem.Id
     );
  };
  closeEmail=()=>{
    Fn_FillListData(
      this.obj,
      "xyz",
      API_WEB_URLS.MASTER +
        "/0/token/SolvedSupportMail/Id/" +
        this.state.selectedMailItem.Id
    );
    const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(
      this.obj,
      "mailList",
      API_WEB_URLS.MASTER + "/0/token/maillist/Id/" + obj.uid
    );
   
                          this.setState({
                            openList: false,
                          })
                     
  }
  // handleEditorChange = editorState => {
  //   const contentState = editorState.getCurrentContent();
  //   const text = contentState.getPlainText();

  //   this.setState({ message: text });
  // };

  handleEditorChange = (e) => {
    const contentState = e.target.value;
    

    this.setState({ message: contentState });
  };
  handleClick = (item, id) => {
    this.setState({
      selectedMailItem: item,
      openList: true,
    });
    Fn_FillListData(
      this.obj,
      "conversation",
      API_WEB_URLS.MASTER + "/0/token/supportmailL/Id/" + id
    );
  };
  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content" style={{marginTop : '200px'}}>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />

<Row>
              <Col lg="12">
                
                    <Card  className="mb-3">
                      <CardBody>
                        <Row >
                          <Col sm="2">
                            <h5 className="card-title">
                              Date + Time
                            </h5>
                          </Col>
                          <Col sm="2">
                            <h5 className="card-title">
                            Addressee + Subject
                            </h5>
                          </Col>
                          <Col sm="4">
                          <h5 className="card-title">
                            Action
                            </h5>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  
              </Col>
            
            </Row>
            <Row>
              <Col lg="12">
                {!this.state.openList &&
                  this.state.mailList.map((item, index) => (
                    <Card key={index} className="mb-3">
                      <CardBody>
                        <Row onClick={() => this.handleClick(item, item.Id)}>
                          <Col sm="2">
                            <h6 >
                              {item.FormattedDate}
                            </h6>
                            <h6 >
                              {item.FormattedTime}
                            </h6>
                          </Col>
                          <Col sm="2">
                            <h6 >
                               {item.FirstName} :  {item.MembershipNo}
                            </h6>
                            <h6 >
                              Subject : {item.Subject}
                            </h6>
                          </Col>
                         
                          <Col sm="4">
                            <p className="card-text">
                              {item.IsReplied ? (
                                <p  style={{fontWeight:'bold', color: "blue" }}>Replied</p>
                              ) : item.IsClosed ? (
                                <p  style={{fontWeight:'bold', color: "red" }}>Closed</p>
                              ) : item.IsSolved ? (
                                <p  style={{fontWeight:'bold', color: "green" }}>Solved</p>
                              ) : (
                                <p  style={{fontWeight:'bold', color: "yellow" }}>Pending</p>
                              )}
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
              </Col>
              <Col lg="12">
                {this.state.openList && (
                  <Card>
                    <CardBody>
                      <Row
                        onClick={() =>
                          this.setState({
                            openList: false,
                          })
                        }
                      >
                        <Col sm="2">
                          <h5>Subject</h5>
                        </Col>
                        <Col lg="10">
                          <h5>{this.state.selectedMailItem.Subject}</h5>
                        </Col>

                       

                        <Col sm="2">
                          <p>Date </p>
                        </Col>
                        <Col sm="10">
                          <p>
                            {this.formatDate(
                              this.state.selectedMailItem.DateOfCreation
                            )}
                          </p>
                        </Col>
                      </Row>
                      <hr />
                      {this.state.conversation.map((item, key) => (
                        <Row key={item.Id}>
                          <Col sm="2">
                            <p style={{ fontSize: "15px" }}>
                              {item.F_UserType === "Member"
                                ? "Member "
                                : "Federated Society "}
                            </p>
                          </Col>
                          <Col sm="6">
                            <p style={{ fontSize: "15px" }}>{item.Message}</p>
                          </Col>
                          <Col sm="4">
                            <p style={{ fontSize: "15px" }}>
                              Date : {this.formatDate(item.DateOfCreation)}
                            </p>
                          </Col>
                        </Row>
                      ))}
                      {!this.state.selectedMailItem.IsReplied &&
                        !this.state.selectedMailItem.IsClosed &&
                        !this.state.selectedMailItem.IsSolved && (
                          <>
                            <Row>
                              <Col sm="12">
                                <h5>Reply Here : </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">

                              <textarea className="form-control"  type="text"
                                placeholder="Place Your Message Here..."
                                  onChange={(e)  => this.handleEditorChange(e)}  rows="4" cols="50">
                                </textarea>
                               
                                {/* <Editor
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  placeholder="Place Your Message Here..."
                                  onEditorStateChange={this.handleEditorChange}
                                /> */}
                              </Col>
                            </Row>
                            <div className="d-flex flex-wrap gap-2">
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => this.sendEmail()}
                              >
                                Send
                              </Button>
                              <Button
                                type="button"
                                color="success"
                                onClick={() => this.closeEmail()}
                              >
                                Solved
                              </Button>
                            </div>
                          </>
                        )}
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(pageList_MailList);
