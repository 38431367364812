import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { size } from "lodash";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Images
import images from "../../assets/images";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  addMessage,
  getChats,
  getContacts,
  getGroups,
  getMessages,
} from "../../store/actions";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoomId: 1,
      currentUser: {
        name: "Henry Wells",
        isActive: true,
      },
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      activeTab: "1",
      Chat_Box_Username: "Report",
      Chat_Box_User_Status: "online",
      Chat_Box_User_isActive: false,
      curMessage: "",
      menus : [{
        id : 1,
        name : 'List Assignment'
      },
      {
        id : 2,
        name : 'Loan Collection List'
      },
      {
        id : 3,
        name : 'Loan Collection All'
      },
      {
        id : 4,
        name : 'Loan Skipped List'
      },
      {
        id : 5,
        name : 'Loan Collection List'
      },
      {
        id : 6,
        name : 'Deposit Collection List'
      },
      {
        id : 7,
        name : 'Deposit Skipped List'
      },
      {
        id : 8,
        name : 'Deposit Collection All'
      },
      {
        id : 9,
        name : 'Area-Wise Collection'
      },
      {
        id : 10,
        name : 'Group Collection List'
      },
      {
        id : 11,
        name : 'Savings Collection List'
      }
    
    
    ],
                             
    chat : [
      {
        id: 1,
        roomId: 1,
        status: "offline",
        description: "Hey! there I'm available",
        image: "avatar2",
        name: "Steven Franklin",
        time: "05 min"
      },
      {
        id: 2,
        roomId: 2,
        status: "online",
        description: "I've finished it! See you soon",
        image: "avatar3",
        name: "Adam Miller",
        time: "12 min"
      },
      {
        id: 3,
        roomId: 3,
        status: "online",
        description: "This theme is awesome!",
        image: "avatar3",
        name: "Keith Gonzales",
        time: "24 min"
      },
      {
        id: 4,
        roomId: 4,
        status: "intermediate",
        description: "Nice to meet you",
        image: "avatar4",
        name: "Jose Vickery",
        time: "1 hr"
      },
      {
        id: 5,
        roomId: 5,
        status: "offline",
        description: "Hey! there I'm available",
        image: "avatar4",
        name: "Mitchel Givens",
        time: "3 hrs"
      },
      {
        id: 6,
        roomId: 6,
        status: "online",
        description: "I've finished it! See you soon",
        image: "avatar6",
        name: "Stephen Hadley",
        time: "5 hrs"
      },
      {
        id: 7,
        roomId: 7,
        status: "online",
        description: "This theme is awesome!",
        image: "avatar6",
        name: "Keith Gonzales",
        time: "24 min"
      }
    ],
    display : false
      
    };
    this.messageBox = null;
  }

  componentDidMount() {
  
   
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props;
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom();
    }
  }

  toggleNotification = () => {
    this.setState(prevState => ({
      notification_Menu: !prevState.notification_Menu,
    }));
  };

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }));
  };

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }));
  };

  toggleOther = () => {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }));
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  //Use For Chat Box
  userChatOpen = ( name) => {
    //const { onGetMessages } = this.props;
    this.setState({ Chat_Box_Username: name });
   // onGetMessages(roomId);
  };

  menu () {

   


    var element = document.getElementById("recent-list");
    element.style.display = this.state.display == false ?  "none" : "";

    var element2 = document.getElementById("men");
    element2.style.minWidth = this.state.display == false ?  "10px" : "488px";

    this.setState(prevState => ({
      display: !prevState.display
    }));

  }



  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000;
    }
  };

  onKeyPress = e => {
    const { key, value } = e;
    const { currentRoomId, currentUser } = this.state;
    if (key === "Enter") {
      this.setState({ curMessage: value });
      this.addMessage(currentRoomId, currentUser.name);
    }
  };

  //serach recent user
  searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  render() {
    
    const { currentRoomId, currentUser } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Reports | Skote - React Admin & Dashboard Template</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Skote" breadcrumbItem="Chat" />

            <Row>
              <Col lg="12">
                <div className="d-lg-flex">
                  <div className="chat-leftsidebar me-lg-4" id="men">
                    <div className="">
                  

                   

                      <div className="chat-leftsidebar-nav">
               
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="py-4"
                        >
                          <TabPane tabId="1">
                            <div>
                            <Link
                                        to="#"
                                        onClick={() => {
                                          this.menu(
                                          );
                                        }}
                                      >
                                <i className="bx bx-menu" style={{fontSize:'19px'}} />
                                <span className="calibri-normal-black-27px" style={{fontSize:'23px'}}> Menu</span>
                              {/* <h5 className="font-size-14 mb-3">Menus</h5> */}
                              </Link>
                              <ul className="list-unstyled chat-list" id="recent-list">
                                <PerfectScrollbar style={{ height: "410px" }}>
                                  {this.state.menus.map(chat => (
                                    <li
                                      key={chat.id}
                                      className={
                                        currentRoomId === chat.roomId
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          this.userChatOpen(
                                            // chat.id,
                                            chat.name,
                                            // chat.status,
                                            // chat.roomId
                                          );
                                        }}
                                      >
                                        <div className="d-flex">
                                        
                                        <div className="align-self-center me-3">
                                        <h5 className="text-truncate font-size-14 mb-1">
                                              {chat.name}
                                            </h5>
                                          </div>
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                                </PerfectScrollbar>
                              </ul>
                            </div>
                          </TabPane>

                     
                         
                        </TabContent>
                      </div>
                    </div>
                  </div>
                  
                  <div className="w-100 user-chat">
                    
                    <Card>
                      <div className="p-4 border-bottom ">
                        <Row>
                        <h5 className="font-size-14 mb-3" style={{textAlign:'center'}}>{this.state.Chat_Box_Username}</h5>
                       
                          <ul className="list-unstyled mb-0">
                            <PerfectScrollbar
                              style={{ height: "486px", minHeight: "486px" }}
                              containerRef={ref => (this.messageBox = ref)}
                            >
                              <li>
                               
                              </li>
                             
                            </PerfectScrollbar>
                          </ul>
                      
                        </Row>
                      </div>

                  
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
};




export default compose(container)(Chat);
