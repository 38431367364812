/* eslint-disable */


import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPageMem from "../../components/Common/RCDisplayPageMem";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { Card, CardBody, Col, Container, Row, Table, Button, CardTitle, Modal, UncontrolledTooltip, Alert  } from "reactstrap";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_ChangePassword
  
} from "../../store/functions";
import { Link } from "react-router-dom";
import { Fn_ChangeStateValue } from "../../store/functions";
import { API_HELPER } from "helpers/ApiHelper";
import SweetAlert from "react-bootstrap-sweetalert";

class pageList_Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true,
      isEdit : false,
      isDelete : false ,
      roleid : 0,
      modal_fullscreen : false, 
      BoldToken : [{
        Token : ''
      }],
      modal_backdrop : false,
      modal_backdrop2 : false,
      commissionmodal : false
     
    };
    this.obj = this;
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Members";
    this.modalTitle = "Memberships";
    this.rtPage_Add = "add-membership";
    this.rtPage_Edit = "/edit-membership";
    this.rtPage_Edit2 = "/edit-entity-membership";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/MemberList";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.printInvoice =  this.printInvoice.bind(this);
    this.searchmember  =  this.searchmember.bind(this);
    this.tog_backdrop =  this.tog_backdrop.bind(this);
    this.tog_backdrop2  = this.tog_backdrop2.bind(this);
    this.tog_fullscreen  =  this.tog_fullscreen.bind(this);
    this.btn_fullMember  = this.btn_fullMember.bind(this);
    this.showpass  = this.showpass.bind(this);
    this.proceedchange  = this.proceedchange.bind(this);
    this.changepass   = this.changepass.bind(this);
    this.syno  = this.syno.bind(this);
    
  }


 
  componentDidMount() {

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Members")
    const obj = JSON.parse(localStorage.getItem("authUser"));


    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role  , roleid:obj.roleid });

    if (obj.roleid == 4) {
      this.setState({ isEdit: false, isDelete : false });
    }

    else {
      this.setState({ isEdit: false, isDelete : false });
    }
    Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");
    Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/1");
    Fn_FillListData(this.obj, "gridDatanominal", this.API_URL + "/Id/2");
    Fn_FillListData(this.obj, "gridDataGratis", this.API_URL + "/Id/6");
    // Fn_FillListData(this.obj, "gridDatanon", this.API_URL + "/Id/4");
    Fn_FillListData(this.obj, "gridDataentity", this.API_URL + "/Id/5");
    Fn_FillListData(this.obj, "gridDataApprove", this.API_URL + "/Id/9");
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
  }


  // proceedchange(Id){
  //   this.setState({MemberId : Id, commissionmodal : true})
  // }

  showpass () {
     
    if (this.state.PassKey  ==  'Bulaiza@123'){
      alert(this.state.password);
    }
    else{
      alert('Passkey is wrong!')
    }
    this.setState({modal_backdrop : false,PassKey:''})
  }

  changepass (){
    if (this.state.PassKey  ==  'Bulaiza@123'){
      this.setState({modal_backdrop2 : true})
    }
    else{
      alert('Passkey is wrong!')
    }
    this.setState({modal_backdrop : false,PassKey:''})
  }


  approve  = async()=>{

    let vformData = new FormData();
   
  //Information
  vformData.append("Id", this.state.MemberId);
  vformData.append("F_MembershipType", this.state.MembershipTypeId);
  vformData.append("LoginPin", '1234');

    let AuthUser2 = function() {
      return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"ApproveProvisionalMember/0/token" , vformData).then(token => { return token } )
    }


    let userToken2 = await AuthUser2();
    

    this.setState({success_msg : true , MemberId : 0 , MembershipTypeId : 0,Pin : '' })
    
  }



  proceedchange () {
  
    let vformData = new FormData();
        
    vformData.append("F_UserMaster", this.state.Id);
    vformData.append("OldPassword", this.state.password);
    vformData.append("NewPassword", this.state.NewPassword);
  
    Fn_ChangePassword(this.obj, { arguList: { id: 0, formData: vformData } }, "ChangePasswordMember/0/token", "#", true);

    alert('Password Changed Succesfully!');
    this.setState({modal_backdrop2 : false, password : '', Id: 0})
  }
  print = () => {          
    this.setState({modal_fullscreen : true})
  };
  searchmember (event) {
    
    Fn_FillListData(this.obj, "gridDatamember", "SearchMembership/0/token/"+event.target.value+"?F_MembershipTypeMaster=1");
    Fn_FillListData(this.obj, "gridDatanominal", "SearchMembership/0/token/"+event.target.value+"?F_MembershipTypeMaster=2");
    
  }

  printInvoice (formData)  {
    if (formData.MembershipTypeId == 5){
      this.props.history.push(`/membersynopsisprintentity/${formData.Id}`, {});
    }
    else {
    this.props.history.push(`/membersynopsisnew/${formData.Id}`, {});
    }
  };


  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  tog_backdrop(formData) {
    this.setState(prevState => ({
      modal_backdrop: !prevState.modal_backdrop,
      password : formData.UserPassword,
      Id : formData.Id
    }))
    this.removeBodyCss()
  }


  tog_backdrop2() {
    this.setState(prevState => ({
      modal_backdrop2: !prevState.modal_backdrop
    }))
    this.removeBodyCss()
  }

  
  removeBodyCss() {
    document.body.classList.add("no_padding")
  }


  btnEdit_onClick(formData) {

    if (formData.MembershipTypeId == 5){
      this.props.history.push(`${this.rtPage_Edit2}/${formData.Id}`, {});
    }
    else {
   this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
    }
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        {/* <th>Membership Type</th> */}
        <th>MEMBERSHIP NO.</th>
        <th>MEMBERS NAME</th>
        <th>SEX</th>
        <th>ADDRESS</th>
        <th>PHONE</th>
        <th>PASS</th>
        {/* <th>EMERGENCY CONTACT</th> */}

      </>
    );
  }

  
  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss();
   
  }


  removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  syno(){
    this.setState({success_msg : false, commissionmodal : false });

    Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/1");
    Fn_FillListData(this.obj, "gridDatanominal", this.API_URL + "/Id/2");
    Fn_FillListData(this.obj, "gridDataentity", this.API_URL + "/Id/5");
  }





  renderGridBody(formData) {

   
var Gender  =  formData.GenderId;

if (Gender  == 2) {
  Gender  =  "Female";
}
else if (Gender  == 1) {
  Gender  =  "Male"
}
else if (Gender  == 4){
  Gender  =  "Entity"
}

    return (
      <>
        {/* <td>{formData.MembershipType}</td> */}
        <td>{formData.MembershipNo}</td>
        <td>{formData.Name}</td>
        <td>{Gender}</td>
        <td >{formData.Address_Residential}</td>
        <td>{formData.PhoneNo1_Residential}</td>
        <td><Link to="#"  className="mr-3 text-primary"  onClick={()=>this.tog_backdrop(formData)}>
                                                                        <i className="mdi mdi-eye-off font-size-18 mr-3" id="edittooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                                                            Show Pass
                                                                        </UncontrolledTooltip >
                                                                    </Link></td>
        {/* <td>{formData.PhoneNo1_Nominee}</td> */}
      
        {/* <td></td>
        <td></td> */}
      </>
    );
  }


  btn_fullMember (formData) {
    this.setState({MemberId : formData.Id, commissionmodal : true})
    // Fn_FillListData(this.obj, "ff", API_WEB_URLS.MASTER+'/0/token/ProceedFullMember' + "/Id/"+formData.Id);
    // alert('Member moved to full member');
    // Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/1");
    // Fn_FillListData(this.obj, "gridDatanominal", this.API_URL + "/Id/2");
    // Fn_FillListData(this.obj, "gridDataentity", this.API_URL + "/Id/5");
  
  }



  renderModalBody(selectedFormData) {
 
     
  

    return (
      <>
       <p className="mb-4">
          Membership No.: <span className="text-primary">{selectedFormData.MembershipNo}</span>
        </p>
        <p className="mb-4">
         Full Name: <span className="text-primary">{selectedFormData.FirstName} {selectedFormData.SurName}</span>
        </p>
        <p className="mb-4">
          Phone No.: <span className="text-primary">{selectedFormData.PhoneNo1_Residential}</span>
        </p>
        <p className="mb-4">
          Membership Type: <span className="text-primary">{selectedFormData.MembershipType}</span>
        </p>

        <p className="mb-4">
        Member Photo:  <img height="150" className="rounded me-2" alt={"image"} width="200" src={selectedFormData.ImageURL_Member_Thumbnail} />
        </p>

        <p className="mb-4">
        Signature:  <img height="150" className="rounded me-2" alt={"image"} width="200" src={selectedFormData.ImageURL_Signature_Thumbnail} />
        </p>

        <p className="mb-4">
       Transaction Account:  <span className="text-primary">{selectedFormData.MembershipNo}TR01</span>
        </p>
        <p className="mb-4">
        MIS Account:   <span className="text-primary">{selectedFormData.MembershipNo}MS01</span>
        </p>

        <p className="mb-4">
        Reserved Account:  <span className="text-primary">{selectedFormData.MembershipNo}RS01</span>
        </p>
      </>
    );
  }
  render() {
    return (
      <>
      <div className="page-content">
      <RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={true}
        isSNo={true}
        isCheckBox={true}
        isfull ={true}
        isViewDetails={true}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatamember}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        searchmember = {this.searchmember}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        tog_backdrop={this.tog_backdrop}
        //user rights
        isAdd={true}
        isEdit={this.state.isEdit}
        isDelete={this.state.isDelete}
        btn_fullMember ={this.btn_fullMember}
      ></RCDisplayPageMem>
</div>

<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"Nominal Members"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isfull ={true}
        isViewDetails={true}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatanominal}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        tog_backdrop={this.tog_backdrop}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={this.state.isEdit}
        btn_fullMember ={this.btn_fullMember}
        isDelete={this.state.isDelete}
      ></RCDisplayPageMem>




      
<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"Gratis Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isfull ={true}
        isViewDetails={true}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDataGratis}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        tog_backdrop={this.tog_backdrop}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={this.state.isEdit}
        btn_fullMember ={this.btn_fullMember}
        isDelete={this.state.isDelete}
      ></RCDisplayPageMem>



<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"Approved Members"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={true}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDataApprove}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        tog_backdrop={this.tog_backdrop}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={this.state.isEdit}
        isDelete={this.state.isDelete}
      ></RCDisplayPageMem>




<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={"Entity Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={true}
        isfull ={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDataentity}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        tog_backdrop={this.tog_backdrop}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={this.state.isEdit}
        isDelete={this.state.isDelete}
        btn_fullMember ={this.btn_fullMember}
      ></RCDisplayPageMem>

<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
           
                <Card style={{backgroundColor: API_WEB_URLS.Customer === 4 ? this.state.VoucherGlobalOptions[0].BackgroundColor : '#a8ead4'}}>
                  <CardBody>
                    <CardTitle className="h4">
                    
                    </CardTitle>
                 
                    <div>

                    <Modal
                        isOpen={this.state.modal_backdrop}
                        toggle={this.tog_backdrop}
                        scrollable={true}
                        backdrop={'static'}
                        id="staticBackdrop"
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">Show Password</h5>
                          <button type="button" className="btn-close" onClick={() =>
                            this.setState({ modal_backdrop: false })
                          } aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          
                          <input name="PassKey" label="" value={this.state.PassKey === null ? ''   : this.state.PassKey}  onChange={(e) => Fn_ChangeStateValue(this.obj, 'PassKey', e.target.value)}  placeholder="Enter PassKey ."   type="text"  className="form-control" />
                          
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn" onClick={() =>
                            this.setState({ modal_backdrop: false })
                          }>Cancel</button>
                          <button type="button" onClick={this.showpass}  className="btn btn-primary">View</button>

                          <button type="button" onClick={this.changepass}  className="btn btn-primary">Change Pass</button>
                        </div>
                      </Modal>



                      <Modal
                        isOpen={this.state.modal_backdrop2}
                        toggle={this.tog_backdrop2}
                        scrollable={true}
                        backdrop={'static'}
                        id="staticBackdrop"
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">Change Password</h5>
                          <button type="button" className="btn-close" onClick={() =>
                            this.setState({ modal_backdrop2: false })
                          } aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <input name="NewPassword" label="" value={this.state.NewPassword === null ? ''   : this.state.NewPassword}  onChange={(e) => Fn_ChangeStateValue(this.obj, 'NewPassword', e.target.value)}  placeholder="Enter NewPassword ."   type="text"  className="form-control" />
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn" onClick={() =>
                            this.setState({ modal_backdrop2: false })
                          }>Cancel</button>
                         
                          <button type="button" onClick={this.proceedchange}  className="btn btn-primary">Proceed</button>
                        </div>
                      </Modal>



                      
<Modal
                        isOpen={this.state.commissionmodal}
                        toggle={this.commissionmodal}
                        scrollable={true}
                        backdrop={'static'}
                        id="staticBackdrop2"
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" >Change Membership Type</h5>
                          <button type="button" className="btn-close" onClick={() =>
                            this.setState({ commissionmodal: false })
                          } aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Membership Type</label>
                                        </Col>
                                        <Col sm="4" className="mb-3">

                                        <select name="MembershipTypeId" label="" value={this.state.MembershipTypeId} onChange={(e) => Fn_ChangeStateValue(this.obj, 'MembershipTypeId', e.target.value)}  errorMessage="Select Membership Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.membershipType
                                              ? this.state.membershipType.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </select>
                         
                         </Col>
                         </Row>

                        </div>
                        <div className="modal-footer">
                          <button type="button" onClick={this.approve} className="btn btn-info">Proceed</button>
                        </div>
                      </Modal>

                     
                      <Modal
                        size="xl"
                        isOpen={this.state.modal_fullscreen}
                        toggle={this.tog_fullscreen}
                        className="modal-fullscreen"
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title mt-0"
                            id="exampleModalFullscreenLabel"
                          >
                           Print
                          </h5>
                          <button
                            onClick={() =>
                              this.setState({ modal_fullscreen: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                         
                
                    
                      <BoldReportViewerComponent id="reportviewer-container"
                     reportServiceUrl = {API_WEB_URLS.REPORTURL +'reporting/reportservice/api/Viewer'}
                     reportServerUrl= {API_WEB_URLS.REPORTURL +'reporting/api/site/site1'}
                     serviceAuthorizationToken = {'bearer '+this.state.BoldToken[0].Token}
                     reportPath = {'/Ghana/Members'}
                     printMode = {true}
                  
                     >
                     </BoldReportViewerComponent>
                 

                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={this.tog_fullscreen}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                      
                        </div>
                      </Modal>
                    </div>
                  </CardBody>
                </Card>

                {this.state.success_msg == true ? (
                      <SweetAlert
                        title="Membership Changed Succesfully !"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                      
                      </SweetAlert>
                    ) : null}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>


<div className="d-print-none">
                        <div className="float-end">
<Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.print}
                        >
                          Print
                        </Button>
                        </div>
                        </div>
                        </CardBody>
                        </Card>
                        </Col>
                        </Row>
                        </Container>
                        </div>
                        </React.Fragment>
</>







      
    );
  }
}
export default compose(container)(pageList_Membership);
