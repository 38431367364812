/* eslint-disable */


import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPageMem from "../../components/Common/RCDisplayPageMem";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { Card, CardBody, Col, Container, Row, Table, Button, CardTitle, Modal, UncontrolledTooltip, Alert, Input  } from "reactstrap";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_ChangePassword,
  Fn_GetReport
  
} from "../../store/functions";
import { Link } from "react-router-dom";
import { Fn_ChangeStateValue } from "../../store/functions";
import { API_HELPER } from "helpers/ApiHelper";
import SweetAlert from "react-bootstrap-sweetalert";

class pageList_MembersForStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true,
      isEdit : false,
      isDelete : false ,
      roleid : 0,
      modal_fullscreen : false, 
      BoldToken : [{
        Token : ''
      }],
      modal_backdrop : false,
      modal_backdrop2 : false,
      commissionmodal : false,
      gridDatanominal  : [{}],
      groupedData  : [{}]
     
    };
    this.obj = this;
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Members";
    this.modalTitle = "Memberships";
    this.rtPage_Add = "add-membership";
    this.rtPage_Edit = "/edit-membership";
    this.rtPage_Edit2 = "/edit-entity-membership";
    this.API_URL = "MembersForStatement/0/token";
    this.view  = this.view.bind(this);
    this.searchmember =  this.searchmember.bind(this);

    //Event Binding
   
    
  }


 
  componentDidMount() {

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Members")
    const obj = JSON.parse(localStorage.getItem("authUser"));


    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role  , roleid:obj.roleid });

    if (obj.roleid == 4) {
      this.setState({ isEdit: false, isDelete : false });
    }

    else {
      this.setState({ isEdit: false, isDelete : false });
    }



    Fn_GetReport(this.obj, { arguList: { id: 0, formData: "vformData" } }, this.API_URL,"gridDatanominal");
    
    
  }



 




  renderGridHeader() {
    return (
      <>
        
        <th>GIN </th>
        <th>Name </th>
        <th>Transaction A/C</th>
        <th>Shares</th>
        <th>Reserved A/C</th>
        <th>Thrift A/C</th>
        <th>Caveo</th>
        <th>MIS</th>
        <th>Term Deposit</th>
        <th>DCA</th>
        <th>Total Cash</th>
        <th>View</th>


      </>
    );
  }

  view (Id){

    this.props.history.push(`${"memberstatementreport"}/${Id}`, {});

  }









  renderGridBody(formData) {

    return (
      <>
       
        <td>{formData.MembershipNo}</td>
        <td>{formData.FullName}</td>
        <td>{formData.TransactionBalance}</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>0.00</td>
        <td>{formData.TransactionBalance}</td>

        <td><td><Link to="#"  className="mr-3 text-primary"  onClick={()=>this.view(formData.Id)}>
                                                                    <i className="mdi mdi-near-me font-size-18 mr-3" id="edittooltip"></i>
                                                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                                                            View Details
                                                                        </UncontrolledTooltip >
                                                                    </Link></td></td>
        
     
      </>
    );
  }

  searchmember(e){
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm == null  || searchTerm == ''){
      this.setState({gridDatanominal : this.state.MainArr});
    }
    else {
      
    

    const filteredData = this.state.MainArr.filter(
      (item) =>
        item.FullName.toLowerCase().includes(searchTerm) ||
        item.MembershipNo.toString().includes(searchTerm)
    );

    this.setState({gridDatanominal : filteredData })

    }
    

  }






  renderModalBody(selectedFormData) {
 
     
  

    return (
      <>
       <p className="mb-4">
        
        </p>
       
      </>
    );
  }
  render() {

    const groupedData = {};
    this.state.gridDatanominal.forEach(item => {
      if (!groupedData[item.MembershipType]) {
        groupedData[item.MembershipType] = [];
      }
      groupedData[item.MembershipType].push(item);
    });
    
    return (
      <>

      <div style={{marginTop:250}}>

        <Row>
        <Col sm="4">
                                            <div className="search-box mr-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <Input type="text" className="form-control" placeholder="Search..." onChange={this.searchmember} />
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
        </Row>
      {Object.keys(groupedData).map((membershipType, index) => (

<RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        key={index}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={`${membershipType}`} // Example breadcrumb title
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isfull ={false}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={groupedData[membershipType]} // Pass grouped data
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        searchmember = {this.searchmember}
        tog_backdrop={this.tog_backdrop}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        view ={this.view}
       
        isDelete={false}
      ></RCDisplayPageMem>

      ))}

</div>
</>







      
    );
  }
}
export default compose(container)(pageList_MembersForStatement);
