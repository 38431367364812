import React, { Component } from "react";


import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap";
import { isEmpty } from "lodash";

import img4 from "../../assets/images/small/img-4.jpg";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
} from "../../store/functions";

import ReactToExcel  from 'react-html-table-to-excel'
import '../Memberships/datatables.scss'

class MemberSynopsisPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      id: 0,
      selectedFormData: {},
      detail:[
{
  MembershipType : "",
  DateOfJoining : "",
  MembershipNo : "",
  Name : "",
  Address_Residential : "",
  MobileNo_SMS : "",
  Email : "",
  SpouseName : "",
  Address_Business : "",
  MobileNo1_Business : "",
  MembershipFees : "",
  ImageURL_Member : '',
  Address_Nominee : ''
}

      ],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true
     
    };
    this.obj = this;
    this.pushFormName = "/membersynopsispage";
    this.breadCrumbTitle = "Synopsis Print";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/MemberEdit";
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);

    //Event Binding
   
  }
  componentDidMount() {
    
    const { id } = this.props.match.params;
    this.setState({ id: id });
    localStorage.setItem("PageName", this.breadCrumbTitle)

    Fn_FillListData(this.obj, "detail", this.API_URL + "/Id/"+id);
    
  }
  wrapText(text, maxLength) {
    // if (text.length > maxLength) {
    //   let wrappedText = '';
    //   for (let i = 0; i < text.length; i += maxLength) {
    //     wrappedText += text.substr(i, maxLength) + '<br>';
    //   }
    //   return <th dangerouslySetInnerHTML={{ __html: wrappedText }} />;
    // }
    return <th>kk</th>;
  }


  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
  //  this.props.history.push(`${this.pushFormName}/${this.state.id}`, {});
  this.props.history.push(`${this.pushFormName}`, {});
  };



  //Print the Invoice
  printInvoice = () => {                                                                                                                                                      
    var originalContents = document.body.innerHTML;
    var printableDiv = document.getElementById("printableDiv").innerHTML;
    document.body.innerHTML = printableDiv;
    window.print();
    document.body.innerHTML = originalContents;
  };

  render() {
    const { invoiceDetail } = this.props;

    return (
      <React.Fragment>



        <div className="page-content" id="printableDiv" style={{marginTop:100}}>
         
          <MetaTags>
            <title>Federated Co-Operative Multi-Purpose Society Ltd.</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs title="Invoices" breadcrumbItem="Federated Co-Operative Multi-Purpose Society Ltd." /> */}
            
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title" style={{"textAlign":"center"}} >
                       <div className="mb-2" >
                        <h4>Federated Co-Operative Multi-Purpose Societies Ltd</h4>
                        <h6>P. O. Box GP-21703, GPO, Accra, Ghana.</h6>
                        </div>
                      </div>
                     
                      

                      <div className="invoice-title" style={{"textAlign":"center"}} >
                          <div className="mb-2" >
                        <h4><u>MEMBER SYNOPSIS</u></h4>
                        
                        </div>
                        
                      </div>
                      <hr />
                      
                      
                    


                      <div className="table-responsive">
                        <Table className="table-nowrap" id="some" style={{borderTop:'0 !important'}}>
        
                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">GIN:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MembershipNo}</th>
                              
                              <th className="synopsisth"><b>EMERGENCY CONTACT PERSON INFORMATION</b></th>
                            <th className="synopsisthcol2"></th>
                            </tr>

                           
                          </thead>

                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Date of Joining:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].DateOfJoining}</th>

                              <th className="synopsisth">Full Name:</th>
                              <th className="synopsisthcol2">  {this.state.detail[0].EmergencyContactPerson !== '' && this.state.detail[0].EmergencyContactPerson !== null && this.state.detail[0].EmergencyContactPerson !== 'null' && this.state.detail[0].EmergencyContactPerson !== 0 ?  this.state.detail[0].EmergencyContactPerson : ''}
                              </th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Membership Type:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MembershipType}</th>


                              <th className="synopsisth">Full Address:</th>
                              <th className="synopsisthcol2">  {this.state.detail[0].EmergencyContactPersonAddress !== '' && this.state.detail[0].EmergencyContactPersonAddress !== null && this.state.detail[0].EmergencyContactPersonAddress !== 'null' && this.state.detail[0].EmergencyContactPersonAddress !== 0 ?  this.state.detail[0].EmergencyContactPersonAddress : ''}
                              </th>
                             
                            </tr>
                          </thead>

                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">First Name:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].FirstName}</th>


                              <th className="synopsisth">Post Code:</th>
                              <th className="synopsisthcol2">  {this.state.detail[0].EmergencyContactPersonAddress !== '' && this.state.detail[0].EmergencyContactPersonAddress !== null && this.state.detail[0].EmergencyContactPersonAddress !== 'null' && this.state.detail[0].EmergencyContactPersonAddress !== 0 ?  this.state.detail[0].EmergencyContactPersonAddress : ''}
                              </th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Middle Names:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MiddleName}</th>


                              <th className="synopsisth">Mobile Phone No. 1:</th>
                              <th className="synopsisthcol2">  {this.state.detail[0].EmergencyContactPersonMobileNo1 !== '' && this.state.detail[0].EmergencyContactPersonMobileNo1 !== null && this.state.detail[0].EmergencyContactPersonMobileNo1 !== 'null' && this.state.detail[0].EmergencyContactPersonMobileNo1 !== 0 ?  this.state.detail[0].EmergencyContactPersonMobileNo1 : ''}
                              </th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Surname:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].SurName}</th>
                             

                              <th className="synopsisth">Mobile Phone No. 2:</th>
                              <th className="synopsisthcol2">  {this.state.detail[0].EmergencyContactPersonMobileNo2 !== '' && this.state.detail[0].EmergencyContactPersonMobileNo2 !== null && this.state.detail[0].EmergencyContactPersonMobileNo2 !== 'null' && this.state.detail[0].EmergencyContactPersonMobileNo2 !== 0 ?  this.state.detail[0].EmergencyContactPersonMobileNo2 : ''}
                              </th>
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Private E-Mail:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Email}</th>

                                
                              <th className="synopsisth"><b>{"FATHER'S INFORMATION"}</b></th>
                             <th className="synopsisthcol2"></th>
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Maiden:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MaidenName}</th>


                              <th className="synopsisth">First Name:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].FirstName_Father} </th>
    
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Date of Birth:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].DateOfBirth}</th>


                              <th className="synopsisth">Middle Name:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MiddleName_Father}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Age:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Age}</th>


                              <th className="synopsisth">Surname:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].SurName_Father}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Marital Status:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MaritalStatus}</th>


                              <th className="synopsisth">Hometown :</th>
                              <th className="synopsisthcol2">{this.state.detail[0].HomeTown_Father}</th>
                            
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Blood Group:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].BloodGroup}</th>


                              
                              <th className="synopsisth">Nationality :</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Country}</th>
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                              <th className="synopsisth">Gender:</th>
                              <th className="synopsisthcol2">
                                {/* -₵ */}
                                Male</th>


                                 
                              <th className="synopsisth">Life Status :</th>
                              <th className="synopsisthcol2">{this.state.detail[0].IsLive_Father ==  0 ?  'Alive' : 'Dead'}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                             
                            <th className="synopsisth">Country:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Country}</th>


                              <th className="synopsisth"><b>{"MOTHER'S INFORMATION"}</b></th>
                              <th className="synopsisthcol2"></th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Hometown:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].HomeTown}</th>


                              <th className="synopsisth">First Name:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].FirstName_Mother} </th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Education:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Education}</th>


                              
                              <th className="synopsisth">Middle Name:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MiddleName_Mother}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Profession:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Profession}</th>



                              
                              <th className="synopsisth">Surname:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].SurName_Mother}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">

                            <th className="synopsisth">E-Mail:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Email}</th>


                             

                              <th className="synopsisth">Hometown :</th>
                              <th className="synopsisthcol2">{this.state.detail[0].HomeTown_Mother}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Phone:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MobileNo_SMS}</th>



                             
                              <th className="synopsisth">Nationality :</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Country}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Name of Spouse:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].SpouseName}</th>




                              <th className="synopsisth">Life Status :</th>
                              <th className="synopsisthcol2">{this.state.detail[0].IsLive_Mother ==  0 ?  'Alive' : 'Dead'}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                               <th className="synopsisth">Spouse GIN:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MemberId_Spouse}</th>

                             

                              <th className="synopsisth"><b>{"NOMINEE'S INFORMATION"}</b></th>
                              <th className="synopsisthcol2"></th>
                            </tr>

                            
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth"><b>RESIDENTIAL ADDRESS</b></th>
                            <th className="synopsisthcol2">
                            </th>
                             
                              <th className="synopsisth">First Name:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].FirstName_Nominee} </th>

                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Full Address:  </th>
                              <th className="synopsisthcol2">
  {this.wrapText(this.state.detail[0].Address_Residential, 26)}
</th>

                              <th className="synopsisth">Middle Name:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MiddleName_Nominee}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Post Code:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Pincode_Residential}</th>

                              
                              <th className="synopsisth">Surname:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].SurName_Nominee}</th>
                            </tr>
                          </thead>



                          <thead>
                            <tr className="synopsistr">
                           
                            <th className="synopsisth">Mobile Phone No. 1:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MobileNo1_Residential}</th>

                             
                              

                              
                              <th className="synopsisth">Date of Birth:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].DateOfBirth_Nominee}</th>
                            </tr>
                          </thead>



                          <thead>
                            <tr className="synopsistr">

                            <th className="synopsisth">Mobile Phone No. 2:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MobileNo2_Residential}</th>

                              
                              <th className="synopsisth">Age:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Age_Nominee}</th>
                            </tr>
                          </thead>



                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Telephone Line 1:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].PhoneNo1_Residential}</th>

                              
                              <th className="synopsisth">Gender:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].F_GenderMaster_Nominee == 2 ? 'Female' : 'Male'}</th>
                            </tr>
                          </thead>



                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Telephone Line 2:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].PhoneNo2_Residential}</th>
                           

                              
                              <th className="synopsisth">Relationship:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Relation_Nominee}</th>
                            </tr>
                          </thead>










                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth"><b>BUSINESS ADDRESS</b></th>
                             
                             <th className="synopsisthcol2"></th>
 
                          

                              <th className="synopsisth">Mobile Phone No. 1:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].PhoneNo1_Nominee}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="synopsistr">

                            <th className="synopsisth">Full Address:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Address_Business}</th>


                           
                              <th className="synopsisth">Mobile Phone No. 2:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].PhoneNo2_Nominee}</th>
                            </tr>
                          </thead>



                          <thead>
                            <tr className="synopsistr">
                           

                            <th className="synopsisth">Post Code:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Pincode_Business}</th>


                              

                              
                              <th className="synopsisth">Address:</th>

                              <th className="synopsisthcol2">
                              {this.wrapText(this.state.detail[0].Address_Nominee, 26)}

                              </th>
                              {/* <th>{this.state.detail[0].Address_Nominee}</th> */}
                            </tr>
                          </thead>



                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Mobile Phone No. 1:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MobileNo1_Business}</th>




                          
                              <th className="synopsisth">Post Code:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].PostalCode_Nominee}</th>
                           

                             

                              
                             
                            </tr>
                          </thead>



                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Mobile Phone No. 1:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MobileNo2_Business}</th>


                           

                            <th className="synopsisth"><b>INTRODUCER</b></th>
                           
                            <th className="synopsisthcol2"></th>

                           
                            </tr>
                          </thead>



                          <thead>
                            <tr className="synopsistr">
                            <th className="synopsisth">Telephone Line 1:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].PhoneNo1_Business}</th>
                            
                           
                        
                              
                              <th className="synopsisth">Introducers GIN:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].MemberId_Introducer}</th>
                              
                          
                            </tr>
                          </thead>


                          <thead>
                            <tr className="synopsistr">
                            
                            <th className="synopsisth">Telephone Line 2:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].PhoneNo2_Business}</th>
                            
                            
                              <th className="synopsisth">Introducers Full Name:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Member_Introducer}</th>
                            </tr>
                          </thead>




                          <thead>
                            <tr className="synopsistr">
                              <th></th>
                              <th></th>
                         
                           
                              <th className="synopsisth">Relationship to:</th>
                              <th className="synopsisthcol2">{this.state.detail[0].Relationship_Introducer}</th>
                            </tr>
                          </thead>




                         
                          <thead>
                            <tr className="synopsistr">

                            <th className="synopsisimg"> <img
                          className="rounded me-2"
                          alt="doc1"
                          width="250"
                          height="140"
                          src={this.state.detail[0].ID_ImageURL_1_Thumbnail}
                        /></th>

                        

<th className="synopsisimg"> <img
                          className="rounded me-2"
                          alt="memberimage"
                          width="180"
                          height="150"
                          src={this.state.detail[0].ImageURL_Member2}
                        /></th>



<th className="synopsisimg"> <img
                          className="rounded me-2"
                          alt="doc2"
                          width="250"
                          height="140"
                          src={this.state.detail[0].ID_ImageURL_2_Thumbnail}
                        /></th>

<th className="synopsisth"></th>

                             
                            </tr>
                          </thead>

                         



                          
                          
{/* 
                          <Row>
                        <Col sm="6">
                        
                        <img
                          className="rounded me-2"
                          alt="image"
                          width="250"
                          height="100"
                          src={this.state.detail[0].ImageURL_Member}
                        />
                     
                            
                           
                        </Col>
                       
                      </Row> */}
                     

                        </Table>
                      </div>
                      <div className="d-print-none">



                      <div style={{marginTop : 20}}> <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.printInvoice}
                        >
                          Print
                        </Button></div>
                        <div className="float-end">
                        <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                          Cancel
                        </Button>
                       
                        </div>
{/* <ReactToExcel 
className = "btn"
table =  "some"
filename = "excelfile"
sheet = "sheet 1"
buttontext= "Export"

/> */}
{" "}
{" "}


                          {/* <Link
                            to="#"
                            onClick={this.printInvoice}
                            className="btn btn-success me-1"
                          >
                            <i className="fa fa-print" />
                          </Link>{" "}
                          <Link
                            to="#"
                            className="btn btn-primary w-md"
                          >
                            Send
                          </Link> */}
                        
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            
          </Container>
        </div>
      </React.Fragment>
    );
  }

 
 
 
}
export default compose(container)(MemberSynopsisPrint);
