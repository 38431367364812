import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";
//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
} from "../../store/functions";
import Breadcrumbs from "../../components/Common/Breadcrumb";

class pageList_LedgerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true,
      Ledger : [],
    
      bal : [{

        'Balance' : 0
      }
      ],
      formData : {}
    };
    this.obj = this;
    this.breadCrumbTitle = "Ledger Details Report";
    this.breadCrumbItem = "Ledger Details Report";
    this.modalTitle = "Ledger Details Report";
    this.rtPage_Add = "add_schemeinterestmaster";
    this.rtPage_Edit = "edit_schemeinterestmaster";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Getledger/1?ListFor=0";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
  }
  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem)
    Fn_FillListData(this.obj, "gridData", this.API_URL);
  }
  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
      
        <th>Date</th>
        <th>Name</th>
        <th>Narration</th>
        <th>Cr</th>
        <th>Dr</th>
        {/* <th>Country Code</th>
        <th>ISD Code</th> */}
      </>
    );
  }
  renderGridBody(formData) {
    return (
      <>
        <td>{formData.VoucherDate}</td>
        <td>{formData.Name}</td>

        <td>{formData.Narration}</td>
        <td>{formData.CrAmount}</td>
        <td>{formData.DrAmount}</td>
        {/* <td></td>
        <td></td> */}
      </>
    );
  }
  renderModalBody(selectedFormData) {
    return (
      <>
        <p className="mb-4">
          Name: <span className="text-primary">{selectedFormData.Name}</span>
        </p>
      </>
    );
  }



  GetLedgerName =(event)=> {
   

    var value  =  event.target.value;
    
   
    Fn_FillListData(this.obj, "Ledger", "SearchLedger/0/token?ListFor="+value);

   
  }



  
  GetLedger =(event)=> {
   
    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "gridData", API_WEB_URLS.MASTER + "/0/token/Getledger/1?ListFor="+value);

    Fn_FillListData(this.obj, "bal", "GetBalanceByLedger/0/token?ListFor="+value);
   
  }


  render() {
    return (
<>
<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="9">
                                  {/* <Card>
                                    <CardBody> */}


                                    <Row>
                                        <Col sm="1" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Search</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                          <AvField name="Search" label="" onChange={this.GetLedgerName}  placeholder="Search" errorMessage="Search" validate={{ required: { value: true } }} type="text" className="form-control" />
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">Select Ledger</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="LedgerId" label="" onChange={this.GetLedger}  errorMessage="Select Ledger " validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select Ledger"} />
                                            {this.state.Ledger
                                              ? this.state.Ledger.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                          </Col>
                                      </Row>

                                      <Row>

                                      <Col sm="1" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Balance</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="Balance" label="" value={ this.state.bal[0].Amount } placeholder="balance" disabled  type="text" className="form-control" />
                                        </Col>
                                      </Row>

                       
                                      
                         {/* </CardBody>
                         </Card> */}
                         </Col>
                         </Row>


                         
                         </AvForm>

                         </div> 
                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                         </Container>
                         </div>
                         </React.Fragment>
                         <RCDisplayPage
        //page header paramaters
        //breadCrumbTitle={this.breadCrumbTitle}
       // breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPage>




    
   
     
      </>
    );
  }
}
export default compose(container)(pageList_LedgerDetails);
