/* eslint-disable */
import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData
} from "../../store/functions";

class pageReport_CashFlowStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      BoldToken : [{
        Token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImluZm9Ac2hpbmV3ZWxsaW5ub3ZhdGlvbi5jb20iLCJuYW1laWQiOiIxIiwidW5pcXVlX25hbWUiOiIzMTZiYmI3Ny1kOWIxLTQwNWYtYWRiYy01YzYyN2I0M2YwOWQiLCJJUCI6IjQ5LjQzLjE3OS4xMjEiLCJpc3N1ZWRfZGF0ZSI6IjE2ODI5Mzk4OTYiLCJuYmYiOjE2ODI5Mzk4OTYsImV4cCI6MTY4MzU0NDY5NiwiaWF0IjoxNjgyOTM5ODk2LCJpc3MiOiJodHRwOi8vc2hpbmV3ZWxsc29mdHRlY2guY28uaW46NTA2OTMvcmVwb3J0aW5nL3NpdGUvc2l0ZTEiLCJhdWQiOiJodHRwOi8vc2hpbmV3ZWxsc29mdHRlY2guY28uaW46NTA2OTMvcmVwb3J0aW5nL3NpdGUvc2l0ZTEifQ.bvID9MEES0z_VWKtAxnrrQXITu1PvvhDPwvTrMgD3us'
      }],
      VoucherId : 411
  
    };
    this.obj = this;
    this.PageName =  "Cashflow Statement"
   
    

    //Event Binding

  }
  componentDidMount() {

    localStorage.removeItem("PageName");
    localStorage.setItem("PageName", this.PageName);
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
  }
  
  
  render() {

    var parameters = [{
      name: 'Id',
      values: [this.state.VoucherId]
      }
    ];

    return (
      <div className="page-content" style={{height:'700px', marginTop:'116px'}}>
        <BoldReportViewerComponent id="reportviewer-container"
reportServiceUrl = {'http://shinewellsofttech.co.in:50693/reporting/reportservice/api/Viewer'}
reportServerUrl= {'http://shinewellsofttech.co.in:50693/reporting/api/site/site1'}
serviceAuthorizationToken = {'bearer '+this.state.BoldToken[0].Token}
reportPath = {'/Ghana/CashFlowSatetment'}
parameters = {parameters}
>
</BoldReportViewerComponent>
      
      </div>
    );
  }
}
export default compose(container)(pageReport_CashFlowStatement);
