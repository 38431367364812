import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue, Fn_AddEditDataN } from "../../store/functions";
import { bind } from "lodash";




class AMC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      ed : true,
      passedSteps: [1],
      //dropdowns
      MemberName: [
        {
          Name : ""
        }
      ],
     
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      ShareType : true,
      ShareTypeThriftM : false,
      ShareTypeThriftD : false,
      ShareTypeThriftW : false,
      accountlimit : [{}]
    
      
     



    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "AMC ";
    this.breadCrumbTitle = "AMC ";
    this.breadCrumbItem = " " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/AdvisorSelectById";
    this.API_URL_SAVE = API_WEB_URLS.AdvisorMaster + "/0/token";
    this.pushFormName = "/agentdetails";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.onchangethriftTypeM  = this.onchangethriftTypeM.bind(this);
    this.onchangethriftTypeD  = this.onchangethriftTypeD.bind(this);
    this.onchangethriftTypeW  = this.onchangethriftTypeW.bind(this);
  }




  onchangethriftTypeM(){

    this.setState({ShareTypeThriftD : false , ShareTypeThriftW : false,ShareTypeThriftM : true })

  }

  onchangethriftTypeD(){

    this.setState({ShareTypeThriftM : false , ShareTypeThriftW : false,ShareTypeThriftD : true})

  }


  onchangethriftTypeW(){

    this.setState({ShareTypeThriftD : false , ShareTypeThriftM : false,ShareTypeThriftW : true})

  }




  componentDidMount() {
    const { id } = this.props.match.params;
    //Filling DropDowns

    Fn_FillListData(this.obj, "accountlimit", API_WEB_URLS.MASTER + "/0/token/LevelAccountLimit/Id/"+id);
    Fn_FillListData(this.obj, "standingorders", API_WEB_URLS.MASTER + "/0/token/StandingOrdersByMember/Id/"+id);
     
    localStorage.setItem("PageName", this.breadCrumbItem)
    
    if (id) {
      this.setState({ id: id  });
    
    }
     else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {


   

    let vformData = new FormData();
   
    vformData.append("AccountLimit", this.state.AccountLimitValue);
    vformData.append("Id", this.state.id);

    
    Fn_AddEditDataN(this.obj, { arguList: { id: 0, formData: vformData } }, "UpdateAccountLimit/0/token", "/masters-membershipmodify", true);
   
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }













  render() {



    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                  
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="12">
                                  <Card>
                                    <CardBody>
                                    <Row>
                                    <Col sm="2">
                                 <label htmlFor="membershipType" className="col-form-label">Level 2 Account Limit : </label>
                               </Col>
                               <Col lg="3">
                                 <AvField name="AccountLimit"  value={this.state.accountlimit[0].AccountLimit} onChange={(e)=>Fn_ChangeStateValue(this.obj,"AccountLimitValue", e.target.value)}   type="text" className="form-control" />
                               </Col>
                           

                          </Row>
                                
                          
                          
                                <h4>Transfer to Shares:</h4>
                             
                              <Row>
                                <Col sm="2">
                                  <label htmlFor="membershipType" className="col-form-label">Amount</label>
                                </Col>
                                <Col lg="3">
                                  <AvField name="SharesAmount"   value={this.state.formData.SharesAmount} placeholder="Enter Share Amount" errorMessage="Enter Share Amount"  type="text" className="form-control" />
                                </Col>
                              </Row>


                              <Row>
                                <Col sm="2">
                                  <label htmlFor="membershipType" className="col-form-label">Monthly</label>
                                </Col>
                                <Col lg="3">
                                  <AvField name="SharesType"  disabled checked={this.state.ShareType} value={this.state.ShareType}   type="checkbox" className="form-control" />
                                </Col>
                              </Row>





                              <h4>Transfer to Thrift Account:</h4>
                             
                             <Row>
                               <Col sm="2">
                                 <label htmlFor="membershipType" className="col-form-label">Amount</label>
                               </Col>
                               <Col lg="3">
                                 <AvField name="ThriftAmount"   value={this.state.formData.ThriftAmount} placeholder="Enter Thrift Amount" errorMessage="Enter Thrift Amount"  type="number" className="form-control" />
                               </Col>
                             </Row>
                             <Row>
                               <Col sm="2">
                                 <label htmlFor="membershipType" className="col-form-label">Daily</label>
                               </Col>
                               <Col lg="3">
                                 <AvField name="SharesType" checked={this.state.ShareTypeThriftD} value={this.state.ShareTypeThriftD} onChange={this.onchangethriftTypeD}   type="checkbox" className="form-control" />
                               </Col>
                             </Row>

                             <Row>
                               <Col sm="2">
                                 <label htmlFor="membershipType" className="col-form-label">Weekly</label>
                               </Col>
                               <Col lg="3">
                                 <AvField name="SharesType" checked={this.state.ShareTypeThriftW} value={this.state.ShareTypeThriftW}  onChange={this.onchangethriftTypeW} type="checkbox" className="form-control" />
                               </Col>
                             </Row>

                             <Row>
                               <Col sm="2">
                                 <label htmlFor="membershipType" className="col-form-label">Monthly</label>
                               </Col>
                               <Col lg="3">
                                 <AvField name="SharesType" checked={this.state.ShareTypeThriftM} value={this.state.ShareTypeThriftM} onChange={this.onchangethriftTypeM}   type="checkbox" className="form-control" />
                               </Col>
                             </Row>



                             <h4>Standing Orders:</h4>


                             {this.state.standingorders
                                              ? this.state.standingorders.map(
                                                  (option, key) => (
                                                    <>
                                                    <Row>
                               <Col sm="2">
                                 <label htmlFor="membershipType" className="col-form-label">GIN/EIN</label>
                               </Col>
                               <Col lg="3">
                                 <AvField name={'GIN'+key}   value={option.GIN}    type="number" disabled className="form-control" />
                               </Col>
                             </Row>
                             


                             <Row>
                               <Col sm="2">
                                 <label htmlFor="membershipType" className="col-form-label">Recipient</label>
                               </Col>
                               <Col lg="3">
                               <AvField name={'Rec'+key}   value={option.Receipent}   type="number" disabled className="form-control" />
                               </Col>
                             </Row>


                             
                             <Row>
                               <Col sm="2">
                                 <label htmlFor="membershipType" className="col-form-label">Amount</label>
                               </Col>
                               <Col lg="3">
                               <AvField name={'Amount'+key}   value={option.Amount}   type="number" disabled className="form-control" />
                               </Col>
                             </Row>
                                                    </>
                                                  ) 
                                                )
                                              : null}
                             
                             




                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              ACTIVATE
                            </Button>{" "}
                            <Button type="reset" color="secondary">
                              STOP
                            </Button>{" "}
                            <Button type="reset" color="secondary">
                              REMOVE
                            </Button>
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(AMC);
