import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPageMem from "../../components/Common/RCDisplayPageMem";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData
  
} from "../../store/functions";

class pageList_VoucherPaid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true,
      isEdit : false,
      isDelete : false ,
      roleid : 0
     
    };
    this.obj = this;
    this.breadCrumbTitle = "Voucher Paid";
    this.breadCrumbItem = "Voucher Paid";
    this.modalTitle = "Voucher Paid";
    this.rtPage_Add = "";
    this.rtPage_Edit = "";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/VoucherPaid";

    //Event Binding
   
  }


 
  componentDidMount() {

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Voucher Paid")
    const obj = JSON.parse(localStorage.getItem("authUser"));


    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role  , roleid:obj.roleid });

    

    Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/0");
   
    
  }





  renderGridHeader() {
    return (
      <>
        {/* <th>Membership Type</th> */}
        <th>Date</th>
        
        <th>Party Name</th>
        <th>CommissionAmount</th>
        <th>MainAmount</th>
      
      </>
    );
  }



  renderGridBody(formData) {

   


    return (
      <>
        
        <td>{formData.Date}</td>
        <td>{formData.PartyName}</td>
        <td>{formData.CommissionAmount}</td>
        <td>{formData.MainAmount}</td>
       
     
      </>
    );
  }



  renderModalBody(selectedFormData) {
 
     
  

    return (
      <>
       
      </>
    );
  }
  render() {
    return (
      <>
      <div className="page-content">
      <RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={false}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatamember}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
       
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        searchmember = {this.searchmember}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={this.state.isEdit}
        isDelete={this.state.isDelete}
      ></RCDisplayPageMem>
</div>

</>

    );
  }
}
export default compose(container)(pageList_VoucherPaid);
