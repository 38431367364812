import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";

class pageAddEdit_SponsorInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Sponsor Master";
    this.breadCrumbTitle = "Sponsor Masters";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.pushFormName = "/memberships";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
  }
  componentDidMount() {
    //Filling DropDowns
    Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");
    Fn_FillListData(this.obj, "maritalStatus", API_WEB_URLS.MASTER + "/0/token/MaritalStatus/Id/0");
    Fn_FillListData(this.obj, "bloodGroup", API_WEB_URLS.MASTER + "/0/token/BloodGroup/Id/0");
    Fn_FillListData(this.obj, "gender", API_WEB_URLS.MASTER + "/0/token/Gender/Id/0");
    Fn_FillListData(this.obj, "education", API_WEB_URLS.MASTER + "/0/token/Education/Id/0");
    Fn_FillListData(this.obj, "profession", API_WEB_URLS.MASTER + "/0/token/Profession/Id/0");
    Fn_FillListData(this.obj, "relationShip", API_WEB_URLS.MASTER + "/0/token/Relationship/Id/0");
    Fn_FillListData(this.obj, "relationShip", API_WEB_URLS.MASTER + "/0/token/Relationship/Id/0");
    Fn_FillListData(this.obj, "nationality", API_WEB_URLS.MASTER + "/0/token/Country/Id/0");
    Fn_FillListData(this.obj, "typeofRegCert", API_WEB_URLS.MASTER + "/0/token/IDDocumentType/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {
    if (!this.state.id)
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, name: formData.Name } },
        this.API_URL,
        this.pushFormName
      );
    else
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, name: formData.Name } },
        this.API_URL,
        this.pushFormName
      );
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Scheme Master</h4>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                              <Row>
                                <Col lg="6">
                                    <Label for="basicpill-lastname-input2">
                                      Sponsor Information
                                    </Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <AvField name="searchSponsor" label="Search Sponsor" value={this.state.formData.FirstName} placeholder="Enter Search Sponsor" errorMessage="Enter Search Sponsor" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="sponsorName" label="Select Sponsor Name" value={this.state.formData.membershipType} errorMessage="Select Sponsor Name" validate={{ required: { value: true } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select Select Sponsor Name"} />
                                    {this.state.membershipType
                                      ? this.state.membershipType.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                </Col> 
                                <Row>
                                <Col lg="6">
                                    <Label for="basicpill-lastname-input2">
                                      Advisor Details
                                    </Label>
                                </Col>
                              </Row>
                                <Col lg="6">
                                  <AvField name="cader" label="Select Cader" value={this.state.formData.membershipType} errorMessage="Select Cader" validate={{ required: { value: true } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select Cader"} />
                                    {this.state.membershipType
                                      ? this.state.membershipType.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                </Col>
                                <Col lg="6">
                                  <AvField name="code" label="Code" value={this.state.formData.LastName} placeholder="Enter Code" errorMessage="Enter Code" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="advisor" label="Advisor" value={this.state.formData.MaidenName} placeholder="Enter Advisor" errorMessage="Enter Advisor" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="contactNo" label="Contact No." value={this.state.formData.MaidenName} placeholder="Enter Contact No." errorMessage="Enter Contact No." validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="address" label="Address" value={this.state.formData.MaidenName} placeholder="Enter Address" errorMessage="Enter Address" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="savingAccount" label="Saving Account" value={this.state.formData.MaidenName} placeholder="Enter Saving Account" errorMessage="Enter Saving Account" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="receiptNo" label="Receipt No" value={this.state.formData.MaidenName} placeholder="Enter Receipt No" errorMessage="Enter Receipt No" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="browseMember" label="Browse Member" value={this.state.formData.MaidenName} placeholder="Enter Browse Member" errorMessage="Enter Browse Member" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="pan" label="PAN" value={this.state.formData.MaidenName} placeholder="Enter PAN" errorMessage="Enter PAN" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="emailId" label="Email ID" value={this.state.formData.MaidenName} placeholder="Enter Email ID" errorMessage="Enter Email ID" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="dateOfJoining" label="Date of Joining" value={this.state.formData.DateOfJoining} placeholder="Enter Date of Joining" errorMessage="Enter Date of Joining" validate={{ required: { value: true } }} type="date" defaultValue="2019-08-19" className="form-control" />
                                </Col>
                                <Col lg="6">
                                  <AvField name="amount" label="Amount" value={this.state.formData.MaidenName} placeholder="Enter Amount" errorMessage="Enter Amount" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                              </Row>
                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Submit
                            </Button>{" "}
                            <Button type="reset" color="secondary">
                              Cancel
                            </Button>
                          </div>
                            </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_SponsorInformation);
