import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";
import { bind } from "lodash";




class pageAddEdit_Agent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      ed : true,
      passedSteps: [1],
      //dropdowns
      MemberName: [
        {
          Name : ""
        }
      ],
     
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
    
      
     



    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Agent ";
    this.breadCrumbTitle = "Agent ";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/AdvisorSelectById";
    this.API_URL_SAVE = API_WEB_URLS.AdvisorMaster + "/0/token";
    this.pushFormName = "/agentdetails";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
  }




  componentDidMount() {
    //Filling DropDowns
      Fn_FillListData(this.obj, "cader", API_WEB_URLS.MASTER + "/0/token/Cader/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id , ed : false });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  fun() {

  }
  btnSave_onClick(event, formData) {

    let vformData = new FormData();
   
    vformData.append("MembershipNo", formData.MembershipNo);
    vformData.append("MemberName", this.state.MemberName[0].Name);
    vformData.append("JOB", formData.cader);
    vformData.append("UserName", formData.UserName);
    vformData.append("UserPassword", formData.UserPassword);
    vformData.append("F_MemberMaster", this.state.MemberName[0].Id);

    if (!this.state.id)

    Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true, "Id", this.fun);
    else
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, formData: vformData } },
        this.API_URL_SAVE,
        this.pushFormName,
        true
      );
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetMemberName =(event)=> {

    

   

    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "MemberName", API_WEB_URLS.MASTER + "/0/token/SearchMem/1/Mem?ListFor="+value);




    

  }











  render() {



    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Agent Create</h4>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Information</h4>
                             
                              <Row>

                              <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Membership No.</label>
                                        </Col>
                                <Col lg="6">

                                <AvField name="membershipno" onChange={this.GetMemberName}  value={this.state.formData.MembershipNo} placeholder="Enter membershipno" errorMessage="Enter membershipno" validate={{ required: { value: true } }} type="text" className="form-control" />
                                 
                                </Col>
</Row>


 

<Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Member Name</label>
                                        </Col>
                                <Col lg="6">


                                <AvField name="membername"  value={this.state.MemberName[0].Name} placeholder=" Member Name" errorMessage=" Sender Name"  type="text" disabled className="form-control" />
                                  </Col>


</Row>





<Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maritalStatus" className="col-form-label">JOB</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="cader" label="" value={this.state.formData.cader === null ? '-1'   : this.state.formData.cader} errorMessage="Select Job" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.cader
                                              ? this.state.cader.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col> 
                                      </Row>


<Row>

<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Agent/Staff Number</label>
          </Col>
  <Col lg="6">


  <AvField name="agentcode"  value={this.state.formData.AgentCode} placeholder="Agent / Staff Number"   type="text" disabled className="form-control" />
    </Col>


</Row>






<Row>

<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">UserName (*UserName should be unique.)</label>
          </Col>
  <Col lg="6">


  <AvField name="UserName"  value={this.state.formData.UserName} placeholder="UserName"   type="text"  className="form-control" disabled={this.state.ed}/>
    </Col>


</Row>





<Row>

<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">UserPassword</label>
          </Col>
  <Col lg="6">


  <AvField name="UserPassword"  value={this.state.formData.UserPassword} placeholder="UserPassword"   type="text"  className="form-control" disabled={this.state.ed}/>
    </Col>


</Row>













                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Save
                            </Button>{" "}
                            <Button type="reset" color="secondary">
                              Cancel
                            </Button>
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_Agent);
