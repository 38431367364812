/* eslint-disable */


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";
//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_ChangeStateValue
} from "../../store/functions";
import Breadcrumbs from "../../components/Common/Breadcrumb";


function formatDate(inputDate) {
  const date = new Date(inputDate);
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (adding 1 since it's zero-based) and pad with leading zero if necessary
  const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
  const year = date.getFullYear(); // Get full year

  return `${month}/${day}/${year}`;
}

function printInvoice2 ()  {
  alert("Hi");
    }

class pageList_MemberJoiningReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      isProgress:true,
      formData : {},
      modal_fullscreen : false, 
      gridDatanominalMFG : [{
        
      }],
      gridDataMFG  : [{
        
      }],
      BoldToken : [{
        Token : ''
      }],

    };
    this.obj = this;
    this.breadCrumbTitle = "Member Joining Report";
    this.breadCrumbItem = "Member Joining Report";
    this.modalTitle = "Member Joining Report";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    
    this.btnSave_onClick  =  this.btnSave_onClick.bind(this);
this.renderGridBody =  this.renderGridBody.bind(this);
this.tog_fullscreen  =  this.tog_fullscreen.bind(this);
   

  }

  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem)

    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
    
  }

  getaccountsdata (){
    alert(this.state.Id);
  }


  print = () => {          
    
    this.setState({modal_fullscreen : true})

    
  };

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss();
   
  }


  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

   
  renderGridHeader() {
    return (
      <>
      
        <th>  Membership No.</th>
        <th>Full Name</th>
        <th>Maiden Name</th>
        <th>Gender</th>
        <th>Phone No.</th>
        <th>Joining Date</th>
        {/* <th>Country Code</th>
        <th>ISD Code</th> */}
      </>
    );
  }


 





  renderGridBody(formData) {
    
    return (
      <>
        <td >
          {/* <a  onClick={() => {
              this.setState({
                Id: formData.Id,
              }, () => {this.getaccountsdata();});
              
            }}> */}
            


            <a   onClick={() =>  {this.props.history.push(`/membersynopsisprint/${formData.Id}`, {})}}>
                          
          {formData.MembershipNo}</a></td>
        <td>{formData.FullName}</td>
        <td>{formData.MaidenName}</td>
        <td>{formData.Gender}</td>
        <td>{formData.MobileNo_SMS}</td>
        <td>{formData.DateOfJoining}</td>
        {/* <td></td>
        <td></td> */}
      </>
    );
  }


  renderModalBody(selectedFormData) {

    

    return (
      <>
        <p className="mb-4">
          Name: <span className="text-primary">{selectedFormData.Id}</span>
        </p>
      </>
    );
  }


  

  btnSave_onClick (event, formData)  {

    console.log(formData.ToDate);

    const obj = JSON.parse(localStorage.getItem("authUser"));

   


    let vformData = new FormData();
   
    vformData.append("FromDate", formData.FromDate);
    vformData.append("ToDate", formData.ToDate);
    vformData.append("F_UserMaster", obj.roleid == 4 ? 0 :  obj.uid);
    vformData.append("F_MembershipType", 1);
   
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, "MemberJoiningReport/0/token", "gridData", true);
    

    let vformData2 = new FormData();
   
    vformData2.append("FromDate", formData.FromDate);
    vformData2.append("ToDate", formData.ToDate);
    vformData2.append("F_UserMaster", obj.roleid == 4 ? 0 :  obj.uid);
    vformData2.append("F_MembershipType", 2);

    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData2 } }, "MemberJoiningReport/0/token", "gridDatanominal", true);
  




    
    

    let vformData3 = new FormData();
   
    vformData3.append("FromDate", formData.FromDate);
    vformData3.append("ToDate", formData.ToDate);
    vformData3.append("F_UserMaster", obj.uid);
    vformData3.append("F_MembershipType", 4);

    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData3 } }, "MemberJoiningReport/0/token", "gridDatanon", true);
  
  


  }

  



  


  render() {

    var parameters = [
      {
        name: 'FromDate',
        values: [formatDate(this.state.FromDate)] // Wrap the date string in an array to represent a list
      },
      {
        name: 'ToDate',
        values: [formatDate(this.state.ToDate)] // Wrap the date string in an array to represent a list
      },
      {
        name: 'F_UserMaster',
        values: [0] // Converted to a string
      },
      {
        name: 'F_MembershipType',
        values: [0] // Converted to a string
      }
    ];


    return (
<>
<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="9">
                                  {/* <Card>
                                    <CardBody> */}


                                    <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">From Date</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="FromDate" label="" onChange={(e)=> Fn_ChangeStateValue(this.obj , "FromDate" , e.target.value)} value={this.state.formData.FromDate} placeholder="From Date"  type="date"  className="form-control" />
                                        </Col>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="age" className="col-form-label">ToDate</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="ToDate" label="" onChange={(e)=> Fn_ChangeStateValue(this.obj , "ToDate" , e.target.value)} value={this.state.formData.ToDate} placeholder="To Date"  type="date"  className="form-control" />
                                          </Col>
                                      </Row>

          

                       
                                      
                         {/* </CardBody>
                         </Card> */}
                         </Col>
                         </Row>
                         <div>
                          <Button
                          type="submit"
                          color="primary"
                        
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>

                        
                          </div>

                         
                         </AvForm>

                         </div> 
                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                         </Container>
                         </div>
                         </React.Fragment>





                         <React.Fragment>
       



                         
                        
                       
                         </React.Fragment>



                         <RCDisplayPage
        //page header paramaters
       
       Isbreadcrumb = {true}
       breadCrumbTitle={this.breadCrumbTitle}
       breadcrumbItem={"Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPage>



<React.Fragment>
       
                         </React.Fragment>



<RCDisplayPage
        //page header paramaters
       
       Isbreadcrumb = {true}
       breadCrumbTitle={this.breadCrumbTitle}
       breadcrumbItem={"Nominal Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatanominal}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPage>







<React.Fragment>
       
                         </React.Fragment>



<RCDisplayPage
        //page header paramaters
       
       Isbreadcrumb = {true}
       breadCrumbTitle={this.breadCrumbTitle}
       breadcrumbItem={"Non-Member"}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatanon}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPage>



       
          <Container fluid>
            {/* Render Breadcrumb */}
           
                <Card >
                  <CardBody>
                    <CardTitle className="h4">
                    
                    </CardTitle>
                    <div className="d-print-none">
                        <div className="float-end">
<Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.print}
                        >
                          Print
                        </Button>
                        </div>
                        </div>
                    <div>
                     
                      <Modal
                        size="xl"
                        isOpen={this.state.modal_fullscreen}
                        toggle={this.tog_fullscreen}
                        className="modal-fullscreen"
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title mt-0"
                            id="exampleModalFullscreenLabel"
                          >
                           Print
                          </h5>
                          <button
                            onClick={() =>
                              this.setState({ modal_fullscreen: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                         
                
                    
                      <BoldReportViewerComponent id="reportviewer-container"
                     reportServiceUrl = {'http://shinewellsofttech.co.in:50693/reporting/reportservice/api/Viewer'}
                     reportServerUrl= {'http://shinewellsofttech.co.in:50693/reporting/api/site/site1'}
                     serviceAuthorizationToken = {'bearer '+this.state.BoldToken[0].Token}
                     reportPath = {'/Ghana/MembersJoiningReport'}
                     printMode = {true}
                     parameters ={parameters}
                  
                     >
                     </BoldReportViewerComponent>
                 

                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={this.tog_fullscreen}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                      
                        </div>
                      </Modal>
                    </div>
                  </CardBody>
                </Card>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>



                        </CardBody>
                        </Card>
                        </Col>
                        </Row>
                        </Container>
                       
                     


    
   
     
      </>
    );
  }
}
export default compose(container)(pageList_MemberJoiningReport);
