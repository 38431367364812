import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPageMem from "../../components/Common/RCDisplayPageMem";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import {
  Card,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAlert,
  UncontrolledDropdown,
  Row,
  Col
} from "reactstrap"
import { container } from "../../store/Containers/cntCommon";
import { Link } from "react-router-dom"

class pageList_HelpTopics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true,
      isEdit : false,
      isDelete : false ,
      roleid : 0,
      file_collapse: false,
      file_collapse2: false,
      file_collapse3: false,
      file_collapse4: false
     
    };
    this.obj = this;
    this.breadCrumbTitle = "Help Topics";
    this.breadCrumbItem = "Help Topics";
    this.modalTitle = "Help Topics";
    this.rtPage_Add = "";
    this.rtPage_Edit = "";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/VoucherPaid";
    this.file = this.file.bind(this);
    this.file2 = this.file2.bind(this);
    this.file3 = this.file3.bind(this);
    this.file4 = this.file4.bind(this);
    //Event Binding
   
  }
  file() {
    this.setState({
      file_collapse: !this.state.file_collapse,
    })
  }

  file2() {
    this.setState({
      file_collapse2: !this.state.file_collapse2,
    })
  }

  file3() {
    this.setState({
      file_collapse3: !this.state.file_collapse3,
    })
  }


  file4() {
    this.setState({
      file_collapse4: !this.state.file_collapse4,
    })
  }

 
  componentDidMount() {

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Help Topics")
    const obj = JSON.parse(localStorage.getItem("authUser"));

    
  }





  renderGridHeader() {
    
  }



  renderGridBody(formData) {


  }



  renderModalBody(selectedFormData) {
 
     
  

    return (
      <>
       
      </>
    );
  }
  render() {
    return (
      <>
      <div className="page-content">
   
      <Row style={{marginTop:100}}>
              <Col lg="12">
                <Card>
                  <CardBody>


                  <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        onClick={this.file}
                        to="#"
                      >
                        <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                        How to view passcode{" "}
                        <i
                          className={
                            this.state.file_collapse
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                      <Collapse isOpen={this.state.file_collapse}>
                        <div className="card border-0 shadow-none ps-2 mb-0">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto">Use passcode in Global Settings after selecting the view button in the Members Register.</span>
                              </Link>
                            </li>
                          
                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  </li>
             
                </ul>


                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        to="#"
                      >
                        <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                        How to block an account fully{" "}
                        <i
                          className={
                            this.state.file_collapse
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                     
                    </div>
                  </li>
             
                </ul>


                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        to="#"
                      >
                        <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                        How to block an account partially.{" "}
                        <i
                          className={
                            this.state.file_collapse
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                     
                    </div>
                  </li>
             
                </ul>

                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        onClick={this.file2}
                        to="#"
                      >
                        <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                        How to set individual account limit{" "}
                        <i
                          className={
                            this.state.file_collapse2
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                      <Collapse isOpen={this.state.file_collapse2}>
                        <div className="card border-0 shadow-none ps-2 mb-0">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto">Under MODIFICATION Menu, open to Member Records. Under the column AMC, select the account that is to be restricted. Enter the amount of the limit, then SUBMIT.</span>
                              </Link>
                            </li>
                          
                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  </li>
             
                </ul>



                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        onClick={this.file3}
                        to="#"
                      >
                        <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                        How to set or change the minimum account balance{" "}
                        <i
                          className={
                            this.state.file_collapse3
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                      <Collapse isOpen={this.state.file_collapse3}>
                        <div className="card border-0 shadow-none ps-2 mb-0">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto">Go to Master, open Global Settings, enter the new amount, then save.</span>
                              </Link>
                            </li>
                          
                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  </li>
             
                </ul>


                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        onClick={this.file4}
                        to="#"
                      >
                        <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                        How to print a report{" "}
                        <i
                          className={
                            this.state.file_collapse4
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                      <Collapse isOpen={this.state.file_collapse4}>
                        <div className="card border-0 shadow-none ps-2 mb-0">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto">Use the EXPORT button at the top of the page. Download the document to be printed in PDF or the format desired. Open the downloaded document, and issue the Print Command.</span>
                              </Link>
                            </li>
                          
                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  </li>
             
                </ul>

                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        to="#"
                      >
                        <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                        How to re-set a forgotten passcode for a member.{" "}
                        <i
                          className={
                            this.state.file_collapse
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                     
                    </div>
                  </li>
             
                </ul>


                    </CardBody>
                    </Card>
                    </Col>
                    </Row>



             


   

  
                   
                

  

      </div>

</>

    );
  }
}
export default compose(container)(pageList_HelpTopics);
