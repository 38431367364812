import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
import SweetAlert from "react-bootstrap-sweetalert"
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";
import { bind } from "lodash";




class pageAddEdit_AddStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
   
      //dropdowns
      MemberDetail: [{
        Name : '',
        StaffNumber : ''
      }],
   
      prov : false,
    
     
      success_msg : false
    
      
     



    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Add Staff ";
    this.breadCrumbTitle = "Add Staff ";
    this.breadCrumbItem =  this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.TransactionDeposit + "/0/token";
    this.pushFormName = "#";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno =  this.syno.bind(this);
  }




  componentDidMount() {
    //Filling DropDowns
      
    Fn_FillListData(this.obj, "paytype", API_WEB_URLS.MASTER + "/0/token/Paytype/Id/0");
    Fn_FillListData(this.obj, "workcategory", API_WEB_URLS.MASTER + "/0/token/WorkCategory/Id/0");
    Fn_FillListData(this.obj, "salaryclass", API_WEB_URLS.MASTER + "/0/token/SalaryClass/Id/0");
    Fn_FillListData(this.obj, "salarylevel", API_WEB_URLS.MASTER + "/0/token/SalaryLevel/Id/0");



    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }


  btnSave_onClick(event, formData) {



    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });

    // let vformData = new FormData();
   
    // vformData.append("F_MemberAccountMaster", this.state.Accountdetail[0].Id);
    // vformData.append("Amount", formData.Amount);
    // vformData.append("TransactionType", 2);
    // vformData.append("Narration", formData.narration);
    // vformData.append("F_UserMaster", this.state.aid);


    
    
    // if (!this.state.id)

   

    // Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true);




    // else
    //   Fn_AddEditData(
    //     this.obj,
    //     { arguList: { id: this.state.id, name: formData.Name } },
    //     this.API_URL,
    //     this.pushFormName
    //   );

    //   this.setState({
    //     formData: {
    //       'AccountValue' : 0,
    //       'membershipno' : '',
    //       'MembershipTypeId' : 0,
    //       'Amount' : '',
    //       'TransactionTypeValue' : 0,
    //       'narration' : ''
    //     },
    //     success_msg : true

    //   });




  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();

    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetAccountDetail =(event)=> {
    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "Accountdetail", API_WEB_URLS.MASTER + "/0/token/ListOfAccountsByMember/Id/"+value);

  }



  syno () {

    this.setState({
      success_msg : false
    });
        

      };



 


  GetMemberName =(event)=> {
   

    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "MemberDetail", "SearchMembership/0/token/"+event.target.value+"?F_MembershipTypeMaster=0"

    );


    
    

  }

 







  render() {



    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Add Staff </h4>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                              
                         
                                      <Row>
<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Membership No.</label>
          </Col>
  <Col lg="6">

  <AvField name="membershipno" onChange={this.GetMemberName}  value={this.state.formData.membershipno} placeholder="Enter membershipno" errorMessage="Enter membershipno" validate={{ required: { value: true } }} type="text" className="form-control" />
   
  </Col>
</Row>

<Row>

<Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Staff Number</label>
                                        </Col>
                                <Col lg="6">


                                <AvField name="StaffNumber"   value={this.state.MemberDetail[0].StaffNumber} placeholder="Staff No." errorMessage="Enter Account No."  type="text"  className="form-control" disabled />
                                  </Col>
              </Row>




              <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Full Name</label>
                                        </Col>
                                        <Col sm="6">


                                        <AvField name="FullName"   value={this.state.MemberDetail[0].Name} placeholder="Full Name"  type="text" className="form-control"  disabled/>
                                        
                                        </Col>
                                      </Row>



                                    <Row>

                                    <Col sm="4">
                                                <label htmlFor="membershipType" className="col-form-label">Select Work Category</label>
                                              </Col>
                                      <Col lg="6">

                                       <AvField name="F_WorkCategory" label="" value={this.state.formData.F_WorkCategory} errorMessage="Select Work Category" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.workcategory
                                              ? this.state.workcategory.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                      
                                      </Col>
                                    </Row>


                                    <Row>

                                    <Col sm="4">
                                                <label htmlFor="membershipType" className="col-form-label">Select Salary Level</label>
                                              </Col>
                                      <Col lg="6">

                                       <AvField name="F_SalaryLevel" label="" value={this.state.formData.F_SalaryLevel} errorMessage="Select Salary Level" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.salarylevel
                                              ? this.state.salarylevel.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                      
                                      </Col>

                                    </Row>




  <Row>

                                    <Col sm="4">
                                                <label htmlFor="membershipType" className="col-form-label">Select Salary Class</label>
                                              </Col>
                                      <Col lg="6">

                                       <AvField name="F_SalaryClass" label="" value={this.state.formData.F_SalaryLevel} errorMessage="Select Salary Class" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.salaryclass
                                              ? this.state.salaryclass.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                      
                                      </Col>

                                    </Row>



                                    



                                    <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Select Pay Type</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField  name="F_PayType" label="" value={this.state.formData.F_PayType} errorMessage="Select Pay Type"  type="select" className="form-select"  >
                                            <option value={0} defaultValue label={"Select"} />
                                            {this.state.paytype
                                              ? this.state.paytype.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>



                                      <Row>

<Col sm="4">
            <label htmlFor="membershipType" className="col-form-label">Salary Value</label>
          </Col>



  <Col lg="6">
  <AvField name="SalaryValue"  value={this.state.formData.SalaryValue} placeholder="Salary Value"   type="number" className="form-control" />
    </Col>


</Row>

                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Save
                            </Button>{" "}

                            {this.state.success_msg ? (
                      <SweetAlert
                        title="Transaction Successful!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                       
                      </SweetAlert>
                    ) : null}
                            <Button type="reset" color="secondary">
                              Cancel
                            </Button>
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_AddStaff);
