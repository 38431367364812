import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { apiError, loginUser, socialLogin } from "../../store/actions";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import AuthCode from "react-auth-code-input";
import mainimg from '../Dashboard/main.jpg';


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      prov : false,
      type : "password",
      formData: {},
      prov: false,
      showPassword : true, 
    };
    this.obj = this;
   
    this.API_URL_SAVE =   "UserLogin/0/token";
    this.pushFormName = "/persontoperson";
    this.show =  this.show.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  componentDidMount() {
    this.props.apiError("");
   
  }
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
     }
     // else {
    //   if (document.cancelFullScreen) {
    //     document.cancelFullScreen()
    //   } else if (document.mozCancelFullScreen) {
    //     document.mozCancelFullScreen()
    //   } else if (document.webkitCancelFullScreen) {
    //     document.webkitCancelFullScreen()
    //   }
    // }
  }



  signIn = (res, type) => {
    const { socialLogin } = this.props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    }
  };

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google");
  };

  //handleTwitterLoginResponse
  twitterResponse = () => {};

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook");
  };


  show() {

    if (this.state.type == "password")
      {
        this.setState({
        type : "text"
        });
      }

      else {
        this.setState({
          type : "password"
          });
      }
      
    
  }



  render() {
    return (
      <div >
        <div style={{width:'100vw',backgroundColor:'orange',border:'1px solid orange',height:'12vh',marginBottom:'10px',display:'flex',alignItems:'center'}}>
        <p style={{color:'white' ,margin:'8px',fontSize:'1rem'}}>LOGIN</p>
        </div>

        <div className="text-center mb-4">
          {/* welcome message section */}
          <h2>Welcome to Federated Co-Operative Societies</h2>
        </div>
        <div className="text-center" style={{ marginBottom: "30px" }}>
          {/* login introduction section */}
          <Row className="justify-content-center">
            <Col xs="12" md="8" lg="10">
              <h4
                style={{
                  fontSize: "20px",
                  color: "orange",
                  maxWidth: "100%", // Limiting the maximum width for better readability on mobile
                  margin: "0 auto", // Center the text horizontally
                }}
              >
        Please
                Login Below
              </h4>
            </Col>
          </Row>

          {/* credentials form section */}
        </div>

        <div>
          <Row>

          <Col
              lg="5"
              className="p-4"
              style={{
                margin: "auto", // Horizontally centering the column content
              }}
            >
              <img width={"540px"} src={mainimg}/>




              </Col>

 {/* Separator line */}
 <Col
 sm="1"
 className="d-none d-sm-block"
 style={{ borderLeft: "2px solid grey", height: "50vh" }}
></Col>


          <Col
              lg="5"
              className="p-4"
              style={{
                margin: "auto", // Horizontally centering the column content
              }}
            >

          <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email:
                            (this.state && this.state.email) ||
                            "",
                          password:
                            (this.state && this.state.password) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required(
                            "Please Enter Your Email"
                          ),
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                        })}
                        onSubmit={values => {
                          this.toggleFullscreen();
                          
                          this.props.loginUser(values, this.props.history);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          
                          <Form >
            
              {/* login form section */}
              <Row>
                {/* user input fields section */}
                <Row style={{ marginTop: "20px" }}>
                  <Col lg="2" xs="12" className="m-2">
                    Username 
                  </Col>
                  <Col lg="8" xs="12">
                  <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={(e)=> this.setState({"email" : e.target.value})}

                                style={{height: "36px",
                                  border: "1px solid",
                                  borderRadius: "11px"}}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col lg="2" xs="12" className="m-2">
                    Password
                  </Col>
                  <Col lg="8" xs="12">
                  <div className="input-group auth-pass-inputgroup" style={{ position: "relative" }}>
  <Field
    name="password"
    type={this.state.type}
    autoComplete="true"
    className={
      "form-control" +
      (errors.password && touched.password ? " is-invalid" : "")
    }
    style={{
      height: "36px",
      border: "1px solid",
      borderRadius: "11px"
    }}
  />
  <button
    className="btn btn-light"
    type="button"
    id="password-addon"
    onClick={this.show}
    style={{
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: "8px", // Adjust this value according to your preference
      background: "none",
      border: "none", // Remove border
      padding: 0 // Remove padding
    }}
  >
    <i className="mdi mdi-eye-outline" style={{ color: "black" }}></i>
  </button>
</div>
                              
                              <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                            </Col>
                </Row>

                {/* remember me checkbox section */}
                <Row style={{ marginTop: "20px" }}>
                  <Col xs="1" className="text-right">
                  <input
                        type="checkbox"
                        id="rememberme"
                        name="rememberme"
                        className="m-1"
                      />
                      </Col>
                      <Col xs="6" className="text-right">
                    <label htmlFor="rememberme">
                      Remember Me
                    </label>
                  </Col>
                </Row>
                {/* login button section */}
                <Row className="justify-content-center">
                  <Col xs="12" lg="12" md="6" className="text-center">

                  <button
                                className="mt-2 w-100 btn btn-outline-orange"
                                style={{ border:'2px solid orange', color: "red",fontWeight:'bold' }}
                                type="submit"
                              >
                                Log In
                              </button>
                   
                  </Col>
                </Row>
              </Row>
           

            </Form>
                        )}
                      </Formik>
                      </Col>

           
         
          </Row>
          <Row>

           <Col
              lg="5"
              className="p-4"
              style={{
                margin: "auto", // Horizontally centering the column content
              }}
            ></Col>
          
          <Col lg="5" className="m-4 margin-fix">
              {/* help options section */}
              <Row>
                <p>
                  Having trouble logging in?
                  <br />
                  Please choose an option below:
                </p>

                {/* registration options section */}
                <Row>
                  {/* Forgot password option section */}
                  
                  <Col
                    lg="5"
                    xs="8"
                    style={{
                      padding: "10px",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      backgroundColor: "orange",
                      color: "white",
                    }}
                  >
                    <Link
                    style={{color: "white"}}
                                  to="/forgot-password"
                                  
                                >
                    <p>
                      Forgot
                      <br />
                      Password?
                    </p>
                    </Link>
                  </Col>
                  

                  {/* OR section */}
                  <Col
                    sm="1"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <p style={{ padding: "0px", margin: "0px" }}>OR</p>
                  </Col>

                  {/* Registration prompt section */}
                 
                  <Col
                    lg="5"
                    xs="8"
                    style={{
                      padding: "10px",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                      backgroundColor: "red",
                      color: "white",
                    }}
                  > <Link
                  to="register"
                  style={{color: "white"}}
                >
                    <p>
                      Not a Staff?
                      <br />
                      Register
                    </p>
                    </Link>
                  </Col>
                  
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);
