import React, { Component } from "react";


import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap";
import { isEmpty } from "lodash";

import img4 from "../../assets/images/small/img-4.jpg";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  Fn_AddEditData
} from "../../store/functions";

import ReactToExcel  from 'react-html-table-to-excel'

class MemberApprove extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      id: 0,
      selectedFormData: {},
      detail:[
{
  MembershipType : "",
  DateOfJoining : "",
  MembershipNo : "",
  Name : "",
  Address_Residential : "",
  MobileNo_SMS : "",
  Email : "",
  SpouseName : "",
  Address_Business : "",
  MobileNo1_Business : "",
  MembershipFees : "",
  ImageURL_Member : ''
}

      ],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true
     
    };
    this.obj = this;
    this.pushFormName = "/unapproved";
    this.rtPage_Edit = "/edit-membership";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/MemberSynopsis";
    this.API_URL_SAVE =  "ApproveMember/0/token";
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.approve =  this.approve.bind(this);
    this.blacklist =  this.blacklist.bind(this);
    //Event Binding
   
  }
  componentDidMount() {
    
    const { id } = this.props.match.params;
    this.setState({ id: id });

    Fn_FillListData(this.obj, "detail", this.API_URL + "/Id/"+id);
  
  }


  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(`${this.pushFormName}`, {});
  };



  //Print the Invoice
  printInvoice = () => {                                                                                                                                                      
    window.print();
  };


  approve =  event => {
    event.preventDefault();
    this.props.history.push(`${this.rtPage_Edit}/${this.state.id}`, {});
  }



  blacklist =  event => {
    event.preventDefault();
    alert("Member Blacklisted Successfully!");
    this.props.history.push(`${this.pushFormName}`, {});
  }

  render() {
    const { invoiceDetail } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Federated Co-Operative Multi-Purpose Society Ltd.</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs title="Invoices" breadcrumbItem="Federated Co-Operative Multi-Purpose Society Ltd." /> */}
            
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title" style={{"textAlign":"center"}} >
                       <div className="mb-2" >
                        <h4>  Federated Co-Operative Multi-Purpose Society Ltd.</h4>
                        <h6> P.O. Box GP-21703, GPO, Accra, Ghana.</h6>
                        </div>
                      </div>
                      <hr /> 
                      

                      <div className="invoice-title" style={{"textAlign":"center"}} >
                          <div className="mb-2" >
                        <h4> MEMBER SYNOPSIS</h4>
                        
                        </div>


                        
                      </div>
                      <hr />
                      
                      
                      <Row>
                        <Col sm="6">
                        
                        <img
                          className="rounded me-2"
                          alt="image"
                          width="250"
                          height="200"
                          src={this.state.detail[0].ImageURL_Member}
                        />
                     
                            
                           
                        </Col>
                       
                      </Row>
                     

<hr />
<hr />
                      <div className="table-responsive">
                        <Table className="table-nowrap" id="some">
                        {/* <thead>.</thead>
                        <thead>.</thead>
                        <thead>.</thead>

                        <thead>.</thead>

<thead>

<div className="invoice-title" style={{"textAlign":"center"}} >
                          <div className="mb-2" >
                        <h4>  Federated Co-Operative Multi-Purpose Society Ltd.</h4>
                        <h6> P.O. Box GP-21703,GPO,Accra, Ghana.</h6>
                        </div>
                      </div>
</thead>

<thead>.</thead>

<thead>.</thead>

<thead>
<div className="invoice-title" style={{"textAlign":"center"}} >
                          <div className="mb-2" >
                        <h4> MEMBER SYNOPSIS</h4>
                        
                        </div>


                        
                      </div>
</thead>


<thead>.</thead>
<thead>.</thead> */}


                          
                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Membership Category:</th>
                              <th>{this.state.detail[0].MembershipType}</th>
                             
                            </tr>
                          </thead>

                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Date of Joining:</th>
                              <th>{this.state.detail[0].DateOfJoining}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Membership Number:</th>
                              <th>{this.state.detail[0].MembershipNo}</th>
                             
                            </tr>
                          </thead>

                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Full Name:</th>
                              <th>{this.state.detail[0].Name}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Address:</th>
                              <th>{this.state.detail[0].Address_Residential}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Mobile Phone Number:</th>
                              <th>{this.state.detail[0].MobileNo_SMS}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Private E-Mail:</th>
                              <th>{this.state.detail[0].Email}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Membership E-Mail:</th>
                              <th>{this.state.detail[0].Email}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Emergency Contact Person:</th>
                              <th>{this.state.detail[0].SpouseName}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Contact Person Address:</th>
                              <th>{this.state.detail[0].Address_Business}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Contact Person Mobile Phone:</th>
                              <th>{this.state.detail[0].MobileNo1_Business}</th>
                             
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Entrance Fee Payable:</th>
                              <th>₵{this.state.detail[0].MembershipFees}</th>
                            </tr>
                          </thead>


                          <thead>
                            <tr>
                              <th style={{ width: "350px" }}>Transaction Account Balance:</th>
                              <th>₵{this.state.detail[0].Balance}</th>
                             
                            </tr>
                          </thead>

                        </Table>
                      </div>
                      <div className="d-print-none">
                        <div className="float-end">
                        <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                          Cancel
                        </Button>

                        
{/* <ReactToExcel 
className = "btn"
table =  "some"
filename = "excelfile"
sheet = "sheet 1"
buttontext= "Export"

/> */}
{" "}
{" "}
 <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.approve}
                        >
                          Approve
                        </Button>


                        {" "}
{" "}
 <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.blacklist}
                        >
                          Black-List
                        </Button>

                       

                          {/* <Link
                            to="#"
                            onClick={this.printInvoice}
                            className="btn btn-success me-1"
                          >
                            <i className="fa fa-print" />
                          </Link>{" "}
                          <Link
                            to="#"
                            className="btn btn-primary w-md"
                          >
                            Send
                          </Link> */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            
          </Container>
        </div>
      </React.Fragment>
    );
  }

 
 
 
}
export default compose(container)(MemberApprove);
